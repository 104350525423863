import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import AllTaskScreen from "../Screens/AllTaskScreen";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import AllLeadTask from "../Screens/AllLeadTask";
import AllUserScreen from "../Screens/AllUserScreen";
import AddLeadScreen from "../Screens/AddLeadScreen";
import AllEditScreen from "../Screens/AllEditScreen";
import AddTaskScreen from "../Screens/AddTaskScreen";
import AddTaskSVG from "../assets/image/svg/AddTaskSVG";
import AddMemberSVG from "../assets/image/svg/AddMemberSVG";
import SideBar from "../Components/sidebar Component/SideBar";
import RegisterUserComponent from "../Components/User Component/RegisterUserComponent";

import { useAppDispatch, useAppSelector } from "../Redux/Hooks";
import { getAllUser } from "../Slices/UserSlice";
import {
  getAllDisputeTasks,
  getAllLabCases,
  getAllLeadData,
  getAllMaintanance,
  getAllTasks,
  updateTask,
} from "../Slices/TaskSlice";
import EditUserScreen from "../Screens/EditUserScreen";
import Header from "../Components/Header Component/Header";
import UpdatePassword from "../Components/Update Component/UpdatePassword";
import UpdateProfile from "../Components/Update Component/UpdateProfile";
import { FaUserDoctor } from "react-icons/fa6";
import AllLabCaseScreen from "../Screens/AlLlabCaseScreen";
import AddLabCasesScreen from "../Screens/AddLabCasesScreen";
import DocumentScreen from "../Screens/DocumentScreen";
import { IoMdDocument } from "react-icons/io";

import QualityDisputeScreen from "../Screens/QualityDisputeScreen";
// import ViewSingleLead from "../Components/Lead Components/ViewSingleLead";
import AddMaintanance from "../Screens/AddMaintanance";
import AllMaintanance from "../Screens/AllMaintanance";
import AddLeadSvg from "../assets/image/svg/AddLeadSvg";
import { io } from "socket.io-client";

const Auth = () => {
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const organizationName = AdminUser?.organizationData?.[0]?.name;

  const sideBarData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/dashboard") &&
            window.location.pathname.includes("*") &&
            window.location.pathname.includes("/")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/dashboard",
    },

    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/maintanance")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/maintanance",
    },
    {
      icon: (
        <AddLeadSvg
          color={
            window.location.pathname.includes("/leadTask")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/leadTask",
    },

    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/user") ? "#02647F" : "#000000"
          }
        />
      ),
      url: "/user",
    },

    {
      icon: (
        <IoMdDocument
          size={25}
          color={
            window.location.pathname.includes("/document")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/document",
    },
  ];

  const sideBarDentalData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/dashboard") &&
            window.location.pathname.includes("*") &&
            window.location.pathname.includes("/")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/dashboard",
    },
    {
      icon: (
        <AddLeadSvg
          color={
            window.location.pathname.includes("/leadTask")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      url: "/leadTask",
    },
    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/labCase")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/labCase",
    },

    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/user") ? "#02647F" : "#000000"
          }
        />
      ),
      url: "/user",
    },
    {
      icon: (
        <IoMdDocument
          size={25}
          color={
            window.location.pathname.includes("/document")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      url: "/document",
    },
  ];
  const [notification, setNotification] = useState(false);
  const [profile, setProfile] = useState(false);
  const [pass, setPass] = useState(false);
  const [prof, setProf] = useState(false);
  const [todo, setTodo] = useState<any>([]);
  const [inprogress, setInProgress] = useState<any>([]);
  const [completed, setCompleted] = useState<any>([]);

  const [leadData, setLeadData] = useState<any>({
    new: [],
    followingUp: [],
    completed: [],
    booked: [],
    notInterested: [],
  });
  const [disputeData, setDisputeData] = useState<any>({
    new: [],
    contesting: [],
    wonpartial: [],
    wonclosed: [],
    lostclosed: [],
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [userDetails, setUserDetails] = useState([]);
  const [search, setSearch] = useState("");
  const [refetch, setRefetch] = useState(1);
  const [taskData, setTaskData] = useState<any>([]);
  const [showScreen, setShowScreen] = useState<string>("");
  const [myTask, setMyTask] = useState<any>("false");
  const [labCaseData, setLabCaseData] = useState<any>({
    ready: [],
    pickedUp: [],
    received: [],
    completed: [],
  });
  const [maintData, setMaintData] = useState<any>({
    todoMaint: [],
    inProgressMaint: [],
    completedMaint: [],
  });
  const [dueDate, setDueDate] = useState("all days");
  const [draggedData, setDraggedData] = useState<any>({});
  const [openComment, setOpenComment] = useState(false);
  const [loader, setLoader] = useState(false);
  const { pathname } = useLocation();

  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const handleUpdateFunc = (value: any, status: string) => {
    // Api for Update function
    let payload;
    setLoader(true);
    if (value?.lead === null) {
      payload = {
        task_id: value?._id,
        task_details: {
          title: value?.title,
          comments: value?.commentsData ? value?.commentsData : [""],
          description: value?.description,
          owner: value?._id,
          priority: value?.priority.toLowerCase(),
          status: status,
          deadline: value?.deadline,
          assign_to: value?.assign_to ? value?.assign_to : [""],
          taskType: value?.taskType.toLowerCase(),
        },
        attached_files: value?.DocumentsData,
      };
    } else {
      payload = {
        task_id: value?._id,
        task_details: {
          title: value?.title,
          description: value?.description,
          owner: value?._id,
          comments: value?.commentsData ? value?.commentsData : [""],
          priority: value?.priority.toLowerCase(),
          status: status,
          deadline: value?.deadline,
          assign_to: value?.assign_to ? value?.assign_to : [""],
          taskType: value?.taskType.toLowerCase(),
          lead: AdminUser?._id ? AdminUser?._id : "",
          lead_Phone: value?.LeadPhone ? value?.LeadPhone : "",
          text_for_Lead: value?.text ? value?.text : "",
        },
        attached_files: value?.DocumentsData,
      };
    }

    dispatch(updateTask(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setRefetch(refetch + 1);
          setLoader(false);

          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  };
  let addLead: any;
  let addDispute: any;
  let addMaint: any;
  let addLabCase: any;
  const onDragEnd = (result: DropResult) => {
    //  on drag library
    const { destination, source } = result;
    console.log(
      destination,
      "destinatition id",
      source,
      "source & destinations data"
    );
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let add;
    let temp_todo = todo;
    let temp_Inprogress = inprogress;
    let temp_completed = completed;

    let temp_todo_maint = maintData.todoMaint;
    let temp_Inprogress_maint = maintData.inProgressMaint;
    let temp_completed_maint = maintData.completedMaint;

    let temp_Lead_New = leadData?.new;
    let temp_Lead_booked = leadData?.booked;
    let temp_Lead_completed = leadData?.completed;
    let temp_Lead_followingUp = leadData?.followingUp;
    let temp_Lead_NotIntersted = leadData?.notInterested;

    let add_New_Dispute = disputeData?.new;
    let add_contest = disputeData?.contesting;
    let add_Won_Partial = disputeData?.wonpartial;
    let add_Won_Closed = disputeData?.wonclosed;
    let add_Lost_Closed = disputeData?.lostclosed;

    let temp_ready_lab = labCaseData?.ready;
    let temp_pickedUp_lab = labCaseData?.pickedUp;
    let temp_recieved_lab = labCaseData?.received;
    let temp_completed_lab = labCaseData?.completed;

    if (source.droppableId === "TodosList") {
      add = temp_todo[source.index];
      temp_todo.splice(source.index, 1);
    } else if (source.droppableId === "InProgressList") {
      add = temp_Inprogress[source.index];
      temp_Inprogress.splice(source.index, 1);
    } else if (source.droppableId === "CompletedList") {
      add = temp_completed[source.index];
      temp_completed.splice(source.index, 1);
    } else if (source.droppableId === "maintTodosList") {
      addMaint = temp_todo_maint[source.index];
      temp_todo_maint.splice(source.index, 1);
    } else if (source.droppableId === "maintInProgressList") {
      addMaint = temp_Inprogress_maint[source.index];
      temp_Inprogress_maint.splice(source.index, 1);
    } else if (source.droppableId === "maintCompletedList") {
      addMaint = temp_completed_maint[source.index];
      temp_completed_maint.splice(source.index, 1);
    } else if (source.droppableId === "NewLeadList") {
      addLead = temp_Lead_New[source.index];
      temp_Lead_New.splice(source.index, 1);
    } else if (source.droppableId === "followingUpList") {
      addLead = temp_Lead_followingUp[source.index];
      temp_Lead_followingUp.splice(source.index, 1);
    } else if (source.droppableId === "bookedList") {
      addLead = temp_Lead_booked[source.index];
      temp_Lead_booked.splice(source.index, 1);
    } else if (source.droppableId === "completedList") {
      addLead = temp_Lead_completed[source.index];
      temp_Lead_completed.splice(source.index, 1);
    } else if (source.droppableId === "notInterstedList") {
      addLead = temp_Lead_NotIntersted[source.index];
      temp_Lead_NotIntersted.splice(source.index, 1);
    } else if (source.droppableId === "newDisputeList") {
      addDispute = add_New_Dispute[source.index];
      add_New_Dispute.splice(source.index, 1);
    } else if (source.droppableId === "contestDisputeList") {
      addDispute = add_contest[source.index];
      add_contest.splice(source.index, 1);
    } else if (source.droppableId === "wonPartialList") {
      addDispute = add_Won_Partial[source.index];
      add_Won_Partial.splice(source.index, 1);
    } else if (source.droppableId === "wonClosedList") {
      addDispute = add_Won_Closed[source.index];
      add_Won_Closed.splice(source.index, 1);
    } else if (source.droppableId === "lostClosedList") {
      addDispute = add_Lost_Closed[source.index];
      add_Lost_Closed.splice(source.index, 1);
    } else if (source.droppableId === "readyList") {
      addLabCase = temp_ready_lab[source.index];
      temp_ready_lab.splice(source.index, 1);
    } else if (source.droppableId === "pickedUpList") {
      addLabCase = temp_pickedUp_lab[source.index];
      temp_pickedUp_lab.splice(source.index, 1);
    } else if (source.droppableId === "recievedList") {
      addLabCase = temp_recieved_lab[source.index];
      temp_recieved_lab.splice(source.index, 1);
    } else if (source.droppableId === "completeList") {
      addLabCase = temp_completed_lab[source.index];
      temp_completed_lab.splice(source.index, 1);
    }

    // Destination Logic
    if (destination.droppableId === "TodosList") {
      temp_todo.splice(destination.index, 0, { ...add, status: "todo" });
      setDraggedData({ ...add, status: "todo" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "todo" }] });
    } else if (destination.droppableId === "InProgressList") {
      temp_Inprogress.splice(destination.index, 0, {
        ...add,
        status: "inprogress",
      });
      setDraggedData({ ...add, status: "inprogress" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "inprogress" }] });
    } else if (destination.droppableId === "CompletedList") {
      temp_completed.splice(destination.index, 0, {
        ...add,
        status: "completed",
      });
      setDraggedData({ ...add, status: "completed" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "completed" }] });
    } else if (destination.droppableId === "maintTodosList") {
      temp_todo_maint.splice(destination.index, 0, {
        ...addMaint,
        status: "todo",
      });
      setDraggedData({ ...addMaint, status: "todo" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "todo" }] });
    } else if (destination.droppableId === "maintInProgressList") {
      temp_Inprogress_maint.splice(destination.index, 0, {
        ...addMaint,
        status: "inprogress",
      });
      setDraggedData({ ...addMaint, status: "inprogress" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "inprogress" }] });
    } else if (destination.droppableId === "maintCompletedList") {
      temp_completed_maint.splice(destination.index, 0, {
        ...addMaint,
        status: "completed",
      });
      setDraggedData({ ...addMaint, status: "completed" });
      setOpenComment(true);
      // nav("/edit", { state: [{ ...add, status: "completed" }] });
    } else if (destination.droppableId === "NewLeadList") {
      temp_Lead_New.splice(destination.index, 0, { ...addLead, status: "new" });
      setDraggedData({ ...addLead, status: "new" });
      setOpenComment(true);
    } else if (destination.droppableId === "followingUpList") {
      temp_Lead_followingUp.splice(destination.index, 0, {
        ...addLead,
        status: "following_up",
      });
      setDraggedData({ ...addLead, status: "following_up" });
      setOpenComment(true);
      console.log("++++++++++++++++", add);
    } else if (destination.droppableId === "bookedList") {
      temp_Lead_booked.splice(destination.index, 0, {
        ...addLead,
        status: "booked",
      });
      setDraggedData({ ...addLead, status: "booked" });
      setOpenComment(true);
    } else if (destination.droppableId === "completedList") {
      temp_Lead_completed.splice(destination.index, 0, {
        ...addLead,
        status: "completed",
      });
      setDraggedData({ ...addLead, status: "completed" });
      setOpenComment(true);
    } else if (destination.droppableId === "notInterstedList") {
      temp_Lead_NotIntersted.splice(destination.index, 0, {
        ...addLead,
        status: "not_interested",
      });
      setDraggedData({ ...addLead, status: "not_interested" });
      setOpenComment(true);
    } else if (destination.droppableId === "newDisputeList") {
      add_New_Dispute.splice(destination.index, 0, {
        ...addDispute,
        status: "new",
      });
      setDraggedData({ ...addDispute, status: "new" });
      setOpenComment(true);
    } else if (destination.droppableId === "contestDisputeList") {
      add_contest.splice(destination.index, 0, {
        ...addDispute,
        status: "contesting",
      });
      setDraggedData({ ...addDispute, status: "contesting" });
      setOpenComment(true);
    } else if (destination.droppableId === "wonPartialList") {
      add_Won_Partial.splice(destination.index, 0, {
        ...addDispute,
        status: "won_partial",
      });
      setDraggedData({ ...addDispute, status: "won_partial" });
      setOpenComment(true);
    } else if (destination.droppableId === "wonClosedList") {
      add_Won_Closed.splice(destination.index, 0, {
        ...addDispute,
        status: "won_closed",
      });
      setDraggedData({ ...addDispute, status: "won_closed" });
      setOpenComment(true);
    } else if (destination.droppableId === "lostClosedList") {
      add_Won_Closed.splice(destination.index, 0, {
        ...addDispute,
        status: "lost_closed",
      });
      setDraggedData({ ...addDispute, status: "lost_closed" });
      setOpenComment(true);
    } else if (destination.droppableId === "readyList") {
      temp_ready_lab.splice(destination.index, 0, {
        ...addLabCase,
        status: "ready",
      });
      setDraggedData({ ...addLabCase, status: "ready" });
      setOpenComment(true);
    } else if (destination.droppableId === "pickedUpList") {
      temp_pickedUp_lab.splice(destination.index, 0, {
        ...addLabCase,
        status: "picked_up",
      });
      setDraggedData({ ...addLabCase, status: "picked_up" });
      setOpenComment(true);
    } else if (destination.droppableId === "recievedList") {
      temp_recieved_lab.splice(destination.index, 0, {
        ...addLabCase,
        status: "received",
      });
      setDraggedData({ ...addLabCase, status: "received" });
      setOpenComment(true);
    } else if (destination.droppableId === "completeList") {
      temp_completed_lab.splice(destination.index, 0, {
        ...addLabCase,
        status: "send",
      });
      setDraggedData({ ...addLabCase, status: "send" });
      setOpenComment(true);
    }
  };

  const Socket_Connection = io("wss://server.teampetal.com",{
    reconnection: true, // Enable reconnection attempts
    reconnectionAttempts: 5, // Number of reconnection attempts before giving up
    reconnectionDelay: 1000, // Time between each reconnection attempt in milliseconds
    reconnectionDelayMax: 5000, // Maximum time between reconnection attempts
    randomizationFactor: 0.5 // Randomization factor for reconnection attempts
  });
  useEffect(()=>{
    Socket_Connection.on("connect", () => {
      console.log("socket connect io 22 " ,Socket_Connection.id , Socket_Connection.connected); // x8WIv7-mJelg7on_ALbx
    });
   
    Socket_Connection.emit('subscribe', {room: AdminUser?._id})
  },[])

  useEffect(() =>{
  
    Socket_Connection.on('incoming_messages', (data:any) => {
      console.log("incoming_messages", data)


    })

  },[])

  const getUser = () => {
    // this api for the user
    const payload = {
      search: search.length ? search : "",
      organization_id: AdminUser?.organization_id,
    };
    const payloads = {
      search: search.length ? search : "",
      organization_id: AdminUser?.organization_id,
      hotel_id: AdminUser?.hotel_id,
    };
    dispatch(getAllUser(organizationName === "Hotel" ? payloads : payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setUserDetails(res?.response?.user);
          // toast("Successful ✅", {
          //   position: "top-right",
          // });
        }
      })
      .catch((error) => {
        toast.error(error?.msg);
      });
  };

  const HandleGetTaskFunc = () => {
     const payload = {
      status: "all",
      search: search,
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : " ",
      due_date: dueDate,
    };
    dispatch(getAllTasks(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setTaskData(res?.taskData);
        }
        else{
          setTaskData([]);
        }
      })
      .catch((error) => {
        setTaskData([]);
        console.log("error", error);
        toast.error(error?.msg);
      });
  };

  const handleGetMaintanance = () => {
    const payload = {
      search: "",
      maintenance_task_sub_type: "",
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : " ",
      due_date: dueDate,
    };
    dispatch(getAllMaintanance(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          const tempTodo: any = [];
          const tempInprogress: any = [];
          const tempCompleted: any = [];
          res?.taskData?.forEach((item: any) => {
            if (item.status === "todo") {
              tempTodo.push(item);
              // setMaintData((prevState: any) => ({
              //   ...prevState,
              //   todoMaint: [...prevState.todoMaint, item], // Spread previous array and add new item
              // }));
            } else if (item.status === "inprogress") {
              tempInprogress.push(item);
              // setMaintData((prevState: any) => ({
              //   ...prevState,
              //   inProgressMaint: [...prevState.inProgressMaint, item], // Spread previous array and add new item
              // }));
            } else if (item.status === "completed") {
              tempCompleted.push(item);
              // setMaintData((prevState: any) => ({
              //   ...prevState,
              //   completedMaint: [...prevState.completedMaint, item], // Spread previous array and add new item
              // }));
            }
            setMaintData({
              todoMaint: tempTodo,
              inProgressMaint: tempInprogress,
              completedMaint: tempCompleted,
            });
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.msg);
      });
  };

  const HandleGetDisputeTaskFunc = () => {
    // this api for all task
    const payload = {
      status: "all",
      search: search,
    };
    dispatch(getAllDisputeTasks(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          const tempNew: any = [];
          const tempContesting: any = [];
          const tempWonPartial: any = [];
          const tempWonClosed: any = [];
          const tempLostClosed: any = [];
          res?.taskData?.forEach((item: any) => {
            if (item.status === "new") {
              tempNew.push(item);
              // setDisputeData((prevState: any) => ({
              //   ...prevState,
              //   new: [...prevState.new, item], // Spread previous array and add new item
              // }));
            } else if (item.status === "contesting") {
              tempContesting.push(item);
              // setDisputeData((prevState: any) => ({
              //   ...prevState,
              //   contesting: [...prevState.contesting, item], // Spread previous array and add new item
              // }));
            } else if (item.status === "won_partial") {
              tempWonPartial.push(item);
            } else if (item?.status === "won_closed") {
              tempWonClosed.push(item);
              // setDisputeData((prevState: any) => ({
              //   ...prevState,
              //   wonclosed: [...prevState.wonclosed, item], // Spread previous array and add new item
              // }));
            } else if (item?.status === "lost_closed") {
              tempLostClosed.push(item);
              // setDisputeData((prevState: any) => ({
              //   ...prevState,
              //   lostclosed: [...prevState.lostclosed, item], // Spread previous array and add new item
              // }));
            }
            setDisputeData({
              new: tempNew,
              contesting: tempContesting,
              wonpartial: tempWonPartial,
              wonclosed: tempWonClosed,
              lostclosed: tempLostClosed,
            });
          });
          // setTaskData(res?.taskData);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.msg);
      });
  };

  const handleGetAllLabCase = () => {
    const payload = {
      search: "",
      status: "",
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : " ",
    };
    dispatch(getAllLabCases(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setLabCaseData({
            ready: [],
            pickedUp: [],
            received: [],
            completed: [],
          })
          const tempReady: any = [];
          const tempPickedUp: any = [];
          const tempReceive: any = [];
          const tempCompleted: any = [];
          res?.labCaseData?.forEach((item: any) => {
            if (item.status === "ready") {
              tempReady.push(item);
            } else if (item.status === "picked_up") {
              tempPickedUp.push(item);
            } else if (item.status === "received") {
              tempReceive.push(item);
            } else if (item.status === "send") {
              tempCompleted.push(item);
              // setLabCaseData((prevState: any) => ({
              //   ...prevState,
              //   completed: [...prevState.completed, item], // Spread previous array and add new item
              // }));
            }
            setLabCaseData({
              ready: tempReady,
              pickedUp: tempPickedUp,
              received: tempReceive,
              completed: tempCompleted,
            });
          });
        }
      })
      .catch((error) => {
        console.log("===================error", error);
      });
  };

  const handleGetAllLeadData = () => {
    const payload = {
      status: "all",
      search: "",
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : " ",
      organization_id: AdminUser?.organization_id,
    };
    dispatch(getAllLeadData(payload))
      .unwrap()
      .then((response) => {
        if (response?.success === true) {
          const tempNew: any = [];
          const tempFollowingUp: any = [];
          const tempBooked: any = [];
          const tempCompleted: any = [];
          const tempNotInterested: any = [];
          response?.taskData?.forEach((item: any) => {
            if (item.status === "new") {
              tempNew.push(item);
            } else if (item.status === "following_up") {
              tempFollowingUp.push(item);
              // setLeadData((prevState: any) => ({
              //   ...prevState,
              //   followingUp: [...prevState.followingUp, item], // Spread previous array and add new item
              // }));
            } else if (item.status === "scheduled") {
              tempBooked.push(item);
            } else if (item?.status === "completed") {
              tempCompleted.push(item);
            } else if (item.status === "not_interested") {
              tempNotInterested.push(item);
            }
            setLeadData({
              new: tempNew,
              followingUp: tempFollowingUp,
              booked: tempBooked,
              completed: tempCompleted,
              notInterested: tempNotInterested,
            });
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(error?.msg);
      });
  };

  useEffect(() => {
    // here we call function
    if (organizationName === "Dental") {
      // handleGetAllLabCase();
    } else if (organizationName === "Hotel") {
      handleGetMaintanance();
      HandleGetDisputeTaskFunc();
    }
    handleGetAllLeadData();
    HandleGetTaskFunc();
  }, [search, refetch]);

  useEffect(() => {
    // in this seperate todo inprogress complete in different state because all data coming from same api.
    const tempTodo: any = [];
    const tempInProgress: any = [];
    const tempCompleted: any = [];
    // const tempLeadTodo: any = [];
    // const tempLeadInProgress: any = [];
    // const tempLeadCompleted: any = [];

    taskData &&
      taskData.map((item: any) => {
        if (item.status === "todo") {
          tempTodo.push(item);
        } else if (item.status === "inprogress") {
          tempInProgress.push(item);
        } else if (item.status === "completed") {
          tempCompleted.push(item);
        }
        // } else if (item.lead !== null && item.status === "todo") {
        //   tempLeadTodo.push(item);
        // } else if (item.lead !== null && item.status === "inprogress") {
        //   tempLeadInProgress.push(item);
        // } else if (item.lead !== null && item.status === "completed") {
        //   tempLeadCompleted.push(item);
        // }
      });
    setCompleted([...tempCompleted]);
    setInProgress([...tempInProgress]);
    setTodo([...tempTodo]);
    // setLeadCompleted([...tempLeadCompleted]);
    // setLeadInProgress([...tempLeadInProgress]);
    // setLeadTodo([...tempLeadTodo]);
  }, [refetch, taskData, search]);

  useEffect(() => {
    getUser();
    const handleResize = () => {
      // this is function for the window size
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [search, refetch]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <>
        <div className="h-[100vh] w-[100vw] flex bg-[#EDF1F6] ">
          {/* <BrowserRouter> */}

          <SideBar
            data={
              organizationName === "Dental" ? sideBarDentalData : sideBarData
            }
          />

          <div className="h-[100vh] w-[100vw] overflow-auto md:overflow-hidden ">
            {window.location.pathname !== "/registerUser" &&
              window.location.pathname !== "/AddTask" &&
              window.location.pathname !== "/AddLeadTask" &&
              window.location.pathname !== "/edit" &&
              window.location.pathname !== "/editUser" &&
              window.location.pathname !== "/AddLabCase" && (
                <Header // header
                  setNotification={setNotification}
                  notification={notification}
                  setProfile={setProfile}
                  profile={profile}
                  setPass={setPass}
                  setProf={setProf}
                  setSearch={setSearch}
                  windowWidth={windowWidth}
                  setShowScreen={setShowScreen}
                />
              )}
            <div
              className="w-[100%] overflow-auto"
              style={
                pathname !== "/registerUser" &&
                pathname !== "/AddTask" &&
                pathname !== "/AddLeadTask" &&
                pathname !== "/edit" &&
                pathname !== "/editUser" &&
                pathname !== "/AddLabCase"
                  ? { height: "calc(100% - 72px)" } // this condition for height because this component doesn't need header space.
                  : { height: "100%" }
              }
            >
              <Routes>
                <Route
                  path="*"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                      myTask={myTask}
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                      setMyTask={setMyTask}
                      setTaskData={setTaskData}
                      userDetails={userDetails}
                      setOpenComment={setOpenComment}
                      openComment={openComment}
                      add={draggedData}
                    />
                  }
                />
                <Route
                  path="/"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      loader={loader}
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                      setTaskData={setTaskData}
                      myTask={myTask}
                      userDetails={userDetails}
                      setMyTask={setMyTask}
                      setOpenComment={setOpenComment}
                      openComment={openComment}
                      add={draggedData}
                    />
                  }
                />

                <Route
                  path="/dashboard"
                  element={
                    <AllTaskScreen
                      todo={todo}
                      inprogress={inprogress}
                      completed={completed}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      setRefetch={setRefetch}
                      refetch={refetch}
                      myTask={myTask}
                      dueDate={dueDate}
                      setDueDate={setDueDate}
                      setTaskData={setTaskData}
                      setMyTask={setMyTask}
                      userDetails={userDetails}
                      loader={loader}
                      setOpenComment={setOpenComment}
                      openComment={openComment}
                      add={draggedData}
                    />
                  }
                />

                <Route
                  path="/leadTask"
                  element={
                    <AllLeadTask
                      todo={leadData?.new}
                      inprogress={leadData?.followingUp}
                      completed={leadData?.completed}
                      taskData={leadData?.booked}
                      leadData={leadData}
                      addLead={draggedData}
                      setLeadData={setLeadData}
                      refetch={refetch}
                      windowWidth={windowWidth}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                      myTask={myTask}
                      setMyTask={setMyTask}
                      userDetails={userDetails}
                      setRefetch={setRefetch}
                      openComment={openComment}
                      setOpenComment={setOpenComment}
                    />
                  }
                />
                {organizationName === "Dental" ? (
                  <React.Fragment>
                    <Route
                      path="/labCase"
                      element={
                        <AllLabCaseScreen
                          todo={labCaseData?.ready} // this data is coming from all task please it from lab case data todo is replaced from send state
                          inprogress={labCaseData?.pickedUp} // this data is coming from all task please it from lab case data inprogress is replaced from received
                          completed={labCaseData?.completed} // this data is coming from all task please it from lab case data completed is replaced from delivered
                          labCaseData={labCaseData?.received}
                          windowWidth={windowWidth}
                          setShowScreen={setShowScreen}
                          showScreen={showScreen}
                          setRefetch={setRefetch}
                          refetch={refetch}
                          loader={loader}
                          myTask={myTask}
                          setMyTask={setMyTask}
                          userDetails={userDetails}
                          setLabCaseData={setLabCaseData}
                          setOpenComment={setOpenComment}
                          openComment={openComment}
                          addLabCase={draggedData}
                        />
                      }
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Route
                      path="/maintanance"
                      element={
                        <AllMaintanance
                          todo={maintData?.todoMaint}
                          inprogress={maintData?.inProgressMaint}
                          completed={maintData?.completedMaint}
                          windowWidth={windowWidth}
                          setShowScreen={setShowScreen}
                          showScreen={showScreen}
                          setRefetch={setRefetch}
                          refetch={refetch}
                          setMaintData={setMaintData}
                          loader={loader}
                          addMaint={draggedData}
                          dueDate={dueDate}
                          setDueDate={setDueDate}
                          myTask={myTask}
                          setMyTask={setMyTask}
                          userDetails={userDetails}
                          setOpenComment={setOpenComment}
                          openComment={openComment}
                        />
                      }
                    />

                    <Route
                      path="/qualityDispute"
                      element={
                        <QualityDisputeScreen
                          windowWidth={windowWidth}
                          setShowScreen={setShowScreen}
                          showScreen={showScreen}
                          setOpenComment={setOpenComment}
                          openComment={openComment}
                          refetch={refetch}
                          disputeData={disputeData}
                          setDisputeData={setDisputeData}
                          setRefetch={setRefetch}
                          addDispute={draggedData}
                        />
                      }
                    />
                  </React.Fragment>
                )}

                <Route
                  path="/user"
                  element={
                    <AllUserScreen
                      userDetails={userDetails}
                      setShowScreen={setShowScreen}
                      showScreen={showScreen}
                    />
                  }
                />

                <Route path="/document" element={<DocumentScreen />} />
                <Route
                  path="/edit"
                  element={
                    <AllEditScreen
                      refetch={refetch}
                      handleUpdateFunc={handleUpdateFunc}
                      setRefetch={setRefetch}
                    />
                  }
                />
                <Route
                  path="/AddLeadTask"
                  element={
                    <AddLeadScreen refetch={refetch} setRefetch={setRefetch} />
                  }
                />
                <Route
                  path="/leadTask/:leadId"
                  element={
                    <AddLeadScreen refetch={refetch} setRefetch={setRefetch} />
                  }
                />

                <Route
                  path="/AddTask"
                  element={
                    <AddTaskScreen refetch={refetch} setRefetch={setRefetch} />
                  }
                />
                <Route
                  path="/AddSureStayMaint"
                  element={
                    <AddMaintanance refetch={refetch} setRefetch={setRefetch} />
                  }
                />
                <Route
                  path="/AddSureStayMaint/:maintId"
                  element={
                    <AddMaintanance refetch={refetch} setRefetch={setRefetch} />
                  }
                />

                <Route
                  path="/AddLabCase"
                  element={
                    <AddLabCasesScreen
                      refetch={refetch}
                      setRefetch={setRefetch}
                    />
                  }
                />
                <Route
                  path="/AddLabCase/:labCaseId"
                  element={
                    <AddLabCasesScreen
                      refetch={refetch}
                      setRefetch={setRefetch}
                    />
                  }
                />

                <Route
                  path="/registerUser"
                  element={
                    <RegisterUserComponent
                      setRefetch={setRefetch}
                      refetch={refetch}
                    />
                  }
                />
                <Route
                  path="/editUser"
                  element={
                    <EditUserScreen setRefetch={setRefetch} refetch={refetch} />
                  }
                />
              </Routes>
            </div>
          </div>
          {/* </BrowserRouter> */}
          {pass && <UpdatePassword setPassword={setPass} />}
          {prof && <UpdateProfile setProf={setProf} />}
        </div>
      </>
    </DragDropContext>
  );
};

export default Auth;
