import moment from "moment";
import React, { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { MdDelete, MdOutlineCloudUpload } from "react-icons/md";
import { FieldArray, Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import CommentSection from "./CommentSection";
import MaintTable from "../Tables/MaintTable";
import Loader from "../../assets/image/svg/Loader";
import PdfIcon from "../../assets/image/pdf-icon.png";
import UploadFile from "../Field Components/UploadFile";
import InputField from "../Field Components/InputField";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";
import {
  createMaint,
  updateMaint,
  uploadDocument,
} from "../../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { AddMaintTaskSchema } from "../../Validations/FormikValidation";
import { toast } from "react-toastify";

const AddSurestayMaint = ({
  refetch,
  loader,
  setRefetch,
  singleMaintData,
  setLoader,
  maintId,
}: any) => {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [deletetask, setDeleteTask] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [edit, setEdit] = useState(maintId ? true : false);
  const userId: any = useAppSelector((state) => state?.user?.user);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];
  const data = location.state || [];

  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs: any = ["Comments", "Status", "Attachments"];

  const [comment, setComment] = useState([]);

  let commentsDate: any = [""];

  commentsDate =
    singleMaintData?.commentsData.length &&
    singleMaintData?.commentsData?.map((item: any, index: number) => {
      {
        return moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a");
      }
    });

  const initialValues = {
    title: singleMaintData?.title || "",
    description: singleMaintData?.description || "",
    assignee: singleMaintData?.assignUsersData || [],
    comment: singleMaintData?.commentsData ? singleMaintData?.commentsData : [],
    idDocuments: singleMaintData?.DocumentsData?.length
      ? singleMaintData?.DocumentsData
      : [
          
        ],
    owner: singleMaintData?.owner || "",
    priority: singleMaintData?.priority || "",
    deadline: singleMaintData?.deadline || "",
    status: singleMaintData?.status || "",
    maintenanceTaskSubtype: singleMaintData?.maintenance_task_sub_type || "",
    organization_id: singleMaintData?.organization_id || "",
    hotel_id: singleMaintData?.hotel_id || "",
  };

  // const handleComment = (value: any) => {
  //   const payload = {
  //     comment: singleMaintData?.commentData,
  //     task_id: singleMaintData?._id,
  //     user_id: userId?._id,
  //   };

  //   dispatch(addComment(payload))
  //     .unwrap()
  //     .then((res) => {
  //       if (res?.success === true) {
  //         toast.success(res?.msg, {
  //           position: "top-center",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );

    const docs = value?.idDocuments.filter((docItem: any) => {
      if (docItem?.document_link) {
        return docItem;
      }
    });

    setLoader(true);

    const payload = {
      task_details: {
        title: value?.title,
        description: value?.description,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        status: value?.status,
        deadline: value?.deadline,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
        assign_to: assignUser ? assignUser : [],
        maintenance_task_sub_type: value?.maintenanceTaskSubtype,
      },
      // attached_files: [],
      attached_files: docs,
    };

    const payloads = {
      task_id: maintId,
      task_details: {
        title: value?.title,
        description: value?.description,
        comments: singleMaintData?.commentData,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        status: value?.status,
        deadline: value?.deadline,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
        assign_to: assignUser ? assignUser : [],
        maintenance_task_sub_type: value?.maintenanceTaskSubtype,
      },
      // attached_files: [],
      attached_files: docs,
    };

    (maintId ? dispatch(updateMaint(payloads)) : dispatch(createMaint(payload)))

      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setRefetch(refetch + 1);
          nav("/maintanance");

          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  const handleClose = () => {
    setCancelPopup(true);
    // setRefetch(refetch + 1);
    // nav("/dashboard");
  };
  function handleClosePopup() {
    setCancelPopup(false);
    setRefetch(refetch + 1);
    nav("/maintanance");
  }
  function handelCancel() {
    setCancelPopup(false);
  }
  const handleDelete = () => {
    setDeleteTask(true);
  };

  return (
    <>
      <div
        className="relative w-full h-[90%]  bg-white rounded-[15px]  "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={AddMaintTaskSchema}
          onSubmit={(values, { resetForm }) => {
            handleTaskFunc(values);
            // maintId && handleComment(values);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => {
            const handleChanged: any = (event: any) => {
              console.log(event, "upload files");
              const image = event.currentTarget.files[0];
              const formData = new FormData();
              formData.append("image", image);
              setLoader(true);
              if (image) {
                dispatch(uploadDocument(formData))
                  .unwrap()
                  .then((res) => {
                    if (res?.status) {
                      setFieldValue(
                        `idDocuments.${values?.idDocuments?.length}`,
                        {
                          document_name: image?.name?.trim(),
                          document_type: "Attach Documents",
                          document_link: res?.imageUrl,
                          document_ext: image?.type,
                          document_field: "idDocuments",
                        }
                      );

                      setLoader(false);
                    } else {
                      toast(res?.msg, {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "dark",
                        type: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    toast(error?.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      theme: "dark",
                      type: "error",
                    });
                  });
              }
            };

            return (
              <Form
                onSubmit={handleSubmit}
                className="h-full flex flex-col justify-between"
              >
                <div className="flex  px-10 flex-col md:px-6 scrollable overflow-auto ">
                  <div className="w-full h-full">
                    <div className="w-full flex justify-between py-6">
                      <div className="flex gap-3">
                        {singleMaintData?._id &&
                          tabs.map((tab: any, index: any) => (
                            <button
                              type="button"
                              key={tab}
                              onClick={() => setActiveTab(index)}
                              className={`
              text-lg font-medium 
              py-2 
              transition-all duration-200 
              focus:outline-none
              ${
                activeTab === index
                  ? "border-b-2 border-[#3D5765] text-[#3D5765]"
                  : "text-gray-200"
              }
              hover:text-[#3D5765]
            `}
                            >
                              {tab}
                            </button>
                          ))}
                      </div>
                      <div className="flex gap-10">
                        {singleMaintData?._id &&
                          AdminUser?.userType === "admin" && (
                            <span
                              className="font-medium "
                              onClick={handleDelete}
                            >
                              <MdDelete
                                size={25}
                                color={"#D65252"}
                                className="cursor-pointer"
                              />
                            </span>
                          )}

                        <RxCross2
                          size={20}
                          onClick={() => nav("/maintanance")}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    <span className="font-semibold py-2">Task Title</span>

                    <div className="  border-[#A0A0A0] rounded-[10px] mt-2">
                      <InputField
                        className="w-full  rounded-lg border border-[#DADADA] italic"
                        fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                        placeholderStyle={{ top: "8px", left: "18px" }}
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        value={values?.title}
                        touched={touched}
                        // fieldDisable={edit}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.title ? errors.title : undefined}
                      />
                    </div>
                    <div className="w-full flex py-4 md:flex-col">
                      <div className="w-3/5 md:w-full">
                        <div className="w-[85%] py-4 md:w-full ">
                          <div className="font-medium pb-2">Description</div>
                          <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                            <textarea
                              placeholder="Add a description..."
                              className="w-full min-h-[120px] outline-none"
                              name="description"
                              id="description"
                              value={values?.description}
                              onChange={handleChange}
                              // disabled={edit}
                            />
                          </div>

                          <div className="w-full py-6  flex flex-col gap-3">
                            <div className="w-full flex gap-2 items-center relative">
                              <span>
                                <img src={PdfIcon} />
                              </span>
                              <span className=" font-semibold ">
                                Attached Files
                              </span>

                              <div className="relative ">
                                <p className="relative  flex font-medium text-[#0094FF] text-[14px] items-center justify-center gap-1">
                                  Upload from here{" "}
                                  <MdOutlineCloudUpload
                                    color="#0094FF"
                                    size={20}
                                    className="mt-1 mx-1 cursor-pointer"
                                  />
                                </p>
                                <input
                                  type="file"
                                  className="absolute  h-full w-full z-20 top-0 left-0 opacity-0"
                                  onChange={(event) => handleChanged(event)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex ">
                            <div className="gap-6 bg-[#E5E5E5] px-2 my-1 rounded-3xl">
                              {(edit ? tabs : ["Attachments"]).map(
                                (tab: any, index: any) => (
                                  <button
                                    type="button"
                                    key={tab}
                                    onClick={() => setActiveTab(index)}
                                    className={`
                               text-[14px] font-medium 
                               py-1 my-2
                               transition-all duration-200  mx-2
                               focus:outline-none rounded-3xl px-2
                               ${
                                 activeTab === index
                                   ? " bg-[#3D5765] text-[#FFFFFF]  transform scale-105"
                                   : "text-gray-200"
                               }
                             
                             `}
                                  >
                                    {tab}
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                      
                          {/* {maintId && (
                            <React.Fragment>
                              <div className="font-medium pb-2">Comment</div>
                              <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                                <textarea
                                  placeholder="Add a Comment..."
                                  className="w-full min-h-[120px] outline-none"
                                  name="comments"
                                  id="comments"
                                  value={singleMaintData?.commentData}
                                  onChange={handleChange}
                                  disabled={edit}
                                />
                              </div>
                            </React.Fragment>
                          )} */}

                          {edit && (
                            <React.Fragment>
                              {commentsDate && commentsDate?.length !== 0 ? (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add{" "}
                                        {singleMaintData?.status} -{" "}
                                        {commentsDate[commentsDate?.length - 1]}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              ) : (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add{" "}
                                        {singleMaintData?.status} -{" "}
                                        {moment(
                                          singleMaintData?.createdAt
                                        ).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              )}
                              <CommentSection
                                length={0}
                                values={values.comment}
                                errors={errors}
                                handleChange={handleChange}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                data={singleMaintData}
                                comment={comment}
                                tabs={tabs}
                                activeTab={activeTab}
                                setComment={setComment}
                                refetch={refetch}
                                setRefetch={setRefetch}
                              />
                            </React.Fragment>
                          )}

                          {(edit ? tabs[activeTab] : "Attachments") ===
                            "Attachments" && (
                            <div className="w-full py-1">
                              <FieldArray
                                name="idDocuments"
                                render={(arrayHelpers) => {
                                  return (
                                    <>
                                      {values?.idDocuments?.map(
                                        (item: any, index: number) => (
                                          <div
                                            key={index}
                                            className=" flex flex-col"
                                          >
                                            <td className="relative ">
                                              <UploadFile
                                                className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                                fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                                placeholderStyle={{
                                                  top: "10px",
                                                  left: "16px",
                                                }}
                                                name={`idDocuments.${index}.document_name`}
                                                location={`idDocuments.${index}`}
                                                arrayName="idDocuments"
                                                placeholder={
                                                  item?.document_type
                                                }
                                                value={item}
                                                fileFrom={"case"}
                                                setFieldValue={setFieldValue}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                index={index}
                                                errors={
                                                  errors?.idDocuments &&
                                                  errors?.idDocuments[index]
                                                    ?.document_name
                                                }
                                                arrayHelpers={arrayHelpers}
                                              />
                                              {index >
                                                initialValues?.idDocuments
                                                  ?.length -
                                                  1 &&
                                                !item?.document_name && (
                                                  <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      X
                                                    </button>
                                                  </div>
                                                )}
                                            </td>
                                          </div>
                                        )
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-[40%] md:w-full h-full px-4  md:px-0 flex flex-col py-4">
                        <MaintTable
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          edit={edit}
                          setFieldValue={setFieldValue}
                          AdminUser={adminUserArray}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 relative "
                  style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                >
                  <div className="w-full flex justify-end gap-4 py-2 px-6">
                    <button
                      onClick={handleClose}
                      type="button"
                      className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      {singleMaintData?._id ? "Update" : "Add Task"}
                    </button>
                  </div>

                  {cancelPopup === true && (
                    <>
                      <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
                      <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
                        <div
                          className="py-4 bg-white  w-[300px] max-h-[80vh] rounded-br-[20px] rounded-[20px] px-4  "
                          style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                        >
                          <div className="flex flex-col justify-center items-centers gap-3">
                            <p className="text-[16px] text-[#000000] font-semibold px-4">
                              Cancel Changes?
                            </p>
                            <p className="text-[14px] text-[#000000] font-medium px-4 py-4">
                              You are about to cancel all changes made to this
                              item. If you proceed, your unsaved edits will be
                              lost, and the item will revert to its previous
                              state.
                            </p>

                            <div className="w-full flex justify-end gap-4 ">
                              <button
                                onClick={handelCancel}
                                className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                cancel
                              </button>
                              <button
                                onClick={handleClosePopup}
                                className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                Okay
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}

      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={singleMaintData}
          setRefetch={setRefetch}
          refetch={refetch}
          maintId={maintId}
        />
      )}
    </>
  );
};

export default AddSurestayMaint;
