import { useEffect, useState } from "react";
import { LuDelete } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import SucessPopups from "./SucessPopups";
import { useAppDispatch } from "../Redux/Hooks";
import {
  deleteDispute,
  deleteLabCase,
  deleteLead,
  deleteMaintanance,
  deleteTask,
} from "../Slices/TaskSlice";
import toast from "react-hot-toast";
import ConfirmPopups from "./ConfirmPopups";

interface propsType {
  setDeleteTask: (a: boolean) => void;
  taskDetail: any;
  setViewTask?: any;
  refetch: any;
  setRefetch: any;
  maintId?: any;
  taskId?: any;
  leadId?: any;
  labCaseId?: any;
  disputeId?: any
}

const DeleteTaskPopups: React.FC<propsType> = ({
  setDeleteTask,
  taskDetail,
  setViewTask,
  refetch,
  setRefetch,
  maintId,
  taskId,
  leadId,
  labCaseId,
  disputeId
}) => {
  const dispatch = useAppDispatch();
  const [popup, setPopup] = useState(false);
  const [value, setValue] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [confirmValue, setConfirmValue] = useState("");
  const [refetched, setRefetched] = useState(1);

  useEffect(() => {
    if (confirmValue.toLowerCase() === "yes") {
      DeleteFunc();
      setConfirmValue("");
    } else if (confirmValue.toLowerCase() === "no") {
      setConfirm(false);
      setConfirmValue("");
    }
  }, [refetched]);

  const DeleteFunc = () => {
    const payload = {
      task_id: taskDetail?._id,
    };
    const payloads = {
      task_id: taskId,
    };
    const payloadLead = {
      task_id: leadId,
    };
    const payloadLabCase = {
      lab_id: labCaseId,
    };
    const payloadDispute = {
      task_id: disputeId
    };
    (maintId
      ? dispatch(deleteMaintanance(payload))
      : taskId
      ? dispatch(deleteTask(payloads))
      : leadId
      ? dispatch(deleteLead(payloadLead))
      : labCaseId
      ? dispatch(deleteLabCase(payloadLabCase))
      : disputeId ? dispatch(deleteDispute(payloadDispute))
      : dispatch(deleteTask(payloadLead))
    )
      .unwrap()
      .then((res: any) => {
        if (res?.success === true) {
          setConfirm(false);
          // toast(res?.message,{
          //   position:"top-right"
          // })
          setPopup(true);
          setRefetch(refetch + 1);
        }
      })
      .catch((error: any) => {
        toast(error, {
          position: "top-right",
        });
      });
  };

  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[999]  bg-[#00000040]">
      <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[350px] h-[200px] md:w-[320px]  rounded-[10px] z-[100] flex-col p-6 md:p-4"
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className="flex  w-full h-[20px] justify-between items-center py-4 ">
          <div className=" flex gap-2 items-center">
            <LuDelete color={"#FF2F2F"} size={20} />
            <span className="font-semibold">Delete Task?</span>
          </div>
          <span>
            <RxCross2
              onClick={() => {
                setDeleteTask(false);
              }}
            />
          </span>
        </div>
        <div className="text-center flex flex-col w-full py-4  ">
          <span className="pl-4 md:pl-0">
            This task will be deleted permanently.
          </span>
          <span> Are you sure you want to proceed?</span>
        </div>
        <div className="w-full flex gap-4 justify-center  py-2">
          <button
            onClick={() => setDeleteTask(false)}
            className="px-8 py-[6px] border border-[#A0A0A0] rounded-[8px]"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              setConfirm(true);
            }}
            className="px-8 py-[6px] border-none text-white bg-[#3D5765] font-semibold rounded-[8px]"
            style={{ boxShadow: " 0px 4px 10px 0px #BAC9DB" }}
          >
            Delete
          </button>
        </div>
      </div>
      {popup && (
        <SucessPopups
          setPopup={setPopup}
          setDeleteTask={setDeleteTask}
          value={value}
          setViewTask={setViewTask}
        />
      )}

      {confirm && (
        <ConfirmPopups
          setConfirm={setConfirm}
          confirmValue={confirmValue}
          setConfirmValue={setConfirmValue}
          refetch={refetched}
          setRefetch={setRefetched}
          popup={popup}
        />
      )}
    </div>
  );
};

export default DeleteTaskPopups;
