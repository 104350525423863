import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";
import { BiSolidFileJpg, BiSolidFilePdf } from "react-icons/bi";

import { useAppSelector } from "../../Redux/Hooks";
import pdfIcon from "../../assets/image/pdfIcon.png";
import DragSVG from "../../assets/image/svg/DragSVG";
import todoIcon from "../../assets/image/todoIcon.png";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";
import inProgressIcon from "../../assets/image/in-progress.png";
import completedIcon from "../../assets/image/completed-solid.png";
import privatetaskIcon from "../../assets/image/privatetaskIcon.png";

type Size = "small" | "medium" | "large";

const CartTask = ({
  size,
  item,
  setViewTask,
  icon,
  index,
  taskDetail,
  setTaskDetail,
  type,
}: any) => {
  const nav = useNavigate();
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const handleChange = () => {
    const data = [item];
    nav("/edit", { state: data });
  };
  const fullName = item?.title;
  const nameParts = fullName.split(" ");
  const firstInitial = nameParts[0]?.charAt(0);
  const lastInitial = nameParts[0]?.charAt(1);
  const scaleClass = {
    small: "text-80",
    medium: "text-90",
    large: "text-100",
  }[size as Size];

  const currentDate = new Date();
  const daysRemaining = moment(item?.deadline).diff(
    moment(currentDate),
    "days"
  );

  const borderClass =
    item?.status !== "completed" && daysRemaining <= 5
      ? "border-solid border-2 border-red-600"
      : "";

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided) => (
        <div
          key={index}
          onDoubleClick={handleChange}
          className={`h-auto  w-auto mx-2 p-3 m-1  bg-white rounded-[14px] cursor-pointer ${scaleClass} ${borderClass}
`}
          style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="w-full flex justify-between  items-center ">
            <div className="flex items-center gap-3 w-[55%]">
              {item?.status === "todo" ? (
                <span>
                  <img src={todoIcon} alt="Todo" />
                </span>
              ) : item?.status === "completed" ? (
                <span>
                  <img src={completedIcon} alt="Completed" />
                </span>
              ) : item?.status === "inprogress" ? (
                <span>
                  <img src={inProgressIcon} alt="Completed" />
                </span>
              ) : (
                ""
              )}

              <div className="flex flex-row gap-1 items-center justify-center">
                <img src={pdfIcon} alt="pdf icon" />
                <span> {item?.DocumentsData?.length}</span>
              </div>
            </div>

            <div className="flex items-center gap-2">
              <span className="">
                <DragSVG />
              </span>
              {/* <BsThreeDotsVertical size={20} /> */}
            </div>
          </div>
          {/* <span className="w-[14px] h-[14px]"> {icon}</span> */}
          <div className="w-full my-2  flex justify-between">
            <span className="text-[14px]  font-medium text-black">
              Title: {item?.title}
            </span>
          </div>
          {item?.task_type === "public" && (
            <div className="flex flex-row gap-2 pb-2">
              <span className="text-[8px] flex items-center justify-center w-[15px] h-[15px] py-3 px-3 rounded-full  font-normal bg-[#0496FF33] text-[#0094FF] cursor-pointer">
                {firstInitial + lastInitial}
              </span>
              <span className="text-[8px] flex items-center justify-center w-[15px] h-[15px] py-3 px-3 rounded-full  font-normal bg-[#0496FF33] text-[#0094FF] cursor-pointer">
                {firstInitial + lastInitial}
              </span>
            </div>
          )}

          <div className="border border-[#EBEBEB]"></div>
          <div className="flex justify-between items-center gap-2 mt-2">
            <p className="text-[14px] text-[#8591A2]">
              Created by: {item?.ownerDetails[0]?.name}
            </p>
            {item?.task_type === "private" && <img src={privatetaskIcon} />}
            <p className="flex items-center gap-1">
              {/* <CalenderSVG /> */}
              {item?.status === "completed" ? (
                <span className="text-[10px] text-[#8591A2]">
                  Completed at : {moment(item?.updatedAt).fromNow()}
                </span>
              ) : (
                <span className="text-[10px] text-[#8591A2]">
                  Past Due Days:{" "}
                  {moment(item?.deadline).diff(moment(currentDate), "days") < 0
                    ? "overdue"
                    : moment(item?.deadline).diff(moment(currentDate), "days")}
                </span>
              )}
            </p>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CartTask;
