import moment from "moment";

interface propsType {
  taskDetail?: any;
}

const TaskDetailsTable: React.FC<propsType> = ({ taskDetail }) => {
  return (
    <>
      <span className=" text-[14px] font-medium  py-2 ">Task Details</span>
      <div className="w-full flex border h-[400px] p-4 scrollable overflow-auto border-[#A0A0A0] rounded-[10px] ">
        <table className="w-full ">
          <tbody>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Assignee:</td>
              <td className="text-[14px]">
                {taskDetail?.assignUsersData?.map(
                  (item: any) => item?.name?.toUpperCase() + " "
                )}
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Owner:</td>
              <td className="text-[14px]">
                {taskDetail?.ownerDetails[0]?.name.toUpperCase()}
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Priority:</td>
              <td className="text-[14px]">
                {taskDetail?.priority?.toUpperCase()}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Deadline:</td>
              <td className="text-[14px]">
                {moment(taskDetail?.deadline).format("Do MMMM  YYYY")}
              </td>
            </tr>

            <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Status:</td>
              <td className="text-[14px]">
                {taskDetail?.status?.toUpperCase()}
              </td>
            </tr>
            <tr className="border-b border-[#DADADA]">
              <td className=" py-2 text-[#7A7A7A]">Creation Date:</td>
              <td className="text-[14px]">
                {moment(taskDetail?.createdAt).format("Do MMMM  YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TaskDetailsTable;
