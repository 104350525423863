import { useState } from "react";
// import toast from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";

import TaskTable from "../Tables/TaskTable";
import Loader from "../../assets/image/svg/Loader";
import { createTask, uploadDocument } from "../../Slices/TaskSlice";
import UploadFile from "../Field Components/UploadFile";
import InputField from "../Field Components/InputField";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { AddTaskSchema } from "../../Validations/FormikValidation";
import { MdOutlineCloudUpload } from "react-icons/md";
import { toast } from "react-toastify";

const AddTask = ({ refetch, setRefetch }: any) => {
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const [loader, setLoader] = useState(false);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];
  const tabs: any = ["Comments", "Status", "Attachments"];
  const [activeTab, setActiveTab] = useState<number>(0);
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const initialValues: any = {
    title: "",
    description: "",
    comment: "",
    assignee: [],
    taskType: "public",
    idDocuments: [],
    // owner: "",
    priority: "",
    deadline: "",
    // taskType: "",
    status: "",
    // creationDate: "",
    organization_id: "",
    hotel_id: "",
  };

  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );
    setLoader(true);

    const payload = {
      task_details: {
        title: value?.title,
        description: value?.description,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        // status: value?.status,
        status: "todo",
        deadline: value?.deadline,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
        assign_to: assignUser ? assignUser : [""],
        task_type: value?.taskType,
      },
      attached_files: value?.idDocuments ? value?.idDocuments : [],
    };
    dispatch(createTask(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setRefetch(refetch + 1);
          nav("/dashboard");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  return (
    <>
      <div
        className="relative w-full h-full  bg-white rounded-[15px] scrollable overflow-auto  "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={AddTaskSchema}
          onSubmit={(values, { resetForm }) => {
            handleTaskFunc(values);
            console.log(values);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => {
            const handleChanged: any = (event: any) => {
              const image = event.currentTarget.files[0];
              const formData = new FormData();
              formData.append("image", image);
              setLoader(true);
              if (image) {
                dispatch(uploadDocument(formData))
                  .unwrap()
                  .then((res) => {
                    if (res?.status) {
                      setFieldValue(
                        `idDocuments.${values?.idDocuments?.length}`,
                        {
                          document_name: image?.name?.trim(),
                          document_type: "Attach Documents",
                          document_link: res?.imageUrl,
                          document_ext: image?.type,
                          document_field: "idDocuments",
                        }
                      );

                      setLoader(false);
                    } else {
                      toast(res?.msg, {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "dark",
                        type: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    toast(error?.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      theme: "dark",
                      type: "error",
                    });
                  });
              }
            };

            return (
              <Form
                onSubmit={handleSubmit}
                className="h-full flex flex-col justify-between"
              >
                <div className="flex overflow-auto  px-10 flex-col md:px-6  ">
                  <div className="w-full h-full">
                    <div className="w-full flex justify-between py-4">
                      <span className="font-semibold">Task Title</span>
                      <div className="flex gap-10">
                        <RxCross2 size={20} onClick={() => nav("/dashboard")} />
                      </div>
                    </div>
                    <div className="  border-[#A0A0A0] rounded-[10px] ">
                      <InputField
                        className="w-full  rounded-lg border border-[#DADADA] italic"
                        fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                        placeholderStyle={{ top: "8px", left: "18px" }}
                        type="text"
                        name="title"
                        placeholder="Enter Title"
                        value={values?.title}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.title ? errors.title : undefined}
                      />
                    </div>
                    <div className="w-full flex py-4 md:flex-col">
                      <div className="w-3/5 md:w-full">
                        <div className="w-[85%] py-4 md:w-full ">
                          <div className="font-medium pb-2">Description</div>
                          <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                            <textarea
                              placeholder="Add a description..."
                              className="w-full min-h-[120px] outline-none"
                              name="description"
                              id="description"
                              value={values?.description}
                              onChange={handleChange}
                            />
                          </div>

                          <div className="w-full py-6  flex flex-col gap-3">
                            <div className="w-full flex gap-2 items-center relative">
                              <span className=" font-semibold ">
                                {" "}
                                Attached Files
                              </span>

                              <div className="relative ">
                                <p className="relative  flex font-medium text-[#0094FF] text-[14px] items-center justify-center gap-1">
                                  Upload from here{" "}
                                  <MdOutlineCloudUpload
                                    color="#0094FF"
                                    size={20}
                                    className="mt-1 mx-1 cursor-pointer"
                                  />
                                </p>
                                <input
                                  type="file"
                                  className="absolute  h-full w-full z-20 top-0 left-0 opacity-0"
                                  onChange={(event) => handleChanged(event)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex ">
                            <div className="gap-6 bg-[#E5E5E5] px-2 my-1 rounded-3xl">
                              {["Attachments"].map((tab: any, index: any) => (
                                <button
                                  type="button"
                                  key={tab}
                                  onClick={() => setActiveTab(index)}
                                  className={`
                               text-[14px] font-medium 
                               py-1 my-2
                               transition-all duration-200  mx-2
                               focus:outline-none rounded-3xl px-2
                               ${
                                 activeTab === index
                                   ? " bg-[#3D5765] text-[#FFFFFF]  transform scale-105"
                                   : "text-gray-200"
                               }
                             
                             `}
                                >
                                  {tab}
                                </button>
                              ))}
                            </div>
                          </div>
                          {/* {tabs[activeTab] === "Attachments" && ( */}
                          <div className="w-full py-1">
                            <FieldArray
                              name="idDocuments"
                              render={(arrayHelpers) => {
                                return (
                                  <>
                                    {values?.idDocuments?.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={index}
                                          className=" flex flex-col"
                                        >
                                          <td className="relative ">
                                            <UploadFile
                                              className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                              fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                              placeholderStyle={{
                                                top: "10px",
                                                left: "16px",
                                              }}
                                              name={`idDocuments.${index}.document_name`}
                                              location={`idDocuments.${index}`}
                                              arrayName="idDocuments"
                                              placeholder={item?.document_type}
                                              value={item}
                                              fileFrom={"case"}
                                              setFieldValue={setFieldValue}
                                              onBlur={handleBlur}
                                              touched={touched}
                                              errors={
                                                errors?.idDocuments &&
                                                errors?.idDocuments[index]
                                                  ?.document_name
                                              }
                                            />
                                            {index >
                                              initialValues?.idDocuments
                                                ?.length -
                                                1 &&
                                              !item?.document_name && (
                                                <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )}
                                          </td>
                                        </div>
                                      )
                                    )}
                                  </>
                                );
                              }}
                            />
                          </div>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="w-[40%] md:w-full h-full px-4  md:px-0 flex flex-col py-4">
                        <TaskTable
                          values={values}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          AdminUser={adminUserArray}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 "
                  style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                >
                  <div className="w-full flex justify-end gap-4 py-2">
                    <button
                      onClick={() => nav(-1)}
                      type="button"
                      className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      Add Task
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default AddTask;
