import React, { useEffect, useRef, useState } from "react";
import { BiSolidFilePdf } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiSolidFileJpg } from "react-icons/bi";
import { FaFileWord } from "react-icons/fa";
import { toast } from "react-toastify";
import { FaCircleArrowRight } from "react-icons/fa6";
import { useAppDispatch } from "../../Redux/Hooks";
import { useOutSideCheck } from "../customize state/useOutSideCheck";
import { uploadDocument } from "../../Slices/TaskSlice";
import Loader from "../../assets/image/svg/Loader";
import MiniSelectPopup from "../Modal Component/MiniSelectPopup";

interface FieldPropsType {
  className?: string;
  fieldClass?: string;
  fieldStyle?: object;
  placeholderStyle?: object;
  labelName?: string;
  required?: Boolean;
  value?: valueType;
  location?: string;
  data?: any;
  arrayName?: string;
  placeholder?: string;
  fieldDisable?: boolean;
  fileFrom: string;
  name: string;
  onBlur: any;
  setFieldValue: any;
  errors: any;
  touched: any;
  arrayHelpers?: any;
  index?: any;
}
interface valueType {
  id?: string;
  document_ext: string;
  document_field: string;
  document_link: string;
  document_name: string;
  document_type: string;
}
interface popupData {
  case_id: string;
  created_at: string;
  document_ext: string;
  document_field: string;
  document_link: string;
  document_name: string;
  document_type: string;
  id: number;
  is_verified: boolean;
  updated_at: string;
}

const UploadFile: React.FC<FieldPropsType> = ({
  className,
  fieldClass,
  fieldStyle,
  placeholderStyle,
  labelName,
  name,
  location,
  data,
  arrayName,
  onBlur,
  value,
  fileFrom,
  setFieldValue,
  placeholder,
  errors,
  touched,
  required,
  arrayHelpers,
  fieldDisable = false,
  index,
}) => {
  const dispatch = useAppDispatch();
  // const { loading } = useAppSelector((state) => state.user);
  const [popupShow, setPopupShow] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [rename, setRename] = useState(value?.document_name?.trim());
  const [viewPopup, setViewPopup] = useState<popupData | {}>({});
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);

  useOutSideCheck(wrapperRef, setPopupShow, popupShow);

  const handleChanged: any = (event: any) => {
    const image = event.currentTarget.files[0];
    const formData = new FormData();
    formData.append("image", image);
    setDefaultValue("");
    setLoading(true);

    if (image) {
      dispatch(uploadDocument(formData))
        .unwrap()
        .then((res) => {
          if (res?.status) {
            setRename(image?.name?.trim());
            setFieldValue(
              name === "add_document"
                ? `${arrayName}.${data?.length - 1}`
                : location,
              {
                document_name: image?.name?.trim(),
                document_type: placeholder,
                document_link: res?.imageUrl,
                document_ext: image?.type,
                document_field: arrayName,
              }
            );
          
            setLoading(false);
         
          } else {
            toast(res?.msg, {
              position: "top-right",
              autoClose: 5000,
              theme: "dark",
              type: "error",
            });
          }
        })
        .catch((error) => {
          
          toast(error?.msg, {
            position: "top-right",
            autoClose: 5000,
            theme: "dark",
            type: "error",
          });
        });
    }
  };

  useEffect(() => {
    if (selectValue === "Delete") {
      if(arrayHelpers){
        arrayHelpers.remove(index)
      }else
      setFieldValue(
        name === "add_document" ? `${arrayName}.${data?.length - 1}` : location,
        {
          document_name: "",
          document_type: value?.document_type,
          document_link: "",
          document_ext: "",
          document_field: value?.document_field,
        }
      );
      setSelectValue("");
    } else if (selectValue === "Rename") {
      setRename(value?.document_name?.trim());
    }
  }, [selectValue]);
  
  const handleView = (item: {}) => {
    setViewPopup(item);
  };

  return (
    <>
      {!value?.document_name ? (
        <>
          <div className={className + " mb-2 relative"} style={fieldStyle}>
            {/* {labelName && <label htmlFor={name}> {labelName} </label>}  */}
            <input
              className={
                fieldClass +
                " bg-transparent relative z-10 opacity-0 " +
                `${fieldDisable && "text-[#8a8a8a]"}`
              }
              type={"file"}
              accept="image/*,.pdf,.doc,.docx"
              name={
                name === "add_document"
                  ? `${arrayName}.${data?.length - 1}.document_name`
                  : name
              }
              id={name}
              value={defaultValue}
              onChange={handleChanged}
              onBlur={onBlur}
              disabled={fieldDisable}
            />

            <p
              className="capitalize text-[#B4B4B4] text-[14px] font-medium absolute z-[0] sm:text-[12px] md:text-[14px] sm:mt-[2px]"
              style={placeholderStyle}
            >
              {placeholder}
              <span className="text-[#FF5050]">{required && "*"}</span>
            </p>

            <div
              className={
                "text-[12px] absolute capitalize font-medium bg-white pl-1 sm:text-[10px] "
              }
              style={{ ...placeholderStyle, left: "auto", right: "20px" }}
            >
              {loading ? <Loader /> : "Browse File"}
            </div>
            <div className="text-[#FF5050] text-xs absolute capitalize font-medium bottom-[-48px] pt-1">
              {errors && touched && typeof errors === "string" && errors}
            </div>
          </div>
       
        </>
      ) : (
        <div
          className="flex flex-row items-center gap-1 p-[2px] bg-[#F6F6F6] rounded-md mb-2 pr-8 relative"
          style={{ boxShadow: "0px 2px 8px 0px #00000040" }}
          ref={wrapperRef}
        >
          {value?.document_ext?.includes("image") ? (
            <BiSolidFileJpg color="#007cb8" size={40} />
          ) : value?.document_ext?.includes("pdf") ? (
            <BiSolidFilePdf color="#DF0000" size={40} />
          ) : (
            <FaFileWord color="#007cb8" size={48} />
          )}
          <div className="w-full">
            {selectValue === "Rename" ? (
              <div className="flex items-center mb-1 gap-2 w-full">
                <input
                  className="border rounded-md px-2 w-[85%]"
                  type="text"
                  name=""
                  id=""
                  value={rename}
                  onChange={(e) => setRename(e.target.value)}
                />
                <FaCircleArrowRight
                  className="text-primary cursor-pointer"
                  size={20}
                  // onClick={handleRename}
                />
              </div>
            ) : (
              <h3 className="font-medium font text-[12px] pb-1 leading-tight max-w-[90%] break-all">
                {rename ? rename : value?.document_name}
              </h3>
            )}
            <p className="flex gap-4">
              <a
                href={value?.document_link}
                target="_blank"
                className="text-[#0096C6] text-[12px] flex flex-row items-center gap-1"
              >
                Download <MdOutlineFileDownload />
              </a>

            </p>
          </div>
          {!fieldDisable && (
            <span
              className="absolute top-2 right-3 cursor-pointer"
              onClick={() => setPopupShow(!popupShow)}
            >
              <BsThreeDots />
              {popupShow && (
                <MiniSelectPopup
                  data={["Delete"]}
                  setPopupShow={setPopupShow}
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                />
              )}
            </span>
          )}
        </div>
      )}

    </>
  );
};

export default UploadFile;
