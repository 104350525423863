import { SVGProps } from "react"

const AddLeadSvg = (props: SVGProps<SVGSVGElement>) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={31}
    height={31}
    fill="none"
    viewBox="0 0 64 66"
    {...props}
  >
    <rect width={31} height={31} y={0.611} fill="#3E5F71" rx={15} />
    <path
      fill="#A4A6B9"
      stroke="#A4A6B9"
      strokeWidth={0.7}
      d="M22.884 26.321c-.157 1.171-.16 2.723-.16 4.958v4.666c0 2.235.003 3.788.16 4.959V26.32Zm0 0c.152-1.134.43-1.725.847-2.143m-.847 2.143.847-2.143m2.144 19.715c1.17.158 2.722.16 4.957.16h2.334c2.234 0 3.787-.002 4.958-.16 1.133-.152 1.724-.43 2.142-.848.417-.417.695-1.008.848-2.142.157-1.17.16-2.723.16-4.958V31.28c0-2.235-.003-3.787-.16-4.959-.153-1.133-.43-1.724-.848-2.142-.418-.417-1.009-.695-2.143-.848-1.17-.157-2.723-.16-4.957-.16h-2.334c-2.235 0-3.785.003-4.957.16v20.563Zm0 0c-1.135-.152-1.725-.43-2.143-.848-.418-.417-.696-1.008-.848-2.141l2.99 2.99ZM23.73 24.178c.419-.417 1.01-.695 2.144-.848l-2.144.849Zm14.72-3.276c-1.353-.182-3.07-.182-5.194-.182h-2.514c-2.124 0-3.84 0-5.193.182-1.407.19-2.6.595-3.55 1.543-.948.95-1.354 2.144-1.543 3.55-.182 1.352-.182 3.07-.182 5.193V36.036c0 2.123 0 3.84.182 5.193.19 1.406.595 2.6 1.543 3.55.95.948 2.143 1.353 3.55 1.543 1.352.182 3.07.182 5.193.182h2.514c2.124 0 3.84 0 5.193-.182 1.407-.19 2.6-.595 3.55-1.544.948-.95 1.354-2.143 1.543-3.549.182-1.352.182-3.07.182-5.193V31.188c0-2.123 0-3.84-.182-5.193-.19-1.406-.595-2.6-1.543-3.55-.95-.948-2.143-1.354-3.55-1.543Zm-11.984 9.51a1.225 1.225 0 0 0 .866 2.092h9.334a1.225 1.225 0 1 0 0-2.45h-9.334c-.325 0-.636.129-.866.358Zm0 4.667a1.225 1.225 0 0 0 .866 2.091h5.834a1.225 1.225 0 1 0 0-2.45h-5.834c-.325 0-.636.13-.866.36Z"
    />
  </svg>
)
export default AddLeadSvg
