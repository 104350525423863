import { useParams } from "react-router-dom";
import NewHeader from "../Components/Header Component/NewHeader";
import AddSurestayMaint from "../Components/maintananceComponent/AddSurestayMaint";
import { useAppDispatch } from "../Redux/Hooks";
import { useEffect, useState } from "react";
import { getSingleMaintanance } from "../Slices/TaskSlice";
import toast from "react-hot-toast";

const AddMaintanance = ({ refetch, setRefetch}: any) => {
  const { maintId } = useParams();
  const [singleMaintData, setSingleMaintData] = useState<any | null>(null);
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const getSingleMaintData = async () => {
    setLoader(true);
    const payload = {
      task_id: maintId,
    };
    await dispatch(getSingleMaintanance(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setSingleMaintData(res?.taskData?.[0]);
          toast("Successful ✅", {
            position: "top-right",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  useEffect(() => {
    maintId && getSingleMaintData();
  }, [maintId]);
  return (
    <div className="h-full w-full flex">
      <div
        className="flex h-full flex-col gap-2 py-4 px-10 md:px-6"
        style={{ width: "100%" }}
      >
        <NewHeader heading={maintId ? "Edit Maint" : "Add Maint"} />
        <AddSurestayMaint
          maintId={maintId}
          setLoader={setLoader}
          loader={loader}
          refetch={refetch}
          setRefetch={setRefetch}
          singleMaintData={singleMaintData}
        />
      </div>
    </div>
  );
};

export default AddMaintanance;
