import { useState } from "react";
import SearchBar from "../Header Component/SearchBar";
import RespoSideBar from "./RespoSidebar";
import RespoSelectTask from "./RespoSelectTask";
import NewLead from "../Lead Components/NewLead";
import FollowingUpLead from "../Lead Components/FollowingUpLead";
import Booked from "../Lead Components/Booked";
import CompletedLead from "../Lead Components/CompletedLead";
import NotInterested from "../Lead Components/NotInterested";

interface PropsType {
  refetch: any;

  showScreen: any;
  setShowScreen: any;
  completed: any;
  inprogress: any;
  todo: any;
  taskData: any;
  setRefetch: any;
  leadData: any;
  setLeadData: any;
  openComment: any;
  setOpenComment: any;
  addLead: any;
  setViewTask?: any;
  button: string;
  setTaskDetail: any;
  taskDetail: any;
  myTask: any;
  name: any;
  
}

const RespoAllLead: React.FC<PropsType> = ({
  showScreen,
  setShowScreen,
  completed,
  inprogress,
  todo,
  taskData,
  leadData,
  setLeadData,
  setRefetch,
  refetch,
  addLead,
  openComment,
  setOpenComment,
  button,
  taskDetail,
  myTask,
  name,
  setTaskDetail
}) => {
  const [showTask, setShowTask] = useState<string>("");

  const detail = [
    {
      name: "new",
      heading: "New Lead Created",
      desc: "The task is completed by the team.",
    },
    {
      name: "following_up",
      heading: "Lead is following up",
      desc: "The lead is following up  by the team.",
    },
    {
      name: "booked",
      heading: "Lead booked",
      desc: "The lead is booked by the team.",
    },
    {
      name: "Completed",
      heading: "Task Completed",
      desc: "The task is completed by the team.",
    },
    {
      name: "not_interested",
      heading: "lead not interested",
      desc: "The lead is not interested by the team.",
    },
  ];

  return (
    <>
      <div className="w-[100%] h-[100%] px-[20px] py-[10px] ">
        <SearchBar />
        <div
          className="w-full flex  flex-col "
          style={{ height: "calc(100% - 110px)" }}
        >
          {showTask ? (
            showTask === "new" ? (
              <NewLead
                todo={todo}
                openComment={openComment}
                taskDetail={taskDetail}
                addLead={addLead}
                myTask={myTask}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
                setTaskDetail={setTaskDetail}
              />
            ) : showTask === "following_up" ? (
              <FollowingUpLead
                inprogress={inprogress}
                taskDetail={taskDetail}
                openComment={openComment}
                setTaskDetail={setTaskDetail}
                addLead={addLead}
                myTask={myTask}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
              />
            ) : showTask === "booked" ? (
              <Booked
                taskData={taskData}
                setTaskDetail={setTaskDetail}
                taskDetail={taskDetail}
                openComment={openComment}
                addLead={addLead}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />
            ) : showTask === "Completed" ? (
              <CompletedLead
                completed={completed}
                setTaskDetail={setTaskDetail}
                openComment={openComment}
                addLead={addLead}
                taskDetail={taskDetail}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />
            ) : (
              <NotInterested
                leadData={leadData?.notInterested}
                setTaskDetail={setTaskDetail}
                openComment={openComment}
                addLead={addLead}
                taskDetail={taskDetail}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />
            )
          ) : (
            <RespoSelectTask
              button={button}
              setShowTask={setShowTask}
              name={name}
              detail={detail}
            />
          )}
        </div>
      </div>

      {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RespoAllLead;
