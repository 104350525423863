
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from '../Redux/Hooks';
import { getSingleLabCase } from '../Slices/TaskSlice';
import AddLabCase from '../Components/LabCases/AddLabCase'
import NewHeader from '../Components/Header Component/NewHeader'

const AddLabCasesScreen = ({ refetch, setRefetch }: any) => {
  const { labCaseId } = useParams();
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [singleLab, setSingleLab] = useState<any | null>(null);
  const getSingleLabData = async () => {
    setLoader(true);
    const payload = {
      task_id: labCaseId,
    };
    await dispatch(getSingleLabCase(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setSingleLab(res?.labCaseData?.[0]);
          toast("Successful ✅", {
            position: "top-right",
          });
        }
        setLoader(false);
      })
      .catch((error: any) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  useEffect(() => {
    labCaseId && getSingleLabData();
  }, [labCaseId]);

  console.log(singleLab, "single lab data", labCaseId)
  return (
    <div className="h-full w-full flex  ">
      <div className="h-full w-full flex flex-col gap-2 py-4 px-10 md:px-6">
        <NewHeader heading={labCaseId ? "Update Lab Case" : "Add Lab Case"} />
        <AddLabCase refetch={refetch} setRefetch={setRefetch} labCaseId={labCaseId} singleLab={singleLab} loader={loader} setLoader={setLoader} />
      </div>
    </div>
  )
}

export default AddLabCasesScreen