import { useState } from "react";
import SearchBar from "../Header Component/SearchBar";
import RespoSideBar from "./RespoSidebar";
import RespoSelectTask from "./RespoSelectTask";
import NewLead from "../Lead Components/NewLead";
import FollowingUpLead from "../Lead Components/FollowingUpLead";
import Booked from "../Lead Components/Booked";
import CompletedLead from "../Lead Components/CompletedLead";
import NotInterested from "../Lead Components/NotInterested";
import Completed from "../LabCases/Completed";
import Recieved from "../LabCases/Recieved";
import PickedUp from "../LabCases/PickedUp";
import Ready from "../LabCases/Ready";

interface PropsType {
  refetch: any;
  showScreen: any;
  setShowScreen: any;
  completed: any;
  inprogress: any;
  todo: any;
  setRefetch: any;
  openComment: any;
  setOpenComment: any;
  setViewTask?: any;
  button: string;
  setTaskDetail: any;
  taskDetail: any;
  myTask: any;
  name: any;
  size: any;
  labCaseData: any
  addLabCase: any;
 
}

const RespoAllLabCase: React.FC<PropsType> = ({
  showScreen,
  setShowScreen,
  completed,
  inprogress,
  todo,
  setRefetch,
  refetch,
  size,
  openComment,
  setOpenComment,
  button,
  taskDetail,
  myTask,
  name,
  setTaskDetail,
  addLabCase,
  setViewTask,
  labCaseData
}) => {
  const [showTask, setShowTask] = useState<string>("");

  const detail = [
    {
      name: "Ready",
      heading: "Lab Case Ready",
      desc: "The Lab Case is ready by the team.",
    },
    {
      name: "Picked Up",
      heading: "Lab Case is picked up",
      desc: "The Lab Case is picked up  by the team.",
    },
    {
      name: "Recieved",
      heading: "Lab Case recieved",
      desc: "The Lab Case is recieved by the team.",
    },
    {
      name: "Delivered",
      heading: "Lab Case Delivered",
      desc: "The Lab Case is Delivered by the team.",
    },
  ];

  return (
    <>
      <div className="w-[100%] h-[100%] px-[20px] py-[10px] ">
        <SearchBar />
        <div
          className="w-full flex  flex-col "
          style={{ height: "calc(100% - 110px)" }}
        >
          {showTask ? (
            showTask === "Ready" ? (
              <Ready
                todo={todo}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />
            ) : showTask ==="Picked Up" ? (
              <PickedUp
                size={size}
                myTask={myTask}
                setRefetch={setRefetch}
                inprogress={inprogress}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />
            ) : showTask ==="Recieved" ? (
              <Recieved
                size={size}
                myTask={myTask}
                setRefetch={setRefetch}
                labCaseData={labCaseData}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />
            ) :  (
              <Completed
                size={size}
                setRefetch={setRefetch}
                myTask={myTask}
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />
            ) 
          ) : (
            <RespoSelectTask
              button={button}
              setShowTask={setShowTask}
              name={name}
              detail={detail}
            />
          )}
        </div>
      </div>

      {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RespoAllLabCase;
