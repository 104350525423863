import moment from "moment";
import toast from "react-hot-toast";
import React, { useState } from "react";
import { FaCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";

import Loader from "../../assets/image/svg/Loader";
import PdfIcon from "../../assets/image/pdf-icon.png";
import DateField from "../Field Components/DateField";
import UploadFile from "../Field Components/UploadFile";
import InputField from "../Field Components/InputField";
import SelectField from "../Field Components/SelectField";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import CommentSection from "../maintananceComponent/CommentSection";
import { AddDisputeSechma } from "../../Validations/FormikValidation";
import { createDispute, updateDispute } from "../../Slices/TaskSlice";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";
import { MdDelete } from "react-icons/md";

const AddNewDispute = ({
  refetch,
  setRefetch,
  setUdateDispute,
  setAddDispute,
  item,
}: any) => {
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const [loader, setLoader] = useState(false);
  const disputeStatus = [
    "new",
    "contesting",
    "won_partial",
    "won_closed",
    "lost_closed",
  ];

  const nav = useNavigate();
  const [comment, setComment] = useState([]);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [edit, setEdit] = useState(item?._id ? true : false);
  const dispatch = useAppDispatch();
  const disputedDate = moment(item?.disputed_date).format("YYYY-MM-DD");
  const [deletetask, setDeleteTask] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs: any = ["All Comments", "Status"];
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];

  function handleUpdate() {
    item?._id ? setUdateDispute(false) : setAddDispute(false);
  }

  const initialValues = {
    guestName: item?.guest_name || "",
    disputedAmount: item?.disputed_amount || "",
    disputeDate: disputedDate || "",
    status: item?.status || "",
    organization_id: item?.organization_id || "",
    hotel_id: item?.hotel_id || "",
    idDocuments: item?.DocumentsData?.length
      ? item?.DocumentsData
      : [
          {
            createdAt: "",
            document_ext: "",
            document_field: "idDocuments",
            document_link: "",
            document_name: "",
            document_type: "Attach Documents",
            task_id: "",
            updatedAt: "",
            __v: 0,
            _id: "",
          },
        ],
  };

  const handleTaskFunc = (value: any) => {
    setLoader(true);
    const docs = value?.idDocuments.filter((docItem: any) => {
      if (docItem?.document_link) {
        return docItem;
      }
    });

    const payload = {
      task_details: {
        guest_name: value?.guestName,
        disputed_amount: value?.disputedAmount,
        status: value?.status,
        disputed_date: value?.disputeDate,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
      },
      attached_files: docs,
    };
    const payloads = {
      task_id: item?._id,
      task_details: {
        guest_name: value?.guestName,
        comments: item?.commentsData,
        disputed_amount: value?.disputedAmount,
        status: value?.status,
        disputed_date: value?.disputeDate,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
      },
      attached_files: docs,
    };
    (item?._id
      ? dispatch(updateDispute(payloads))
      : dispatch(createDispute(payload))
    )
      .unwrap()
      .then((res) => {
        if (res?.success) {
          setRefetch(refetch + 1);
          nav("/qualityDispute");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        handleUpdate();
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  const handleClose = () => {
    setCancelPopup(true);
    // setRefetch(refetch + 1);
    // nav("/dashboard");
  };
  function handleClosePopup() {
    setCancelPopup(false);
    setRefetch(refetch + 1);
    item?._id ? setUdateDispute(false) : setAddDispute(false);
  }
  function handelCancel() {
    setCancelPopup(false);
  }
  function handleDelete() {
    setDeleteTask(true);
  }

  return (
    <>
      <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040]">
        <div className="backdrop-blur-[5px] w-full h-full fixed cursor-pointer"></div>
        <div
          className=" bg-[white] flex w-[50%] h-[90%] md:w-[100%] md:h-[100%] rounded-[15px] z-[100] py-6 px-4 flex-col  md:px-4 pb-5 "
          style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={AddDisputeSechma}
            onSubmit={(values, { resetForm }) => {
              handleTaskFunc(values);
              // resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }: any) => {
              return (
                <Form
                  onSubmit={handleSubmit}
                  className="h-full flex flex-col justify-between"
                >
                  <div className="flex overflow-auto  px-10 flex-col md:px-6  ">
                    <div className="w-full h-full">
                      <div className="w-full flex justify-between py-4">
                        <div className="flex gap-3">
                          {item?._id &&
                            tabs.map((tab: any, index: any) => (
                              <button
                                type="button"
                                key={tab}
                                onClick={() => setActiveTab(index)}
                                className={`
                               text-lg font-medium 
                               py-2 
                               transition-all duration-200 
                               focus:outline-none
                               ${
                                 activeTab === index
                                   ? "border-b-2 border-[#3D5765] text-[#3D5765]"
                                   : "text-gray-200"
                               }
                               hover:text-[#3D5765]
                             `}
                              >
                                {tab}
                              </button>
                            ))}
                        </div>
                        <div className="flex gap-8">
                          {item?._id && AdminUser?.userType === "admin" && (
                            <span
                              className="font-medium "
                              onClick={handleDelete}
                            >
                              <MdDelete size={25} color={"#D65252"} className="cursor-pointer"/>
                            </span>
                          )}
                          <RxCross2
                            className="cursor-pointer"
                            size={20}
                            onClick={() => handleUpdate()}
                          />
                        </div>
                      </div>
                      <div className="w-full flex gap-20 justify-between py-4 md:flex-col md:overflow-auto">
                        <div className="w-[50%] md:w-full">
                          <div className="font-medium pb-2">Guest Name</div>
                          <div className="  border-[#A0A0A0] rounded-[10px] ">
                            <InputField
                              className="w-full  rounded-lg border border-[#DADADA] italic"
                              fieldClass="w-full h-[40px] pl-6 pr-6 outline-none italic text-[18px]"
                              placeholderStyle={{ top: "8px", left: "18px" }}
                              type="text"
                              name="guestName"
                              placeholder="Enter Guest Name"
                              value={values?.guestName}
                              touched={touched}
                              handleChange={handleChange}
                              // fieldDisable={edit}
                              handleBlur={handleBlur}
                              errors={
                                errors.guestName ? errors.guestName : undefined
                              }
                            />
                          </div>

                          <div className="font-medium pb-2">
                            Disputed Amount
                          </div>
                          <div className="  border-[#A0A0A0] rounded-[10px] ">
                            <InputField
                              className="w-full  rounded-lg border border-[#DADADA] italic"
                              fieldClass="w-full h-[40px] pl-6 pr-6 outline-none italic text-[18px]"
                              placeholderStyle={{ top: "8px", left: "18px" }}
                              type="number"
                              name="disputedAmount"
                              placeholder="Enter Amount"
                              value={values?.disputedAmount}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              // fieldDisable={edit}
                              errors={
                                errors.disputedAmount
                                  ? errors.disputedAmount
                                  : undefined
                              }
                            />
                          </div>

                          <div className="w-full py-6  flex flex-col gap-3">
                            <div className="w-full flex gap-2 items-center relative">
                              <span>
                                <img src={PdfIcon} />
                              </span>
                              <span className=" font-semibold ">
                                Attached Files
                              </span>

                              <div className=" flex relative font-medium text-[#0094FF] text-[14px] items-center gap-1">
                                Upload from here
                                {/* <LuUpload size={15} color="#0094FF" /> */}
                              </div>
                            </div>
                          </div>
                          <div className="w-full py-1">
                            <FieldArray
                              name="idDocuments"
                              render={(arrayHelpers) => {
                                return (
                                  <>
                                    {values?.idDocuments?.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={index}
                                          className=" flex flex-col"
                                        >
                                          <td className="relative ">
                                            <UploadFile
                                              className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                              fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                              placeholderStyle={{
                                                top: "10px",
                                                left: "16px",
                                              }}
                                              name={`idDocuments.${index}.document_name`}
                                              location={`idDocuments.${index}`}
                                              arrayName="idDocuments"
                                              placeholder={item?.document_type}
                                              value={item}
                                              fileFrom={"case"}
                                              setFieldValue={setFieldValue}
                                              onBlur={handleBlur}
                                              touched={touched}
                                              // fieldDisable={edit}
                                              errors={
                                                errors?.idDocuments &&
                                                errors?.idDocuments[index]
                                                  ?.document_name
                                              }
                                            />
                                            {index >
                                              initialValues?.idDocuments
                                                ?.length -
                                                1 &&
                                              !item?.document_name && (
                                                <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )}
                                          </td>
                                        </div>
                                      )
                                    )}
                                    <div
                                      className="flex flex-col"
                                      onClick={() =>
                                        !edit &&
                                        arrayHelpers.insert(
                                          values?.idDocuments?.length,
                                          {
                                            document_name: "",
                                            document_link: "",
                                            document_type: "Attach Documents",
                                            document_ext: "",
                                            document_field: "idDocuments",
                                          }
                                        )
                                      }
                                    >
                                      <td>
                                        <UploadFile
                                          className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                          fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                          placeholderStyle={{
                                            top: "10px",
                                            left: "16px",
                                          }}
                                          name="add_document"
                                          arrayName="idDocuments"
                                          data={values?.idDocuments}
                                          fileFrom={"case"}
                                          placeholder="+ Attach More Documents"
                                          setFieldValue={setFieldValue}
                                          onBlur={handleBlur}
                                          // fieldDisable={edit}
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </td>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </div>

                          {item?._id && (
                            <React.Fragment>
                              <>
                                <div className="pt-3 ">
                                  <p className="flex items-center gap-2">
                                    <FaCircle color="#0496FF" size={10} />
                                    <span className="text-[#8591A2] text-[14px]">
                                      Last Activity: {item?.status} by{" "}
                                      {AdminUser?.name?.toUpperCase()}
                                    </span>
                                  </p>
                                </div>
                                <div className="border border-[#EBEBEB] my-3"></div>
                              </>

                              <CommentSection
                                length={0}
                                values={values.comment}
                                errors={errors}
                                handleChange={handleChange}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                data={item}
                                tabs={tabs}
                                activeTab={activeTab}
                                comment={comment}
                                setComment={setComment}
                                refetch={refetch}
                                setRefetch={setRefetch}
                              />
                            </React.Fragment>
                          )}
                        </div>

                        <div className="w-[50%] md:w-full">
                          <span className=" text-[14px]  font-medium  py-2 ">
                            Dispute Details
                          </span>
                          <div className="w-full flex border  p-4   border-[#A0A0A0] rounded-[10px] ">
                            <table className="w-full  ">
                              <tbody className="">
                                <tr className="border-b border-[#DADADA]">
                                  <td className="py-4  text-[#7A7A7A] w-[40%]">
                                    Disputed Date:
                                  </td>
                                  <td className="text-[14px] font-medium w-[60%]">
                                    <DateField
                                      className="w-full"
                                      fieldClass="w-full h-[40px] pl-6 outline-none uppercase pr-3"
                                      placeholderStyle={{
                                        top: "8px",
                                        left: "16px",
                                      }}
                                      type="date"
                                      name="disputeDate"
                                      placeholder="Enter dispute Date"
                                      value={values?.disputeDate}
                                      touched={touched}
                                      handleChange={handleChange}
                                      // fieldDisable={edit}
                                      handleBlur={handleBlur}
                                      errors={
                                        errors.disputeDate
                                          ? errors.disputeDate
                                          : undefined
                                      }
                                    />
                                  </td>
                                </tr>

                                <tr className="">
                                  <td className=" py-4 text-[#7A7A7A]">
                                    Status:
                                  </td>
                                  <td className="text-[14px] font-medium w-full">
                                    <SelectField
                                      className="flex gap-5 items-center w-full"
                                      fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-6 pr-4"
                                      name="status"
                                      // fieldDisable={edit}
                                      options={disputeStatus}
                                      value={values?.status}
                                      dataType="string"
                                      errors={errors?.status}
                                      touched={touched}
                                      setFieldValue={setFieldValue}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-full flex justify-end gap-4 mt-3 py-2">
                    <button
                      onClick={() => handleClose()}
                      type="button"
                      className=" w-[150px] border border-[#A0A0A0] py-2 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>

                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[150px]  py-2 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      {item?._id ? "Update" : "Add"}
                    </button>

                    {cancelPopup === true && (
                      <>
                        <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
                        <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
                          <div
                            className="py-4 bg-white  w-[300px] max-h-[80vh]  rounded-br-[20px] rounded-[20px] px-4  "
                            style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                          >
                            <div className="flex flex-col justify-center items-centers gap-3">
                              <p className="text-[16px] text-[#000000] font-semibold px-4">
                                Cancel Changes?
                              </p>
                              <p className="text-[14px] text-[#000000] font-medium px-4 py-4">
                                You are about to cancel all changes made to this
                                item. If you proceed, your unsaved edits will be
                                lost, and the item will revert to its previous
                                state.
                              </p>

                              <div className="w-full flex justify-end gap-4 ">
                                <button
                                  onClick={handelCancel}
                                  className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                                  style={{
                                    boxShadow: "0px 4px 10px 0px #BAC9DB",
                                  }}
                                >
                                  cancel
                                </button>
                                <button
                                  onClick={handleClosePopup}
                                  className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                                  style={{
                                    boxShadow: "0px 4px 10px 0px #BAC9DB",
                                  }}
                                >
                                  Okay
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}

      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={item}
          setRefetch={setRefetch}
          refetch={refetch}
          disputeId={item?._id}
        />
      )}
    </>
  );
};

export default AddNewDispute;
