import React, { useEffect, useState } from "react";
import SubHeader from "../Components/Header Component/SubHeader";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import TodoTask from "../Components/TaskComponents/TodoTask";
import InprogressTask from "../Components/TaskComponents/InprogressTask";
import CompletedTask from "../Components/TaskComponents/CompletedTask";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";
import Loader from "../assets/image/svg/Loader";
import { getAllTasks } from "../Slices/TaskSlice";
import { useAppDispatch } from "../Redux/Hooks";
import { toast } from "react-toastify";

interface PropsType {
  todo: any;
  inprogress: any;
  completed: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  refetch: any;
  setRefetch: any;
  loader: any;
  add: any;
  openComment: any;
  setOpenComment: any;
 
  setTaskData?: any;
  userDetails?: any;
  myTask?: any;
  setMyTask?: any;
  setDueDate?: any;
  dueDate?: any;
}

const AllTaskScreen: React.FC<PropsType> = ({
  todo,
  inprogress,
  completed,
  windowWidth,
  showScreen,
  setShowScreen,
  refetch,
  setRefetch,
  loader,
  add,
  openComment,
  setOpenComment,
  userDetails,
  setTaskData,
  myTask,
  setMyTask,
  setDueDate,
  dueDate,
}) => {
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [size, setSize] = useState<any>("medium");
  const [taskDetail, setTaskDetail] = useState<any>({});

  const dispatch = useAppDispatch();
  const HandleGetTaskFunc = () => {
    // this api for all task
    const payload = {
      status: "all",
      // search: search,
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : "",
      due_date: dueDate,
    };
    dispatch(getAllTasks(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setTaskData(res?.taskData);
        } else {
          setTaskData([]);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setTaskData([]);
        toast.error(error?.msg);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMyTask(e.target.value);
  };

  useEffect(() => {
    HandleGetTaskFunc();
  }, [myTask, dueDate]);

  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllTask // this responsive component and its important
          button={"Add Task"}
          todo={todo}
          size={size}
          myTask={myTask}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          viewTask={viewTask}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Tasks"}
        />
      ) : (
        <div className=" w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="All Tasks"
              button="Add Task"
              setHandleOpen={setHandleOpen}
            />

            <div className="w-full items-center gap-3 flex justify-end mt-3 pr-5 ">
            <div className="pr-5">
                <label className="mr-2 text-[15px]">Filter Days:</label>
                <select
                  id="dueDate"
                  value={dueDate}
                  onChange={(e) => setDueDate(e.target.value)}
                  className="px-2  text-[12px] py-1 border border-none rounded"
                >
                  <option value={"7 days"}>7 days</option>
                  <option value={"15 days"}>15 days</option>
                  <option value={"21 days"}> 21 days</option>
                  <option value={"30 days"}> 30 days</option>
                  <option value={"60 days"}>60 days</option>
                  <option value={"all days"}>All </option>
                </select>
              </div>
              <div className="pr-5">
                <label className="mr-2 text-[15px]">Filter Task:</label>
                <select
                  id="size"
                  value={myTask}
                  onChange={(e) => handleChange(e)}
                  className="px-2  text-[12px] py-1 border border-none rounded"
                >
                  <option value={"true"}>My Task</option>
                  <option value={"false"}>All Task</option>
                  {userDetails?.map((item: any, index: number) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div
              className=" w-full  flex gap-2  py-4  justify-between  "
              style={{ height: "calc(100% - 48px)" }}
            >
              <TodoTask // this component is todo task
                todo={todo}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                refetch={refetch}
                setRefetch={setRefetch}
                add={add}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <InprogressTask // this component is inprogress task
                inprogress={inprogress}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                add={add}
                setRefetch={setRefetch}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <CompletedTask // this component is completed task
                completed={completed}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                add={add}
                setRefetch={setRefetch}
              />
            </div>
          </div>
          {loader && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="bg-[#00000047] backdrop-blur-sm z-[99] w-full h-full absolute top-0 left-0" />
              <div className="relative z-[999]">
                <Loader />
              </div>
            </div>
          )}

          {viewTask && (
            <ViewSingleTask // this component is used to view details of task
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AllTaskScreen;
