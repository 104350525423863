import { useParams } from "react-router-dom";
import NewHeader from "../Components/Header Component/NewHeader";
import AddLeadTask from "../Components/TaskComponents/AddLeadTask";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../Redux/Hooks";
import { getSingleLead } from "../Slices/TaskSlice";
import toast from "react-hot-toast";

const AddLeadScreen = ({ refetch, setRefetch }: any) => {
  const { leadId } = useParams();
  console.log(leadId);

  const [singleleadData, setSingleLeadData] = useState<any | null>(null);
  const dispatch = useAppDispatch();
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const getSingleLeadData = async () => {
    setLoader(true);
    const payload = {
      task_id: leadId,
    };
    await dispatch(getSingleLead(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setSingleLeadData(res?.taskData?.[0]);
          setSelectedOption(res?.taskData?.[0]?.leadlogsData[0]?.contact_method)
          toast("Successful ✅", {
            position: "top-right",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  useEffect(() => {
    leadId && getSingleLeadData();
  }, [leadId]);

  return (
    <div className="h-full w-full flex  ">
      <div className="h-full w-full flex flex-col gap-2 py-4 px-10 md:px-6">
        <NewHeader heading={leadId ? "Update Lead" : "Add Lead Task"} />
        <AddLeadTask
          refetch={refetch}
          setRefetch={setRefetch}
          leadId={leadId}
          singleleadData={singleleadData}
          selectedOption={selectedOption}
          loader={loader}
          setLoader={setLoader}
          setSelectedOption={setSelectedOption}
        />
      </div>
    </div>
  );
};

export default AddLeadScreen;
