import React, { useState } from "react";
import CrossSVG from "../../assets/image/svg/CrossSVG";
import Loader from "../../assets/image/svg/Loader";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../Redux/Hooks";
import { GenerateOTP, VerifyOTPUser } from "../../Slices/UserSlice";
import toast from "react-hot-toast";

const VerifyPassword = ({ setVerify, setUpdate, email,setEmail }: any) => {
  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleOtp = () => {
    const payload = {
      email: email,
      providedOTP: otp,
    };
    dispatch(VerifyOTPUser(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setUpdate(true);
          setLoader(false);
          setVerify(false);
        }
      })
      .catch((error) => {
        setLoader(false);

        toast.error("please enter verification code");
      });
  };

  const HandleGenerateOTP = () => {
    // setEmail(email);
    setLoader(true);
    const payload = {
      email: email,
    };
    dispatch(GenerateOTP(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          
          setLoader(false);
          setVerify(true);
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("otp not generated");
      });
  };
  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040] opacity-100">
      <div className="backdrop-blur-[4px]  w-full h-full fixed cursor-pointer"></div>
      <div
        className="bg-white h-auto w-[30%] md:w-[90%] z-20 rounded-[20px] 
   px-[35px] pt-[30px] pb-[50px] relative"
      >
        <div className="flex justify-between  items-center pb-4 ">
          <span className="font-semibold">Forgot Password</span>
          <div
            onClick={() => setVerify(false)}
            className="flex items-center justify-center w-4  h-4  cursor-pointer"
          >
            <CrossSVG />
          </div>
        </div>
        <div className="w-full mb-6 h-auto flex flex-col justify-center items-center">
          <span className=" font-medium text-[16px] py-2 pb-6">
            Code send to  {email}
          </span>

          <span className="font-semibold text-[14px] ">
            Enter Verification Code
          </span>
        </div>
        <div className="w-full mt-2 ">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderInput={(props) => (
              <input
                {...props}
                type="number"
                style={{
                  width: "50px",
                  fontSize: "24px",
                  // boxShadow: "0px 5px 15px 4px rgba(205, 218, 243, 1)",
                  border: isOtpValid
                    ? "1px solid #C7C7C7"
                    : "1px solid #FF5050",
                }}
              />
            )}
            containerStyle="w-[100%] flex gap-3 justify-center"
            inputStyle="h-[50px] border border-gray-300 rounded-md text-3xl text-center focus:outline-none focus:border-indigo-500"
          />
          
          <div className="flex justify-center mb-8 mt-5">
            <span
              className="text-[#A0A0A0] text-[14px] font-normal cursor-pointer"
         
            >
              Didn't received a code? <span className="text-[#0070D8] cursor-pointer" onClick={HandleGenerateOTP}>Resend</span>
            </span>
          </div>

          <div className="flex justify-center items-center my-4">
            <button
              className="w-[100%] h-[40px] flex justify-center items-center font-semibold text-[14px] rounded-[10px] bg-primary text-[white]"
              type="submit"
              disabled={loading}
              style={{ boxShadow: "0px 5px 15px 4px rgba(205, 218, 243, 1)" }}
              onClick={handleOtp}
            >
              {loading ? <Loader /> : "VERIFY EMAIL"}
            </button>
          </div>
        </div>
        {/* <div className="flex justify-center mt-6">
          <span className="font-medium text-[16px]">
        
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default VerifyPassword;
