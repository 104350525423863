import React, { useRef, useState } from "react";
import { useOutSideCheck } from "../customize state/useOutSideCheck";
import { useLocation, useNavigate } from "react-router-dom";

interface propsType {
  item: ArrayDataType;
}

interface SubmenuType {
  name: string;
  url: string;
}

interface ArrayDataType {
  name: string;
  url: string;
  submenu?: SubmenuType[];
}

const MenuItems: React.FC<propsType> = ({ item }) => {
  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);
  const nav = useNavigate();
  const {pathname} = useLocation()
  useOutSideCheck(menuRef, setMenu, menu);

  return (
    <div className="relative" ref={menuRef}>
      <span
        onClick={() => {
          item.submenu ? setMenu(true) : nav(item.url);
        }}
        className={`text-[#3D5765] font-medium cursor-pointer ${(pathname.includes(item?.url) || item.url==="/dashboard" && pathname==="/") && "border-b"}`}
      >
        {item?.name}
      </span>

      {menu === true && item.submenu && (
        <div
          style={{ width: "max-content" }}
          className="absolute  top-full mt-2 left-0 bg-white shadow-lg rounded-md"
        >
          {item.submenu.map((subItem: any, subIndex: number) => (
            <div
              key={subIndex}
              onClick={() => nav(subItem.url)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {subItem.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItems;
