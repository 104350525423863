import { useState } from "react";
import SearchBar from "../Header Component/SearchBar";
import RespoSideBar from "./RespoSidebar";
import RespoSelectTask from "./RespoSelectTask";

import NewDispute from "../QualityDisputesComponents/NewDispute";
import Contesting from "../QualityDisputesComponents/Contesting";
import WonPartial from "../QualityDisputesComponents/WonPartial";
import WonClosed from "../QualityDisputesComponents/WonClosed";
import LostClosed from "../QualityDisputesComponents/LostClosed";

interface PropsType {
  refetch: any;
  showScreen: any;
  setShowScreen: any;
  setRefetch: any;
  openComment: any;
  setOpenComment: any;
  button: string;
  setTaskDetail: any;
  taskDetail: any;
  myTask: any;
  name: any;
  addDispute: any;
  setAddDispute?: any;
  addedDispute: any;
  disputeData: any;
}

const RespoAllDispute: React.FC<PropsType> = ({
  showScreen,
  setShowScreen,
  setRefetch,
  refetch,
  openComment,
  setOpenComment,
  button,
  myTask,
  name,
  addDispute,
  setTaskDetail,
  setAddDispute,
  addedDispute,
  disputeData,
}) => {
  console.log(disputeData, "disputeddata")
  const [showTask, setShowTask] = useState<string>("");

  const detail = [
    {
      name: "new dispute",
      heading: "New dispute Created",
      desc: "The task is completed by the team.",
    },
    {
      name: "contesting",
      heading: "dispute is contesting",
      desc: "The dispute is contesting  by the team.",
    },
    {
      name: "won partial",
      heading: "dispute won partial",
      desc: "The dispute is won partial by the team.",
    },
    {
      name: "won closed",
      heading: "Dispute won closed",
      desc: "The dispute is won closed by the team.",
    },
    {
      name: "lost closed",
      heading: "dispute lost closed",
      desc: "The lead is lost closed by the team.",
    },
  ];

  return (
    <>
      <div className="w-[100%] h-[100%] px-[20px] py-[10px] ">
        <SearchBar />
        <div
          className="w-full flex  flex-col "
          style={{ height: "calc(100% - 110px)" }}
        >
          {showTask ? (
            showTask === "new dispute" ? (
              <NewDispute // this component is New lead
                openComment={openComment}
                addDispute={addDispute}
                setAddDispute={setAddDispute}
                addedDispute={addedDispute}
                setOpenComment={setOpenComment}
                disputeData={disputeData?.new}
                myTask={myTask}
                setRefetch={setRefetch}
                refetch={refetch}  />
            ) : showTask === "contesting" ? (
              <Contesting // this component is Following up lead
                  disputeData={disputeData?.contesting}
                  setTaskDetail={setTaskDetail}
                  openComment={openComment}
                  addDispute={addDispute}
                  setOpenComment={setOpenComment}
                  myTask={myTask}
                  setRefetch={setRefetch}
                  refetch={refetch}           />
            ) : showTask === "won partial" ? (
              <WonPartial // this component is complete reward
                disputeData={disputeData?.wonpartial}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setTaskDetail={setTaskDetail}
                myTask={myTask}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            ) : showTask === "won closed" ? (
              <WonClosed // this component is completed lead
                disputeData={disputeData?.wonclosed}
                setTaskDetail={setTaskDetail}
                myTask={myTask}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            ) : (
              <LostClosed // this component is completed lead
                disputeData={disputeData?.lostclosed}
                setTaskDetail={setTaskDetail}
                myTask={myTask}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )
          ) : (
            <RespoSelectTask
              button={button}
              setShowTask={setShowTask}
              name={name}
              detail={detail}
              setAddDispute={setAddDispute}
            />
          )}
        </div>
      </div>

      {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
       
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RespoAllDispute;
