import CrossSVG from "../../assets/image/svg/CrossSVG";
import { Form, Formik } from "formik";
import InputField from "../Field Components/InputField";
import { forgetPasswordSchema } from "../../Validations/FormikValidation";
import { useState } from "react";
import VerifyPassword from "./VerifyPassword";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { GenerateOTP } from "../../Slices/UserSlice";
import toast from "react-hot-toast";
import Loader from "../../assets/image/svg/Loader";

const ForgetPassword = ({setVerify,setForget,email,setEmail}:any) => {
  const dispatch=useAppDispatch();
  const [loader,setLoader]=useState(false);

  const HandleGenerateOTP = (email: string) => {
    setEmail(email);
    setLoader(true);
    const payload = {
      email: email,
    };
    dispatch(GenerateOTP(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          
          setLoader(false);
          setVerify(true);
          setForget(false)
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error("Verify email is invalid");
      });
  };

  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040] opacity-100">
      <div className="backdrop-blur-[4px]  w-full h-full fixed cursor-pointer"></div>
      <div
        className="bg-white h-auto w-[30%] md:w-[90%] z-20 rounded-[20px] 
      px-[35px] pt-[30px] pb-[50px] relative"
      >
        <div className="flex justify-end  items-center  ">
          <div
            onClick={() => setForget(false)}
            className="flex items-center justify-center w-6  h-6  cursor-pointer"
          >
            <CrossSVG />
          </div>
        </div>
        <div className="w-full mb-6 h-auto flex flex-col justify-center items-center">
          <span className="text-[#3D5765] font-bold text-[20px] py-2">
            Task
          </span>
          <span className="font-semibold pb-1">Welcome back!</span>
          <span className="text-[#8B8D97] text-[11px] pb-6">
            Forgot your Password
          </span>
        </div>

        <Formik
          initialValues={{ email: email }}
          enableReinitialize
          validationSchema={forgetPasswordSchema}
          onSubmit={(values) => {
            HandleGenerateOTP(values.email );
           
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="flex w-full justify-center  ">
              <Form onSubmit={handleSubmit} className=" flex flex-col  w-full">
                <InputField
                  className="w-full bg-white rounded-lg border border-[#DADADA]"
                  fieldClass="w-full h-[40px] pl-6 pr-6 outline-none"
                  placeholderStyle={{ top: "8px", left: "18px" }}
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={values?.email}
                  touched={touched}
                  handleChange={handleChange}
                  fieldDisable={true}
                  handleBlur={handleBlur}
                  errors={errors.email ? errors.email : undefined}
                />

                <div className="flex justify-center items-center gap-4 mt-6 ">
                  <button 
                  // onClick={()=>{
                  //   setVerify(true);
                  //   setForget(false)
                  // }}
                    type="submit"
                    className="w-1/2 rounded-[10px] py-2 bg-[#3D5765] font-normal text-white"
                  >
                   {loader ? <div className="flex justify-center items-center">
                    <Loader/>
                   </div>  :  "Send Code" }
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    
    </div>
  );
};

export default ForgetPassword;
