import { useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import SubHeader from "../Components/Header Component/SubHeader";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";

import Contesting from "../Components/QualityDisputesComponents/Contesting";
import WonClosed from "../Components/QualityDisputesComponents/WonClosed";
import NewDispute from "../Components/QualityDisputesComponents/NewDispute";
import WonPartial from "../Components/QualityDisputesComponents/WonPartial";
import LostClosed from "../Components/QualityDisputesComponents/LostClosed";
import AddNewDispute from "../Components/QualityDisputesComponents/AddNewDispute";
import RespoAllDispute from "../Components/ResponsiveComponents/RespoAllDispute";

type cartSize = "small" | "medium" | "large";

interface PropsType {
  addDispute: any;
  openComment: any;
  setOpenComment: any;
  setDisputeData: any;
  disputeData: any;
  refetch: any;
  setRefetch: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
}

const QualityDisputeScreen: React.FC<PropsType> = ({
  addDispute,
  disputeData,
  setRefetch,
  refetch,
  openComment,
  setOpenComment,
  windowWidth,
  showScreen,
  setShowScreen,
}) => {
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<cartSize>("medium");
  const [myTask, setMyTask] = useState<any>("false");
  const [addedDispute, setAddDispute] = useState(false);

  function openAddDispute() {
    setAddDispute(true);
  }

  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllDispute // this responsive component and its important
          button={"Add Dispute"}
          openComment={openComment}
          addDispute={addDispute}
          setAddDispute={setAddDispute}
          addedDispute={addedDispute}
          setOpenComment={setOpenComment}
          disputeData={disputeData}
          myTask={myTask}
          setRefetch={setRefetch}
          refetch={refetch}
          setTaskDetail={setTaskDetail}
          taskDetail={taskDetail}
          name={"All Disputes"}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
        />
      ) : (
        <div className="w-[100%] h-[100%] flex  px-5 relative">
          <div className="w-full h-full  ">
            <SubHeader
              heading="Add New Disputes"
              button="Add New Disputes"
              setHandleOpen={() => openAddDispute()}
            />

            <div
              className="w-[100%]  flex gap-2  py-4  justify-between overflow-scroll overflow-x-auto"
              style={{ height: "calc(100% - 48px)" }}
            >
              <NewDispute
                openComment={openComment}
                addDispute={addDispute}
                setAddDispute={setAddDispute}
                addedDispute={addedDispute}
                setOpenComment={setOpenComment}
                disputeData={disputeData?.new}
                size={size}
                myTask={myTask}
                setSize={setSize}
                setRefetch={setRefetch}
                refetch={refetch}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <Contesting
                disputeData={disputeData?.contesting}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                size={size}
                myTask={myTask}
                setSize={setSize}
                setRefetch={setRefetch}
                refetch={refetch}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <WonPartial
                disputeData={disputeData?.wonpartial}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
                setRefetch={setRefetch}
                refetch={refetch}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <WonClosed
                disputeData={disputeData?.wonclosed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
                refetch={refetch}
              />
              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <LostClosed
                disputeData={disputeData?.lostclosed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setSize={setSize}
                openComment={openComment}
                addDispute={addDispute}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            </div>
          </div>

          {viewTask && (
            <ViewSingleTask
            setViewTask={setViewTask}
            taskDetail={taskDetail}
            setRefetch={setRefetch}
            refetch={refetch}
            />
          )}
        </div>
      )}
      {addedDispute && (
        <AddNewDispute
          setAddDispute={setAddDispute}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default QualityDisputeScreen;
