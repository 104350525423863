import React, { useEffect, useRef, useState } from "react";

import { IoIosArrowDown } from "react-icons/io";
import { useOutSideCheck } from "../customize state/useOutSideCheck";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";
import MediumStatusSvg from "../../assets/image/svg/MediumStatusSvg";
import LowStatusSvg from "../../assets/image/svg/LowStatusSvg";
import FormikSelectPopup from "../Modal Component/FormikSelectPopup";

interface FieldPropsType {
  className?: string;
  fieldClass?: string;
  fieldDisable?: boolean;
  name: string;
  value?: any;
  options: string[] | optionType[];
  dataType?: string;
  errors?: any;
  setFieldValue?: (name: string, value: string) => void;
  setCompanyScale?: React.Dispatch<React.SetStateAction<string>>;
  touched?: any;
}
interface optionType {
  name: string;
  value: string;
}

const SelectField: React.FC<FieldPropsType> = ({
  className,
  fieldClass,
  name,
  value,
  options,
  dataType = "string",
  errors,
  touched,
  setFieldValue,
  setCompanyScale,
  fieldDisable = false,
}) => {
  const [selectValue, setSelectValue]: any = useState(value ? value : "");
  const [popupShow, setPopupShow] = useState(false);
  const wrapperRef = useRef(null);
  useOutSideCheck(wrapperRef, setPopupShow, popupShow);

  useEffect(() => {
    if (value) {
      setSelectValue(value);
    }
  }, [value]);

  return (
    <div className={className + " relative  "}>
      <label htmlFor={name} className={"w-full relative"} ref={wrapperRef}>
        <div
          className={`${fieldClass} flex flex-row gap-1 items-center 
           ${name === "usertype" && "border border-[#DADADA]"} rounded-[10px] 
        ${errors && name === "usertype" ? "border-red-600 border-solid" : ""}`}
          style={
            name === "usertype" ? { boxShadow: "0px 5px 15px 0px #D4DEEA" } : {}
          }
          onClick={() => setPopupShow(!popupShow)}
        >
          <p
            className={
              "flex gap-2 items-center " + `${fieldDisable && "text-[#8a8a8a]"}`
            }
          >
            {selectValue === "High" ? (
              <HighStatusSvg />
            ) : selectValue === "Medium" ? (
              <MediumStatusSvg />
            ) : (
              selectValue === "Low" && <LowStatusSvg />
            )}
            {selectValue === "high" ? (
              <HighStatusSvg />
            ) : selectValue === "medium" ? (
              <MediumStatusSvg />
            ) : (
              selectValue === "low" && <LowStatusSvg />
            )}

            {selectValue === "todo" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#0496FF33] rounded-[7px] text-[#0496FF] font-semibold">
                {dataType === "string" ? "TO DO" : selectValue?.name}
              </span>
            ) : selectValue === "inprogress" && selectValue === "inprogress" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#FFF1D6] rounded-[7px] text-[#EEAB2E] font-semibold">
                {dataType === "string" ? "IN PROGRESS" : selectValue?.name}
              </span>
            ) : selectValue === "completed" ? (
              <span className="  text-[12px] text-center px-3 py-[3px] bg-[#3BB31133] rounded-[7px] text-[#3BB311] font-semibold">
                {dataType === "string" ? "COMPLETED" : selectValue?.name}
              </span>
            ) : (
              ""
            )}
            {dataType === "string" &&
            selectValue !== "todo" &&
            selectValue !== "inprogress" &&
            selectValue !== "completed"
              ? selectValue
              : selectValue?.name}
          </p>
          <IoIosArrowDown />
          {popupShow && !fieldDisable && (
            <FormikSelectPopup
              data={options}
              dataType={dataType}
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              setCompanyScale={setCompanyScale}
              setFieldValue={setFieldValue}
              name={name}
            />
          )}
        </div>
      </label>

      <div
        className={`text-[#FF5050] text-xs absolute capitalize   font-medium ${
          name === "usertype" ? "top-10 left-0" : "top-6 left-4"
        }     pt-1`}
      >
        {errors && touched && typeof errors === "string" && errors}
      </div>
    </div>
  );
};

export default SelectField;
