import React, { useEffect, useState } from "react";
import { Droppable } from "react-beautiful-dnd";

import ArrowSVG from "../../assets/image/svg/ArrowSVG";
import CartTask from "./CartTask";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";
import AddComment from "./AddComment";
import { useNavigate } from "react-router-dom";

interface PropsType {
  leadData: any;
  setViewTask?: any;
  setOpenComment: any;
  openComment: any;
  addLead: any;
  setTaskDetail: any;
  myTask: any;
  setRefetch: any;
  taskDetail: any;
}

const NotInterested: React.FC<PropsType> = ({
  leadData,
  addLead,
  setViewTask,
  openComment,
  setTaskDetail,
  setOpenComment,
  myTask,
  setRefetch,
  taskDetail,
}) => {
  const { user } = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const calcLength = () => {
    if (myTask === "true") {
      const tempArray = leadData?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return leadData?.length;
    }
  };

  return (
    <>
      <div className="mt-4 lg:hidden">
        <IoMdArrowBack size={25} onClick={() => navigate(-1)} />
      </div>
      <Droppable droppableId="NewLeadList">
        {(provided) => (
          <div
            className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="  h-full flex  justify-center flex-wrap overflow-auto">
              <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px] font-semibold text-center px-5 py-1 bg-[#D6525233] rounded-[7px] text-[#D65252] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    Not Intersted ({leadData?.length || 0})
                  </span>
                </div>
                <div className="flex flex-col  py-1 px-2">
                  <span className="font-medium text-[14px]">Not Intersted</span>
                  <span className="text-[#505050] text-[13px]">
                    The lead are create by team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto ">
                  {leadData?.length > 0 ? (
                    leadData?.map((item: any, index: number) => {
                      return (
                        <CartTask
                          key={index}
                          index={index}
                          item={item}
                          taskDetail={taskDetail}
                          setTaskDetail={setTaskDetail}
                          setViewTask={setViewTask}
                          openComment={openComment}
                          setOpenComment={setOpenComment}
                          icon={<HighStatusSvg />}
                          // type={type}
                          // size={size}
                        />
                      );
                    })
                  ) : (
                    <div className="h-full flex flex-col  justify-center">
                      <p>Task has not been created for this user</p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {openComment && (
        <div className="w-full h-[50%] relative">
          <AddComment
            openComment={openComment}
            setOpenComment={setOpenComment}
            addLead={addLead}
            setRefetch={setRefetch}
          />
        </div>
      )}
    </>
  );
};

export default NotInterested;
