import { useState } from "react";
import SearchBar from "../Header Component/SearchBar";
import RespoSideBar from "./RespoSidebar";
import RespoSelectTask from "./RespoSelectTask";
// import TodoTask from "../TaskComponents/TodoTask";
// import InprogressTask from "../TaskComponents/InprogressTask";
// import CompletedTask from "../TaskComponents/CompletedTask";

import TodoTask from "../maintananceComponent/TodoTask";
import InprogressTask from "../maintananceComponent/InprogressTask";
import CompletedTask from "../maintananceComponent/CompletedTask";

interface PropsType {
  todo: any;
  inprogress: any;
  completed: any;
  showScreen: any;
  setShowScreen: any;
  button: string;
  setTaskDetail: any;
  setViewTask: any;
  taskDetail: any;
  viewTask: any;
  //   setRefetch: any;
  refetch: any;
  setView?: any;
  name: any;
  size: any;
  myTask?: any;
}

const RespoAllMaint: React.FC<PropsType> = ({
  todo,
  inprogress,
  completed,
  showScreen,
  setShowScreen,
  button,
  setTaskDetail,
  setViewTask,
  taskDetail,
  viewTask,
  refetch,
  //   setRefetch,
  name,
  myTask,
  size,
}) => {
  const [showTask, setShowTask] = useState<string>("");
  const detail = [
    {
      name: "todo",

      heading: "Task Assigned",
      desc: "The tasks are assigned to team members.",
    },
    {
      name: "inprogress",
      heading: "Task Started",
      desc: "The team started working on the tasks.",
    },
    {
      name: "completed",
      heading: "Task Completed",
      desc: "The task is completed by the team.",
    },
  ];

  return (
    <>
      <div className="w-[100%] h-[100%] px-[20px] py-[10px] ">
        <SearchBar />
        <div
          className="w-full flex  flex-col "
          style={{ height: "calc(100% - 110px)" }}
        >
          {showTask ? (
            showTask === "todo" ? (
              <TodoTask
                myTask={myTask}
                todo={todo}
                size={size}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setShowTask={setShowTask}
                setRefetch={undefined}
              />
            ) : showTask === "inprogress" ? (
              <InprogressTask
                inprogress={inprogress}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                size={size}
                myTask={myTask}
                setShowTask={setShowTask}
                setRefetch={undefined}
              />
            ) : (
              <CompletedTask
                completed={completed}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setShowTask={setShowTask}
                setRefetch={undefined}
              />
            )
          ) : (
            <RespoSelectTask
              button={button}
              setShowTask={setShowTask}
              name={name}
              detail={detail}
            />
          )}
        </div>
      </div>

      {showScreen.length > 0 ? (
        <RespoSideBar
          setShowSlider={setShowScreen}
          showSlider={showScreen}
          type={"sidebar"}
          direction={"left"}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default RespoAllMaint;
