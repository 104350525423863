import moment from "moment";
// import toast from "react-hot-toast";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik, FormikHelpers, FormikValues } from "formik";
import { MdDelete, MdOutlineCloudUpload } from "react-icons/md";

import Loader from "../../assets/image/svg/Loader";
import TaskFieldTable from "../Tables/TaskFieldTable";
import DateField from "../Field Components/DateField";
import PdfIcon from "../../assets/image/pdf-icon.png";
import InputField from "../Field Components/InputField";
import UploadFile from "../Field Components/UploadFile";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";
import addAttemptsImg from "../../assets/image/addAttempt.png";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import CommentSection from "../maintananceComponent/CommentSection";
import {
  AddLeadAttemptsSchema,
  AddLeadTaskSchema,
} from "../../Validations/FormikValidation";
import {
  createLead,
  getAllAttempts,
  updateLead,
  uploadDocument,
} from "../../Slices/TaskSlice";
import SelectField from "../Field Components/SelectField";
import AddLeadAttempts from "../Lead Components/AddLeadAttempts";
import { GoDotFill } from "react-icons/go";

const AddLeadTask = ({
  refetch,
  setRefetch,
  leadId,
  singleleadData,
  loader,
  setLoader,
}: any) => {
  const [comment, setComment] = useState([]);
  const [deletetask, setDeleteTask] = useState(false);
  const [edit, setEdit] = useState(leadId ? true : false);

  const tabs: any = ["Comments", "Status", "Attachments"];
  const [activeTab, setActiveTab] = useState<number>(0);
  const [addAttempts, setAddAttempts] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [allAttempts, setAllAttempts] = useState([]);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];

  const nav = useNavigate();
  let commentsDate: any = [""];
  const dispatch = useAppDispatch();

  commentsDate =
    singleleadData?.commentsData.length &&
    singleleadData?.commentsData?.map((item: any, index: number) => {
      {
        return moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a");
      }
    });

  const initialValues = {
    title: singleleadData?.title || "",
    description: singleleadData?.description || "",
    leadName: singleleadData?.lead_name || "",
    comment: singleleadData?.commentsData || [],
    assignee: singleleadData?.assignUsersData || [],
    idDocuments: singleleadData?.attached_files?.length
      ? singleleadData?.attached_files
      : [
          // {
          //   createdAt: "",
          //   document_ext: "",
          //   document_field: "idDocuments",
          //   document_link: "",
          //   document_name: "",
          //   document_type: "Attach Documents",
          //   task_id: "",
          //   updatedAt: "",
          //   __v: 0,
          //   _id: "",
          // },
        ],
    owner: singleleadData?.organization_id || "",
    priority: singleleadData?.priority || "",
    status: singleleadData?.status || "",
    leadSource: singleleadData?.lead_source || " ",
    sourceName: singleleadData?.source_name || "",
    creationDate: "",
    lead: "",
    organization_id: singleleadData?.organization_id || "",
    hotel_id: singleleadData?.hotel_id || "",
    leadType: singleleadData?.lead_type || "",
    phoneNumber: singleleadData?.leadlogsData[0]?.contactData || "",
    email: singleleadData?.leadlogsData[0]?.contactData || "",
    text: singleleadData?.leadlogsData[0]?.contactData || "",
  };

  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );
    setLoader(true);
    const docs = value?.idDocuments.filter((docItem: any) => {
      if (docItem?.document_link) {
        return docItem;
      }
    });
    const payload = {
      task_details: {
        title: value?.title,
        lead_name: value?.leadName,
        date_contacted: value?.dateContacted,
        // contact_method: value?.contMethod,
        description: value?.description,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        status: value?.status,

        lead_source: value?.leadSource,
        source_name: value?.sourceName,
        // status: "new",
        // deadline: value?.deadline,
        assign_to: assignUser ? assignUser : [""],
        // task_type: value?.taskType,
        lead_type: value?.leadType,
        // lead: AdminUser?._id,
        contactData:
          value?.contMethod === "Phone"
            ? value?.phoneNumber
            : value?.contMethod === "Email"
            ? value?.email
            : value?.contMethod === "Text"
            ? value?.text
            : "",

        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
      },
      attached_files: docs,
    };

    const payloads = {
      task_id: singleleadData?._id,
      lead_logs_id: singleleadData?.leadlogsData[0]?._id,
      task_details: {
        title: value?.title,
        lead_name: value?.leadName,
        date_contacted: value?.dateContacted,
        contact_method: value?.contMethod,
        description: value?.description,
        owner: AdminUser?._id,
        priority: value?.priority.toLowerCase(),
        status: value?.status,
        assign_to: assignUser ? assignUser : [""],
        lead_type: value?.leadType,
        lead_source: value?.leadSource,
        source_name: value?.sourceName,
        organization_id: AdminUser?.organization_id,
        hotel_id: AdminUser?.hotel_id,
        contactData:
          value?.contMethod === "Phone"
            ? value?.phoneNumber
            : value?.contMethod === "Email"
            ? value?.email
            : value?.contMethod === "Text"
            ? value?.text
            : "",
      },
      attached_files: docs,
    };
    (leadId ? dispatch(updateLead(payloads)) : dispatch(createLead(payload)))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setLoader(true);

          setRefetch(refetch + 1);
          nav("/leadTask");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  };

  const handleClose = () => {
    setCancelPopup(true);
  };
  function handleClosePopup() {
    setCancelPopup(false);
    setRefetch(refetch + 1);
    nav("/leadTask");
  }
  function handelCancel() {
    setCancelPopup(false);
  }
  const handleDelete = () => {
    setDeleteTask(true);
  };

  function getAllAttempt() {
    const payload = {
      lead_id: leadId,
    };
    dispatch(getAllAttempts(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setAllAttempts(res?.AttemptData);
        }
        setLoader(false);
      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  }

  useEffect(() => {
    getAllAttempt();
  }, []);

  return (
    <>
      <div
        className="relative w-full h-[90%]  bg-white rounded-[15px] scrollable overflow-auto  "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={AddLeadTaskSchema}
          onSubmit={(values, { resetForm }) => {
            handleTaskFunc(values);

            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => {
            const handleChanged: any = (event: any) => {
              console.log(event, "upload files");
              const image = event.currentTarget.files[0];
              const formData = new FormData();
              formData.append("image", image);
              setLoader(true);
              if (image) {
                dispatch(uploadDocument(formData))
                  .unwrap()
                  .then((res) => {
                    if (res?.status) {
                      setFieldValue(
                        `idDocuments.${values?.idDocuments?.length}`,
                        {
                          document_name: image?.name?.trim(),
                          document_type: "Attach Documents",
                          document_link: res?.imageUrl,
                          document_ext: image?.type,
                          document_field: "idDocuments",
                        }
                      );

                      setLoader(false);
                    } else {
                      toast(res?.msg, {
                        position: "top-right",
                        autoClose: 5000,
                        theme: "dark",
                        type: "error",
                      });
                    }
                  })
                  .catch((error) => {
                    toast(error?.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      theme: "dark",
                      type: "error",
                    });
                  });
              }
            };

            return (
              <Form
                onSubmit={handleSubmit}
                className="h-full flex flex-col justify-between"
              >
                <div className=" flex  overflow-auto px-10 flex-col md:px-6  ">
                  <div className="w-full h-full">
                    <div className="w-full flex justify-end pt-2">
                      <div className="flex gap-10">
                        {leadId && AdminUser?.userType === "admin" && (
                          <span className="font-medium " onClick={handleDelete}>
                            <MdDelete
                              size={25}
                              color={"#D65252"}
                              className="cursor-pointer"
                            />
                          </span>
                        )}

                        <RxCross2
                          size={20}
                          onClick={() => nav("/leadTask")}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>

                    <div className="w-full flex py-2 md:flex-col">
                      <div className="w-3/5 md:w-full">
                        <div className="w-[85%] py-4 md:w-full">
                          <span className="font-semibold py-2">
                            Patient Name
                          </span>

                          <div className=" border-[#A0A0A0] rounded-[10px] my-2 ">
                            <InputField
                              className="w-full  rounded-lg border border-[#DADADA] italic"
                              fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                              placeholderStyle={{ top: "8px", left: "18px" }}
                              type="text"
                              name="title"
                              placeholder="Enter Title"
                              value={values?.title}
                              touched={touched}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errors={errors.title ? errors.title : undefined}
                            />
                          </div>
                          <div className="font-medium pb-2">Lead Name</div>
                          <InputField
                            className="w-full  rounded-lg border border-[#DADADA] italic"
                            fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                            placeholderStyle={{ top: "8px", left: "18px" }}
                            type="text"
                            name="leadName"
                            placeholder="Enter Lead Name"
                            value={values?.leadName}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={
                              errors.leadName ? errors.leadName : undefined
                            }
                          />
                          <div className="font-medium pb-2">Description</div>
                          <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                            <textarea
                              placeholder="Add a description..."
                              className="w-full min-h-[120px] outline-none"
                              name="description"
                              id="description"
                              value={values?.description}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="w-full py-2  flex flex-col gap-3">
                            <div className="w-full flex gap-2 items-center relative">
                              <span>
                                <img src={PdfIcon} />
                              </span>
                              <span className=" font-semibold ">
                                {" "}
                                Attached Files
                              </span>

                              <div className="relative ">
                                <p className="relative  flex font-medium text-[#0094FF] text-[14px] items-center justify-center gap-1">
                                  Upload from here{" "}
                                  <MdOutlineCloudUpload
                                    color="#0094FF"
                                    size={20}
                                    className="mt-1 mx-1 cursor-pointer"
                                  />
                                </p>
                                <input
                                  type="file"
                                  className="absolute  h-full w-full z-20 top-0 left-0 opacity-0"
                                  onChange={(event) => handleChanged(event)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="flex ">
                            <div className="gap-6 bg-[#E5E5E5] px-2 my-1 rounded-3xl">
                              {(edit ? tabs : ["Attachments"]).map(
                                (tab: any, index: any) => (
                                  <button
                                    type="button"
                                    key={tab}
                                    onClick={() => setActiveTab(index)}
                                    className={`
                               text-[14px] font-medium 
                               py-1 my-2
                               transition-all duration-200  mx-2
                               focus:outline-none rounded-3xl px-2
                               ${
                                 activeTab === index
                                   ? " bg-[#3D5765] text-[#FFFFFF]  transform scale-105"
                                   : "text-gray-200"
                               }
                             
                             `}
                                  >
                                    {tab}
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                          {edit && (
                            <React.Fragment>
                              {commentsDate && commentsDate?.length !== 0 ? (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add{" "}
                                        {singleleadData?.status} -{" "}
                                        {commentsDate[commentsDate?.length - 1]}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              ) : (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add{" "}
                                        {singleleadData?.status} -{" "}
                                        {moment(
                                          singleleadData?.createdAt
                                        ).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              )}

                              <CommentSection
                                length={0}
                                values={values.comment}
                                errors={errors}
                                handleChange={handleChange}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                data={singleleadData}
                                comment={comment}
                                tabs={tabs}
                                activeTab={activeTab}
                                setComment={setComment}
                                refetch={refetch}
                                setRefetch={setRefetch}
                              />
                            </React.Fragment>
                          )}

                          {(edit ? tabs[activeTab] : "Attachments") ===
                            "Attachments" && (
                            <div className="w-full py-1">
                              <FieldArray
                                name="idDocuments"
                                render={(arrayHelpers) => {
                                  return (
                                    <>
                                      {values?.idDocuments?.map(
                                        (item: any, index: number) => (
                                          <div
                                            key={index}
                                            className=" flex flex-col"
                                          >
                                            <td className="relative ">
                                              <UploadFile
                                                className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                                fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                                placeholderStyle={{
                                                  top: "10px",
                                                  left: "16px",
                                                }}
                                                name={`idDocuments.${index}.document_name`}
                                                location={`idDocuments.${index}`}
                                                arrayName="idDocuments"
                                                placeholder={
                                                  item?.document_type
                                                }
                                                value={item}
                                                fileFrom={"case"}
                                                setFieldValue={setFieldValue}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                index={index}
                                                errors={
                                                  errors?.idDocuments &&
                                                  errors?.idDocuments[index]
                                                    ?.document_name
                                                }
                                                arrayHelpers={arrayHelpers}
                                              />
                                              {index >
                                                initialValues?.idDocuments
                                                  ?.length -
                                                  1 &&
                                                !item?.document_name && (
                                                  <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index
                                                        );
                                                      }}
                                                    >
                                                      X
                                                    </button>
                                                  </div>
                                                )}
                                            </td>
                                          </div>
                                        )
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="w-[40%] md:w-full h-full px-4 flex flex-col py-4 md:px-0">
                        <TaskFieldTable
                          values={values}
                          edit={edit}
                          errors={errors}
                          handleChange={handleChange}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          adminUserArray={adminUserArray}
                        />
                        {edit && (
                          <div className="w-full pt-3">
                            <div className="flex justify-between py-3">
                              <p className="text-[16px] font-medium text-[#000000]">
                                Lead Contact Attempt
                              </p>

                              <button
                                type="button"
                                onClick={() => setAddAttempts(true)}
                                className="bg-[#3D5765] text-white py-1 px-3 rounded-xl"
                              >
                                Add Attempt
                              </button>
                            </div>

                            <div className="overflow-auto h-[150px]">
                              {allAttempts?.length > 0 ? (
                                allAttempts?.map(
                                  (attamptData: any, index: number) => (
                                    <div
                                      className="flex flex-col gap-3 justify-center items-center "
                                      key={index}
                                    >
                                      <div className="flex flex-row justify-center gap-2">
                                        <div>
                                        <GoDotFill color="#3D5765" />
                                        </div>
                                        <div>
                                          <p className=" text-[14px] font-medium text-[#3D5765]">
                                            Contact via -{" "}
                                            <span className="text-[14px] font-normal text-[#3D5765]">
                                              {attamptData?.contact_method}
                                            </span>{" "}
                                            <span>
                                              on{" "}
                                              {moment(
                                                attamptData?.date_contacted
                                              ).format("DD-MM-YYYY")}{" "}
                                            </span>
                                            by {AdminUser?.name}{" "}
                                          </p>
                                          <div className="flex flex-row gap-2 justify-start">
                                            <p className="text-[13px] font-normal text-[#3D5765] ">
                                              Note:
                                              <span className="text-[13px] font-light text-[#3D5765]">
                                                {attamptData?.description}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              ) : (
                                <div className="flex flex-col gap-3 justify-center items-center pt-5">
                                  <div>
                                    {" "}
                                    <img src={addAttemptsImg} />
                                  </div>
                                  <p className="text-[14px] font-medium text-[#8591A2]">
                                    You haven't made any attempts yet.
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 relative"
                  style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                >
                  <div className="w-full flex justify-end gap-4 py-2 px-6">
                    <button
                      onClick={handleClose}
                      type="button"
                      className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      {singleleadData?._id ? "Update" : " Add Lead Task"}
                    </button>
                  </div>

                  {cancelPopup === true && (
                    <React.Fragment>
                      <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
                      <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
                        <div
                          className="py-4 bg-white  w-[300px] max-h-[80vh] rounded-br-[20px] rounded-[20px] px-4  "
                          style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                        >
                          <div className="flex flex-col justify-center items-centers gap-3">
                            <p className="text-[16px] text-[#000000] font-semibold px-4">
                              Cancel Changes?
                            </p>
                            <p className="text-[14px] text-[#000000] font-medium px-4 py-4">
                              You are about to cancel all changes made to this
                              item. If you proceed, your unsaved edits will be
                              lost, and the item will revert to its previous
                              state.
                            </p>

                            <div className="w-full flex justify-end gap-4 ">
                              <button
                                onClick={handelCancel}
                                className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                cancel
                              </button>
                              <button
                                onClick={handleClosePopup}
                                className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                Okay
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>

        {addAttempts === true && (
          <AddLeadAttempts
            loader={loader}
            setLoader={setLoader}
            singleleadData={singleleadData}
            setAddAttempts={setAddAttempts}
          />
        )}
      </div>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}

      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={singleleadData}
          setRefetch={setRefetch}
          refetch={refetch}
          leadId={leadId}
        />
      )}
    </>
  );
};

export default AddLeadTask;
