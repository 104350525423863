import UnAuth from "./Navigation/UnAuth";
import { useAppSelector } from './Redux/Hooks';
import Auth from "./Navigation/Auth";
import { BrowserRouter } from "react-router-dom";

function App() {
  const userStatus = useAppSelector((state) => state.user);
 
  return userStatus?.isAuthenticated ? <BrowserRouter><Auth/></BrowserRouter> : <UnAuth/> 
}

export default App;
