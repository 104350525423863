import { BiSolidPencil } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import TaskDetailsTable from "../Tables/TaskDetailsTable";
import CommentSection from "../CommentSection";
import { useState } from "react";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DocumentDetails from "../DocumentDetails";
import CommentDetails from "../CommentDetails";

interface propsType {
  setViewTask: (a: boolean) => void;
  taskDetail: any;
  refetch: any;
  setRefetch: any;
}

const ViewSingleTask: React.FC<propsType> = ({
  setViewTask,
  taskDetail,
  refetch,
  setRefetch,
}) => {
  const nav = useNavigate();
  const [deletetask, setDeleteTask] = useState(false);

  const handleClick = () => {
    // const data = [taskDetail];
    nav(`/AddSureStayMaint/${taskDetail?._id}`);
    // nav("/edit", { state: data });
  };
 console.log(taskDetail, "task detaisl for maint")
  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040]">
      <div className="backdrop-blur-[5px] w-full h-full fixed cursor-pointer"></div>

      <div
        className=" bg-[white] flex w-[70%] h-[95%] md:w-[95%] md:h-[95%] rounded-[15px] z-[100] py-6 px-10 flex-col scrollable overflow-auto md:px-4 pb-10 "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className="w-full flex justify-between ">
          <span className="font-semibold">
            {taskDetail?.title?.toUpperCase()}
          </span>
          <div className="flex gap-10 md:gap-4 md:justify-end md:w-full">
            <div className="flex gap-4">
              <span className="cursor-pointer">
                <MdDelete
                  size={20}
                  color={"#D65252"}
                  onClick={() => setDeleteTask(true)}
                />
              </span>
              <span className="cursor-pointer" onClick={handleClick}>
                <BiSolidPencil size={20} color={"#3D5765"} />
              </span>
            </div>
            <span className="cursor-pointer">
              <RxCross2 onClick={() => setViewTask(false)} size={20} />
            </span>
          </div>
        </div>
        {/* <h1 className="italic text-[24px] py-2 font-medium">
          Creative Task for {moment(taskDetail?.createdAt).format("MMMM")}
        </h1> */}
        <div className="w-full h-full flex md:flex-col">
          <div className="w-[60%] h-full md:w-full">
            <div className="w-[85%] py-4 md:w-full">
              <span className="font-medium my-2">Description</span>
              <p className="py-4">
                {taskDetail?.description ? (
                  <div className="border py-2 rounded-[8px] border-[#CCCCCC] pl-2 h-auto w-full">
                    {taskDetail?.description}
                  </div>
                ) : (
                  <span className="font-semibold text-[#A0A0A0] italic text-[16px]">
                    NO DESCRIPTION ADD BY USER
                  </span>
                )}
              </p>

              <DocumentDetails value={taskDetail?.DocumentsData} />
              <CommentDetails value={taskDetail?.commentsData} />
            </div>
          </div>
          <div className="w-[50%] px-4 flex flex-col md:w-full md:px-0">
            {/* {taskDetail?.lead !== null ? (
              <LeadTaskDetailTable taskDetail={taskDetail} />
            ) : ( */}
              <TaskDetailsTable taskDetail={taskDetail} />
            {/* )} */}
            <div className="w-full  mt-10 mb-5 flex justify-end  ">
              <button
                type="button"
                onClick={() => setViewTask(false)}
                className="bg-[#3D5765] w-1/2 py-2 rounded-[10px] text-white font-semibold"
                style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
              >
                {" "}
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={taskDetail}
          setViewTask={setViewTask}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
    </div>
  );
};

export default ViewSingleTask;
