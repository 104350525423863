import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../Redux/Hooks";
import DateField from "../Field Components/DateField";
import SelectField from "../Field Components/SelectField";
import MultiSelectField from "../Modal Component/MultiSelectField";
import SelectFieldTwo from "../Field Components/SelectFieldTwo";
import InputField from "../Field Components/InputField";

interface propsType {
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  adminUserArray: any;
  edit?: any;
}
const TaskFieldTable: React.FC<propsType> = ({
  values,
  handleChange,
  setFieldValue,
  errors,
  touched,
  edit,
  adminUserArray,
}) => {
  const { pathname } = useLocation();
  const userData: any = useAppSelector((state) => state?.user?.userData);
  const organizationType: any = useAppSelector((state) => state?.user?.user);
  console.log(organizationType?.organizationData[0]?.name);
  const priority = ["High", "Medium", "Low"];
  const leadsType = ["Internal", "External"];
  // const TaskType = ["surestay_leads", "quality_leads"];
  const status = [
    "new",
    "following_up",
    "scheduled",
    "completed",
    "not_interested",
  ];

  const leadSource: any = [
    "Instagram",
    "tiktok",
    "facebook",
    "google",
    "phone",
    "patient",
    "referral",
  ];
  return (
    <>
      <span className=" text-[14px] font-medium  py-2 ">Lead Details</span>

      <div className="w-full flex border h-1/2 p-4  border-[#A0A0A0] rounded-[10px] ">
        <table className="w-full  ">
          <tbody className="">
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Lead Type:</td>
              <td className=" text-[14px] font-medium w-[70%]">
                <SelectFieldTwo
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                  name="leadType"
                  value={values.leadType}
                  errors={errors?.leadType}
                  touched={touched}
                  dataType="string"
                  options={leadsType}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>

            {/* <tr className="border-b border-[#DADADA] ">
              <td className="py-4 text-[#7A7A7A]">Lead:</td>
              <td className="text-[14px] font-medium">
                <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px]  pr-4 py-2"
                  name="lead"
                  value={values.lead}
                  dataType="object"
                  options={adminUserArray}
                  errors={errors?.lead}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr> */}

            <tr className="border-b border-[#DADADA] ">
              <td className=" py-4 text-[#7A7A7A]">Assignee:</td>
              <td className="text-[14px] font-medium ">
                <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px]  pr-4 py-2"
                  name="assignee"
                  value={values.assignee}
                  dataType="object"
                  options={userData}
                  errors={errors?.assignee}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Owner:</td>
              <td className="text-[14px] font-medium">
              <MultiSelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full min-h-[40px] pl-2 pr-4 py-2"
                  name="owner"
                  value={values.owner}
                  dataType="object"
                  options={adminUserArray}
                  errors={errors?.owner}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr> */}
            <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Priority:</td>
              <td className="text-[14px] font-medium w-[70%]">
                <SelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                  name="priority"
                  options={priority}
                  value={values.priority}
                  dataType="string"
                  errors={errors?.priority}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
            {organizationType?.organizationData[0]?.name === "Dental" && (
              <>
                <tr className="border-b border-[#DADADA]">
                  <td className=" py-4 text-[#7A7A7A]">Lead Source:</td>
                  <td className="text-[14px] font-medium w-[70%]">
                    <SelectField
                      className="flex gap-5 items-center w-full"
                      fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                      name="leadSource"
                      options={leadSource}
                      value={values.leadSource}
                      dataType="string"
                      errors={errors?.leadSource}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />
                  </td>
                </tr>
                {(values.leadSource === "patient" ||
                  values.leadSource === "referral") && (
                  <tr className="border-b border-[#DADADA]">
                    <td className=" py-4 text-[#7A7A7A]">Source Name:</td>
                    <td className="text-[14px] font-medium w-[70%]">
                      <InputField
                        className="w-full"
                        fieldClass="w-full h-[40px] pl-4 outline-none uppercase pr-3"
                        name="sourceName"
                        value={values.sourceName}
                        touched={touched}
                        handleChange={handleChange}
                        errors={
                          errors.sourceName ? errors.sourceName : undefined
                        }
                        placeholder={""}
                        placeholderStyle={{ top: "8px", left: "18px" }}
                        type={"text"}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}

            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Deadline:</td>
              <td className="text-[14px] font-medium ">
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-4 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="deadline"
                  placeholder=""
                  value={values?.deadline}
                  touched={touched}
                  errors={errors?.deadline}
                  handleChange={handleChange}
                />
              </td>
            </tr> */}
            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Task Type:</td>
              <td className="text-[14px] font-medium">
                <SelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-5 pr-4"
                  name="taskType"
                  fieldDisable={false}
                  options={TaskType}
                  value={values.taskType}
                  dataType="string"
                  errors={errors?.taskType}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr> */}

            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Creation Date:</td>
              <td className="text-[14px] font-medium ">
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="creationDate"
                  placeholder=""
                  value={values?.creationDate}
                  touched={touched}
                  errors={errors?.creationDate}
                  handleChange={handleChange}
                />
              </td>
            </tr> */}

            {/* <tr className="border-b border-[#DADADA]">
              <td className=" py-4 text-[#7A7A7A]">Creation Date:</td>
              <td className="text-[14px] font-medium">
                {" "}
                <DateField
                  className="w-full"
                  fieldClass="w-full h-[40px] pl-5 outline-none uppercase pr-3"
                  placeholderStyle={{ top: "8px", left: "16px" }}
                  type="date"
                  name="dateOfContact"
                  placeholder=""
                  fieldDisable={edit}
                  value={values?.dateOfContact}
                  touched={touched}
                  errors={errors?.dateOfContact}
                  handleChange={handleChange}
                />
              </td>
            </tr> */}

            <tr className="">
              <td className=" py-4 text-[#7A7A7A]">Status:</td>
              <td className="pl-4 text-[14px] font-medium">
                <SelectField
                  className="flex gap-5 items-center w-full"
                  fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                  name="status"
                  options={status}
                  value={values.status}
                  dataType="string"
                  errors={errors?.status}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TaskFieldTable;
