import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, setAuthToken, uploadInstance } from "../utils/AxiosInstance";
import { RootState } from "../Redux/Store";

interface userState {
  user: null | userType;
  isAuthenticated: boolean;
  accessToken: null | string;
  loading: boolean;
  error: string;
  userData: any;
}

interface userType {
  name: string;
  email: String;
  userType: String;
  userStatus: String;
  password: String;
  _id: String;
  organization_id: string;
  hotel_id: string;
  organizationData: [];
}

const initialState: userState = {
  user: null,
  isAuthenticated: false,
  accessToken: null,
  loading: false,
  error: "",
  userData: null,
};

export const loginAccount = createAsyncThunk(
  "loginAccount",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/login";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      //   return
      if (response?.data?.success === true) {
        setAuthToken(response?.data?.response?.token);
      }
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const SignUpAccount = createAsyncThunk(
  "SignUpAccount",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/signup";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const UpdateUser = createAsyncThunk(
  "UpdateUser",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllUser = createAsyncThunk(
  "getAllUser",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const userNotification = createAsyncThunk(
  "userNotification",
  async () => {
    try {
      const url = "user/get_notifications";
      const response = await instance.get(url);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  }
);
export const readNotification = createAsyncThunk(
  "readNotification",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/read_notification";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "deleteUserAccount",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);
export const GenerateOTP = createAsyncThunk(
  "GenerateOTP",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/otp_gen";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);
export const VerifyOTPUser = createAsyncThunk(
  "GenerateOTP",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/otp_verify";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const ChangePasswordUser = createAsyncThunk(
  "ChangePassword",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/change_password";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const GetUserUniqueName = createAsyncThunk(
  "GetUserUniqueName",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/user/get_unique_username";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state) => {
      return { ...state, isAuthenticated: !state.isAuthenticated };
    },
    userLogout: () => {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(loginAccount.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(loginAccount.fulfilled, (state, action) => {
        console.log(action?.payload, "==============");
        return {
          ...state,
          loading: false,
          accessToken: action.payload?.response?.token,
          isAuthenticated: true,
          user: action.payload?.response?.user,
        };
      })
      .addCase(loginAccount.rejected, (state) => {
        return { ...state, loading: false };
      })

      .addCase(SignUpAccount.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(SignUpAccount.fulfilled, (state, action) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(SignUpAccount.rejected, (state) => {
        return { ...state, loading: false };
      })

      .addCase(UpdateUser.pending, (state) => {
        return { ...state, loading: false };
      })
      .addCase(UpdateUser.fulfilled, (state) => {
        return {
          ...state,
          loading: false,
        };
      })
      .addCase(UpdateUser.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(getAllUser.pending, (state) => {
        return { ...state, loading: false };
      })
      .addCase(getAllUser.fulfilled, (state, action) => {
        // console.log(action?.payload, "==============Hardikkkkkkkkk");
        return {
          ...state,
          userData: action.payload?.response?.user,
          loading: false,
        };
      })
      .addCase(getAllUser.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export const { userLogin, userLogout } = userSlice.actions;

export default userSlice.reducer;
