import toast from "react-hot-toast";
import { Form, Formik } from "formik";

import { addComment, updateLabCase } from "../../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";

const AddComment = ({ setOpenComment, addLabCase, setRefetch }: any) => {
  const dispatch = useAppDispatch();

  const userId: any = useAppSelector((state) => state?.user?.user);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);

  const handleComment = (value: any) => {
    const payload = {
      comment: value.comment,
      task_id: addLabCase?._id,
      user_id: userId?._id,
    };
    const payloads = {
      lab_id: addLabCase?._id,
      title: addLabCase?.title,
      labName: addLabCase?.labName,
      description: addLabCase?.description,
      owner: AdminUser?._id,
      status: addLabCase?.status,
      assign_to: addLabCase?.assignUsersData ? addLabCase?.assignUsersData : [],
      organization_id: AdminUser?.organization_id,

    };
    dispatch(addComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          // nav("/EditLead", { state: [{ ...addDispute, task_for: "update" }] });
          setOpenComment(false);
          toast.success(res?.msg, {
            position: "top-center",
          });
       
          dispatch(updateLabCase(payloads)).unwrap().then(() => {
            setRefetch((pre: number) => pre + 1)

          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function handleClickCancel() {
    setRefetch((prev: number) => prev + 1);
    setOpenComment(false)
  }
  

  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99] bg-[#00000040]">
      <div className="backdrop-blur-[5px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[40%] h-[40%] md:w-[40%] md:h-[45%] rounded-[15px] z-[100] py-6 px-10 flex-col scrollable overflow-auto md:px-4 pb-10 "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={{ comment: "" }}
          //   validationSchema={AddLeadTaskSchema}
          onSubmit={(values, { resetForm }) => {
            handleComment(values);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => (
            <Form
              onSubmit={handleSubmit}
              className="h-full flex flex-col justify-between"
            >
              <div className=" flex   px-10 flex-col md:px-6  ">
                <div className="w-full py-4 md:w-full">
                  <div className="font-medium pb-2">Comment</div>
                  <div
                    className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none"
                    style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                  >
                    <textarea
                      placeholder="Write some comment here"
                      className="w-full min-h-[120px] outline-none"
                      name="comment"
                      value={values?.comment}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full flex justify-end mt-4 gap-4 py-2">
                    <button
                      onClick={() => handleClickCancel()}
                      type="button"
                      className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      Add Comment
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddComment;
