import React, { useEffect, useState } from "react";
import RespoAllTask from "../Components/ResponsiveComponents/RespoAllTask";
import SubHeader from "../Components/Header Component/SubHeader";
import Loader from "../assets/image/svg/Loader";
import ViewSingleTask from "../Components/TaskComponents/ViewSingleTask";
import Ready from "../Components/LabCases/Ready";
import PickedUp from "../Components/LabCases/PickedUp";
import Completed from "../Components/LabCases/Completed";
import Recieved from "../Components/LabCases/Recieved";
import ViewSingleCase from "../Components/LabCases/ViewSingleCase";
import RespoAllLabCase from "../Components/ResponsiveComponents/RespoAllLabCase";
import { getAllLabCases } from "../Slices/TaskSlice";
import { useAppDispatch } from "../Redux/Hooks";
import toast from "react-hot-toast";

interface PropsType {
  todo: any;
  inprogress: any;
  completed: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  refetch: any;
  setRefetch: any;
  loader: any;
  labCaseData: any;
  addLabCase: any;
  openComment: any;
  setOpenComment: any;
  setLabCaseData?: any;
  userDetails?: any;
  myTask?: any;
  setMyTask?: any;
}

const AllLabCaseScreen: React.FC<PropsType> = ({
  todo,
  inprogress,
  completed,
  windowWidth,
  showScreen,
  setShowScreen,
  refetch,
  setRefetch,
  loader,
  labCaseData,
  addLabCase,
  openComment,
  setOpenComment,
  setLabCaseData,
  userDetails,
  myTask,
  setMyTask,
}) => {
  const dispatch = useAppDispatch();
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<any>("medium");

  const handleSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSize(event.target.value);
  };

  const handleGetAllLabCase = () => {
    const payload = {
      search: "",
      status: "",
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : "",
    };
    dispatch(getAllLabCases(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setLabCaseData({
            ready: [],
            pickedUp: [],
            received: [],
            completed: [],
          })
          const tempReady: any = [];
          const tempPickedUp: any = [];
          const tempReceive: any = [];
          const tempCompleted: any = [];
          res?.labCaseData?.forEach((item: any) => {
            if (item.status === "ready") {
              tempReady.push(item);
            } else if (item.status === "picked_up") {
              tempPickedUp.push(item);
            } else if (item.status === "received") {
              tempReceive.push(item);
            } else if (item.status === "send") {
              tempCompleted.push(item);
              // setLabCaseData((prevState: any) => ({
              //   ...prevState,
              //   completed: [...prevState.completed, item], // Spread previous array and add new item
              // }));
            }
            setLabCaseData({
              ready: tempReady,
              pickedUp: tempPickedUp,
              received: tempReceive,
              completed: tempCompleted,
            });
          });
        }else{
          setLabCaseData([])
        }
      })
      .catch((error) => {
        console.log("===================error", error);
        toast.error(error?.msg);
        setLabCaseData([]);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMyTask(e.target.value);
  };

  useEffect(() => {
    handleGetAllLabCase();
  }, [myTask]);
  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllLabCase
          button={"Add Lab Case"}
          todo={todo}
          size={size}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          setViewTask={setViewTask}
          taskDetail={taskDetail}
          // viewTask={viewTask}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Lab Case"}
          openComment={openComment}
          setOpenComment={setOpenComment}
          myTask={myTask}
          addLabCase={addLabCase}
          labCaseData={labCaseData}
        />
      ) : (
        <div className=" w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="LabCases"
              button="Add Lab Case"
              setHandleOpen={setHandleOpen}
            />

            <div className="w-full items-center gap-3 flex justify-end mt-3 pr-5 ">
              <div className="pr-5">
                <label className="mr-2 text-[15px]">Filter Task:</label>
                <select
                  id="size"
                  value={myTask}
                  onChange={(e) => handleChange(e)}
                  className="px-2  text-[12px] py-1 border border-none rounded"
                >
                  <option value={"true"}>My Task</option>
                  <option value={"false"}>All Task</option>
                  {userDetails?.map((item: any, index: number) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div
              className=" w-full  flex gap-2  py-4  justify-between  "
              style={{ height: "calc(100% - 48px)" }}
            >
              <Ready
                todo={todo}
                size={size}
                myTask={myTask}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <PickedUp
                size={size}
                myTask={myTask}
                setRefetch={setRefetch}
                inprogress={inprogress}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />

              <div className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <Recieved
                size={size}
                myTask={myTask}
                setRefetch={setRefetch}
                labCaseData={labCaseData}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />

              <div className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"></div>
              <Completed
                size={size}
                setRefetch={setRefetch}
                myTask={myTask}
                completed={completed}
                setTaskDetail={setTaskDetail}
                setViewTask={setViewTask}
                setOpenComment={setOpenComment}
                openComment={openComment}
                addLabCase={addLabCase}
              />
            </div>
          </div>
          {loader && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
              <div className="bg-[#00000047] backdrop-blur-sm z-[99] w-full h-full absolute top-0 left-0" />
              <div className="relative z-[999]">
                <Loader />
              </div>
            </div>
          )}

          {viewTask && (
            <ViewSingleCase
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </>
  );
};

export default AllLabCaseScreen;
