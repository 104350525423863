import moment from "moment";
import React, { useState } from "react";
import { CiMoneyCheck1 } from "react-icons/ci";
import { Draggable } from "react-beautiful-dnd";

import AddNewDispute from "./AddNewDispute";
import DragSVG from "../../assets/image/svg/DragSVG";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";

const CartTask = ({
  setOpenComment,
  item,
  index,
  setRefetch,
  refetch,
}: any) => {
  const [updateDispute, setUdateDispute] = useState(false);

  const handleClick = (e: any) => {
    e.stopPropagation();
    setUdateDispute(true);
  };
  const fullName = item?.guest_name;
  const nameParts = fullName.split(" ");
  const firstInitial = nameParts[0]?.charAt(0);
  const lastInitial = nameParts[0]?.charAt(1);
  return (
    <React.Fragment>
      <Draggable draggableId={item?._id} index={index}>
        {(provided) => (
          <div
            className={`h-auto w-auto mx-2 p-4 m-1 border-none bg-white rounded-[14px] cursor-pointer `}
            style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
            onDoubleClick={handleClick}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="w-full flex justify-between  items-center ">
              <div className="flex items-center gap-3 ">
                <span className="w-[18px] h-[18px] text-[12px] text-white bg-slate-600 flex items-center justify-center p-3 rounded-full">
                  {firstInitial + lastInitial}
                </span>
                <p className="font-semibold"> {item?.guest_name}</p>
              </div>

              <div className="flex items-center gap-2">
                <span className="">
                  <DragSVG />
                </span>
              </div>
            </div>
            <div className="flex items-center mt-1 gap-2 ">
              <CalenderSVG />
              <span className="text-[11px] text-[#8591A2]">
                {moment(item?.createdAt).fromNow()}
              </span>
            </div>
            <div className="flex gap-2 items-center mt-3">
              <CiMoneyCheck1 color="#8591A2" />
              <span className="text-[#8591A2] text-[14px] font-normal">
                {"$"} {item?.disputed_amount}
              </span>
            </div>
          </div>
        )}
      </Draggable>
      {updateDispute && (
        <AddNewDispute
          setUdateDispute={setUdateDispute}
          item={item}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      )}
    </React.Fragment>
  );
};

export default CartTask;
