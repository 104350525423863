import { Formik ,Form} from "formik";
import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import SelectField from "../Field Components/SelectField";
import DateField from "../Field Components/DateField";
import { useNavigate } from "react-router-dom";
import { attemptsAddLead } from "../../Slices/TaskSlice";
import { AddLeadAttemptsSchema } from "../../Validations/FormikValidation";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../Redux/Hooks";

const AddLeadAttempts = ({ setLoader, loader, singleleadData ,setAddAttempts}: any) => {
  const [contactMethod, setContactMethod] = useState<string[]>([
    "Phone",
    "Email",
    "Text",
  ]);

  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const initialValue = {
    dateContacted: "",
    descriptions: "",
    contMethod: "Phone",
  };

  function handleAddAttempts(value: any) {
    console.log(value, "value add attempts");
    const payload = {
      lead_id: singleleadData?._id,
      contact_method: value?.contMethod,
      description: value?.descriptions,
      date_contacted: value?.dateContacted,
    };
    dispatch(attemptsAddLead(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          // setLoader(true);
          // setRefetch(refetch + 1);
          nav(`/leadTask/${singleleadData?._id}`);
          setAddAttempts(false);
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        // setLoader(false);
      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  }
  return (
    <React.Fragment>
      <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
      <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
        <div
          className="py-6 bg-white h-[480px] w-[40%] rounded-br-[20px] rounded-[20px] px-10  "
          style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
        >
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={AddLeadAttemptsSchema}
            onSubmit={(values, { resetForm }) => {
              handleAddAttempts(values);

              // resetForm();
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => {
              console.log(values, errors, "values");
              return (
                <Form
                  // onSubmit={handlesSubmit}
                  className="h-full flex flex-col justify-between"
                >
                  <div className="flex flex-col justify-center items-centers gap-3">
                    <div className="flex justify-between ">
                      <p className="text-[20px] text-[#252525] font-semibold py-2">
                        Add Lead Contact Attempt
                      </p>
                      <RxCross2
                        onClick={() => setAddAttempts(false)}
                        className="cursor-pointer"
                      />
                    </div>
                    <div className="font-medium ">Phone Number</div>
                    <SelectField
                      className="flex  w-full rounded-lg border border-[#DADADA] italic"
                      fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[16px] text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                      name="contMethod"
                      options={contactMethod}
                      value={values.contMethod}
                      dataType="string"
                      errors={errors?.contMethod}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />

                    <div className="font-medium ">Date Contacted</div>
                    <DateField
                      className="w-full"
                      fieldClass="w-full border rounded-[10px] border-[#A0A0A0] h-[40px] pl-4 outline-none uppercase pr-3"
                      placeholderStyle={{ top: "8px", left: "16px" }}
                      type="date"
                      name="dateContacted"
                      placeholder="Enter Date"
                      value={values?.dateContacted}
                      touched={touched}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errors={
                        errors.dateContacted ? errors.dateContacted : undefined
                      }
                    />
                    <div className="font-medium ">
                      Additional Notes(Optional)
                    </div>
                    <div className=" border rounded-[10px] border-[#A0A0A0] px-4 outline-none">
                      <textarea
                        placeholder="Add a description..."
                        className="w-full min-h-[60px] outline-none"
                        name="descriptions"
                        id="descriptions"
                        value={values?.descriptions}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="w-full flex justify-center gap-4 mt-5 ">
                      <button
                        onClick={() => setAddAttempts(false)}
                        className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                        style={{
                          boxShadow: "0px 4px 10px 0px #BAC9DB",
                        }}
                      >
                        cancel
                      </button>
                      <button
                        type="button"
                        onClick={() => handleSubmit()}
                        className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                        style={{
                          boxShadow: "0px 4px 10px 0px #BAC9DB",
                        }}
                      >
                        Add Attempt
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddLeadAttempts;
