import "./respo.css";
import { useEffect } from "react";
import RespoWave from "../../assets/image/RespoWave.png";
import { RxCross2 } from "react-icons/rx";
import AddTaskSVG from "../../assets/image/svg/AddTaskSVG";
import AddRegisterSVG from "../../assets/image/svg/AddRegisterSVG";
import AddMemberSVG from "../../assets/image/svg/AddMemberSVG";
import { useNavigate } from "react-router-dom";
import { FaUserDoctor } from "react-icons/fa6";
import { IoMdDocument } from "react-icons/io";
import AddLeadSvg from "../../assets/image/svg/AddLeadSvg";
import { useAppSelector } from "../../Redux/Hooks";

interface RespoSideBarProps {
  setShowSlider: (a: string) => void;
  showSlider: string;
  type: string;
  direction?: any;
}

const RespoSideBar = (props: RespoSideBarProps) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const organizationName = AdminUser?.organizationData?.[0]?.name;

  const sideBarData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/dashboard") &&
            window.location.pathname.includes("*") &&
            window.location.pathname.includes("/")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      name: "Task",
      url: "/dashboard",
    },

    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/maintanance")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      name: "Maintanance",
      url: "/maintanance",
    },
    {
      icon: (
        <AddLeadSvg
          color={
            window.location.pathname.includes("/leadTask")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      name: "Lead Task",
      url: "/leadTask",
    },

    {
      icon: (
        <AddLeadSvg
          color={
            window.location.pathname.includes("/qualityDispute")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      name: "Disputes",
      url: "/qualityDispute",
    },
    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/user") ? "#02647F" : "#000000"
          }
        />
      ),
      name: "User",
      url: "/user",
    },

    {
      icon: (
        <IoMdDocument
          size={25}
          color={
            window.location.pathname.includes("/document")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      name: "Document",
      url: "/document",
    },
  ];

  const sideBarDentalData = [
    {
      icon: (
        <AddTaskSVG
          color={
            window.location.pathname.includes("/dashboard") &&
            window.location.pathname.includes("*") &&
            window.location.pathname.includes("/")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      name: "Home",
      url: "/dashboard",
    },
    {
      icon: (
        <AddLeadSvg
          color={
            window.location.pathname.includes("/leadTask")
              ? "#02647F"
              : "#000000"
          }
        />
      ),
      name: "Lead",
      url: "/leadTask",
    },
    {
      icon: (
        <FaUserDoctor
          color={
            window.location.pathname.includes("/labCase")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      name: "Lab Case",
      url: "/labCase",
    },

    {
      icon: (
        <AddMemberSVG
          color={
            window.location.pathname.includes("/user") ? "#02647F" : "#000000"
          }
        />
      ),
      name: "User",
      url: "/user",
    },
    {
      icon: (
        <IoMdDocument
          size={25}
          color={
            window.location.pathname.includes("/document")
              ? "#B8B9C8"
              : "#B8B9C8"
          }
        />
      ),
      name: "Document",
      url: "/document",
    },
  ];
  const nav = useNavigate();

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center">
      <div
        className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer bg-[#00000040]"
        onClick={() => props.setShowSlider("")}
      ></div>
      <div
        className={`notification-container ${
          props.direction === "left" && "slideFromLeft"
        }`}
        style={{
          [props.direction]: 0,
          boxShadow: "5px 4px 20px 0px #00000026",
        }}
      >
        <div
          className="notification-content h-full"
          style={{
            backgroundImage: `url(${RespoWave})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "-100% 0%",
            objectFit: "cover",
            backgroundSize: "250px 250px",
          }}
        >
          <div className="flex flex-col justify-between min-h-full w-full py-8">
            <div>
              <div className="flex flex-row justify-between items-center px-8">
                <div className="flex flex-col">
                  <span className="font-medium text-[24px]">Task</span>
                  <span className="font-medium text-[24px] "> Management</span>
                </div>
                <RxCross2
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                    color: "#252525",
                  }}
                  onClick={() => props.setShowSlider("")}
                />
              </div>
              <div className="mt-8">
                <ul className="">
                  {/* data={organizationName === "Dental" ? sideBarDentalData : sideBarData} */}
                  {organizationName === "Dental"
                    ? sideBarDentalData?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => nav(item.url)}
                          className="relative cursor-pointer flex flex-row gap-4 items-center py-5 px-8 text-[18px] font-medium mr-2"
                          style={
                            window.location.pathname.includes(item.url)
                              ? { backgroundColor: "#3E5F71" }
                              : { backgroundColor: "" }
                          }
                        >
                          <span>{item.icon}</span>
                          <span
                            style={
                              window.location.pathname.includes(item.url)
                                ? { color: "white" }
                                : { color: "" }
                            }
                            className="text-center text-[#3D5765] "
                          >
                            {item?.name}
                          </span>
                        </li>
                      ))
                    : sideBarData?.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => nav(item.url)}
                          className="relative cursor-pointer flex flex-row gap-4 items-center py-5 px-8 text-[18px] font-medium mr-2"
                          style={
                            window.location.pathname.includes(item.url)
                              ? { backgroundColor: "#3E5F71" }
                              : { backgroundColor: "" }
                          }
                        >
                          <span>{item.icon}</span>
                          <span
                            style={
                              window.location.pathname.includes(item.url)
                                ? { color: "white" }
                                : { color: "" }
                            }
                            className="text-center text-[#3D5765] "
                          >
                            {item?.name}
                          </span>
                        </li>
                      ))}
                </ul>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RespoSideBar;
