import React from "react";
import { Droppable } from "react-beautiful-dnd";

import ArrowSVG from "../../assets/image/svg/ArrowSVG";
import CartTask from "./CartTask";
import AddComment from "./AddComment";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";
import { useNavigate } from "react-router-dom";

interface PropsType {
  todo: any;
  setTaskDetail: any;
  setViewTask: any;
  setView?: any;
  view?: any;
  // taskDetail: any;
  setShowTask?: any;
  type?: any;
  size: any;
  myTask: any;
  addLabCase: any;
  openComment: any;
  setOpenComment: any;
  setRefetch: any;
}

const Ready: React.FC<PropsType> = ({
  todo,
  setShowTask,
  setTaskDetail,
  setViewTask,
  type,
  size,
  myTask,
  addLabCase,
  openComment,
  setOpenComment,
  setRefetch,
  // taskDetail
}) => {
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.user);
  const calcLength = () => {
    if (myTask === "true") {
      const tempArray = todo?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return todo?.length;
    }
  };

  return (
    <>
      <div className="mt-4 lg:hidden">
        <IoMdArrowBack size={25} onClick={() => navigate(-1)} />
      </div>
      <Droppable droppableId="readyList">
        {(provided) => (
          <div
            className="w-1/3 md:w-full h-full bg-[#E2E8F0] p-2  md:mt-[20px] rounded-[15px]"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className=" h-full flex justify-center flex-wrap overflow-auto">
              <div className="w-full h-full flex flex-col gap-4 px-2 py-3">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px]  font-semibold text-center px-5 py-1 bg-[#CDEAFF] rounded-[7px] text-[#0496FF] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    Ready ({todo?.length || 0})
                  </span>
                </div>
                <div className="flex flex-col py-1 px-2 items-start">
                  <span className="font-medium text-[14px]">Case Ready</span>
                  <span className="text-[#505050] text-[13px]">
                    The tasks are assigned to team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto">
                  {todo?.length > 0 ? (
                    todo?.map((item: any, index: number) => {
                      return (
                        <CartTask
                          setViewTask={setViewTask}
                          setTaskDetail={setTaskDetail}
                          icon={<HighStatusSvg />}
                          item={item}
                          size={size}
                          // taskDetail={taskDetail}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <div className="h-full flex flex-col  justify-center">
                      <p>Task has not been created for this user</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {openComment && (
        <div className="w-full h-[50%] relative">
          <AddComment
            openComment={openComment}
            setOpenComment={setOpenComment}
            addLabCase={addLabCase}
            setRefetch={setRefetch}
          />
        </div>
      )}
    </>
  );
};

export default Ready;
