import { BsThreeDotsVertical } from "react-icons/bs";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import DragSVG from "../../assets/image/svg/DragSVG";

import Billing from "../../assets/icons/billing.png";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";
import { useNavigate } from "react-router-dom";

type Size = "small" | "medium" | "large";

const CartTask = ({
  size,
  item,
  setViewTask,
  icon,
  index,
  setTaskDetail,
  type,
}: any) => {
  const nav = useNavigate();
  const handleChange = () => {
    setTaskDetail(item);
    setViewTask(true);
    nav(`/AddLabCase/${item?._id}`);
  };

  const scaleClass = {
    small: "text-80",
    medium: "text-90",
    large: "text-100",
  }[size as Size];
  
  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided) => (
        <div
          key={index}
          onDoubleClick={handleChange}
          className={`h-auto  w-auto mx-2 p-3 m-1 border-none bg-white rounded-[14px] cursor-pointer ${scaleClass}   
`}
          style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="w-full flex justify-between  items-center ">
            <div className="flex items-center gap-3 w-[55%]">
              {/* <span className="w-[14px] h-[14px]"> {icon}</span> */}
              <p className="font-semibold text-[14px] w-[70%] ">{item.title}</p>
            </div>

            <div className="flex items-center gap-2">
              {item?.status === "ready" ? (
                <button className="  text-[12px] text-center px-5 py-1 bg-[#0496FF33] rounded-[7px] text-[#0496FF] font-medium">
                  Ready
                </button>
              ) : item?.status === "picked_up" ? (
                <button className="  text-[12px] text-center px-4 py-1 bg-[#E08C5033] rounded-[7px] text-[#EE7E2E] font-medium">
                  Picked Up
                </button>
              ) : item?.status === "received" ? (
                <button className="  text-[12px] text-center px-5 py-1 bg-[#3BB31133] rounded-[7px] text-[#3BB311] font-medium">
                  Recieved
                </button>
              ) : item?.status === "send" ? (
                <button className="  text-[12px] text-center px-5 py-1 bg-[#CCCDCE] rounded-[7px] text-[#3D5765] font-medium">
                  Delivered
                </button>
              ) : (
                ""
              )}
              <span className="">
                <DragSVG />
              </span>
              {/* <BsThreeDotsVertical size={20} /> */}
            </div>
          </div>
          <div className="flex w-full justify-between  items-center my-2 ">
            <div className="flex items-center gap-2  text-nowrap overflow-auto text-ellipsis">
              <span className="text-[14px] font-normal text-black">
                Lab name: {item?.labName}
              </span>
              {/* </div> */}
              {/* <ArrowSVG /> */}
              {item?.assignUsersData?.map((item: any, index: number) => {
                const fullName = item?.name;
                const nameParts = fullName.split(" ");
                const firstInitial = nameParts[0]?.charAt(0);
                const lastInitial = nameParts[0]?.charAt(1);

                return (
                  <div className="relative group w-[20px] h-[20px] bg-[#3E5F71] rounded-full flex justify-center items-center ">
                    <span className="text-[10px] font-normal text-white cursor-pointer">
                      {firstInitial + lastInitial}
                    </span>
                    <span className="absolute left-1/2 p-3 -translate-x-1/2  hidden group-hover:block bg-[#3E5F71] text-white text-xs rounded z-10">
                      {item?.first_name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="border w-full h-[120px] md:h-[45px] overflow-auto  outline-none p-2 bg-[#D9D9D926] rounded-[5px]  border-[#8591A22E]">
            <p> {item?.description}</p>
          </div>
          <div className="flex justify-between items-center gap-2 mt-2">
            <p className="text-[12px] text-[#8591A2]">
              Created By : {item?.ownerDetails[0]?.name}
            </p>
            {/* <p className="flex items-center gap-1">
              <CalenderSVG />
              <span className="text-[11px] text-[#8591A2]">
                {moment(item?.createdAt).diff(moment(), "days")} days ago
              </span>
            </p> */}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CartTask;
