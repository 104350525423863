import moment from "moment";
import { Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../Redux/Hooks";
import DragSVG from "../../assets/image/svg/DragSVG";
import bedRoom from "../../assets/image/bed-room.png";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";
import { getSingleMaintanance } from "../../Slices/TaskSlice";
import todoIcon from "../../assets/image/todoIcon.png";
import inProgressIcon from "../../assets/image/in-progress.png";
import completedIcon from "../../assets/image/completed-solid.png";
import { BiSolidFileJpg, BiSolidFilePdf } from "react-icons/bi";

type Size = "small" | "medium" | "large";

const CartTask = ({
  size,
  item,
  setViewTask,
  icon,
  index,
  setTaskDetail,
  type,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const scaleClass = {
    small: "text-80",
    medium: "text-90",
    large: "text-100",
  }[size as Size];

  const fullName = item?.title;
  const nameParts = fullName.split(" ");
  const firstInitial = nameParts[0]?.charAt(0);
  const lastInitial = nameParts[0]?.charAt(1);

  const handleChange = () => {
    navigate(`/AddSureStayMaint/${item?._id}`);
  };

  const currentDate = new Date();
  const daysRemaining = moment(item?.deadline).diff(
    moment(currentDate),
    "days"
  );

  const borderClass =
    item?.status !== "completed" && daysRemaining <= 5
      ? "border-solid border-2 border-red-600"
      : "";

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided) => (
        <div
          key={index}
          onDoubleClick={handleChange}
          className={`h-auto  w-auto mx-2 p-3 m-1  bg-white rounded-[14px] cursor-pointer  ${scaleClass} ${borderClass}
`}
          style={{
            border: "1px solid red",
            boxShadow: "0px 1.8px 8.98px 0px #0000000D",
            // border:
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <div className="w-full flex justify-between  items-center ">
            <div className="flex items-center gap-3 w-[55%]">
              <span className="text-[8px] flex items-center justify-center w-[15px] h-[15px] py-3 px-3 rounded-full bg-[#0496FF33] text-[#0094FF] font-normal  cursor-pointer">
                {firstInitial + lastInitial}
              </span>
              <p className="font-semibold w-[70%]">{item?.title}</p>
            </div>

            <div className="flex items-center gap-2">
              {item?.status === "todo" ? (
                <span>
                  <img src={todoIcon} alt="Todo" />
                </span>
              ) : item?.status === "completed" ? (
                <span>
                  <img src={completedIcon} alt="Completed" />
                </span>
              ) : item?.status === "inprogress" ? (
                <span>
                  <img src={inProgressIcon} alt="Completed" />
                </span>
              ) : (
                ""
              )}

              <span className="">
                <DragSVG />
              </span>
              {/* <BsThreeDotsVertical size={20} /> */}
            </div>
          </div>
          <div className="w-full my-2 flex justify-between">
            <div className="flex items-center gap-2 ">
              <span className="w-[14px] h-[14px]"> {icon}</span>
              <span className="text-[10px] ml-4 font-normal text-black">
                {item.title}
              </span>
            </div>
            {item?.DocumentsData[0]?.document_ext?.includes("image") ? (
              <BiSolidFileJpg color="#007cb8" size={20} />
            ) : item?.DocumentsData[0]?.document_ext?.includes("pdf") ? (
              <BiSolidFilePdf color="#DF0000" size={20} />
            ) : (
              ""
            )}
          </div>
          <p className="flex  my-3 items-center gap-2   text-[#8591A2]">
            <img src={bedRoom} alt="bed room" />
            <span className="font-medium text-[#3D5765] text-[12px]">
              {" "}
              {item?.maintenance_task_sub_type}
            </span>
          </p>
          <div className="border border-[#EBEBEB]"></div>
          <div className="flex justify-between items-center gap-2 mt-2">
            <p className="text-[14px] text-[#8591A2]">
              Created by: {item?.ownerDetails[0]?.name}
            </p>
            <p className="flex items-center gap-1">
              {/* <CalenderSVG /> */}
              {item?.status === "completed" ? (
                <span className="text-[11px] text-[#8591A2]">
                  Completed at : {" "}
                  {moment(item?.updatedAt).fromNow()}
                </span>
              ) : (
                <span className="text-[11px] text-[#8591A2]">
                  Past Due Days:{" "}
                  {moment(item?.deadline).diff(moment(currentDate), "days") < 0
                    ? "overdue"
                    : moment(item?.deadline).diff(moment(currentDate), "days")}
                </span>
              )}
            </p>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CartTask;
