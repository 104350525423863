import React, { useState } from "react";
import toast from "react-hot-toast";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import InputField from "../Field Components/InputField";
import { createLabCase, updateLabCase } from "../../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { AddLabCaseSchema } from "../../Validations/FormikValidation";
// import UploadFile from "../Field Components/UploadFile";
import Loader from "../../assets/image/svg/Loader";
import SelectField from "../Field Components/SelectField";
import CommentSection from "../maintananceComponent/CommentSection";
import { MdDelete } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import moment from "moment";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";

const AddLabCase = ({
  refetch,
  setRefetch,
  labCaseId,
  singleLab,
  setLoader,
  loader,
}: any) => {
  const nav = useNavigate();
  const dispatch = useAppDispatch();
  const [cancelPopup, setCancelPopup] = useState(false);
  const [comment, setComment] = useState([]);
  const [deletetask, setDeleteTask] = useState(false);
  const [activeTab, setActiveTab] = useState<number>(0);
  const tabs: any = ["Comments", "Status"];
  const labCaseStatus = ["ready", "picked_up", "received", "completed"];
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const initialValues = {
    title: singleLab?.title || "",
    description: singleLab?.description || "",
    labName: singleLab?.labName || "",
    comment: singleLab?.commentsData || "",
    owner: singleLab?.owner || "",
    assignee: singleLab?.assign_to || [],
    organization_id: singleLab?.organization_id || "",
    status: singleLab?.status || "",
  };

  const handleTaskFunc = (value: any) => {
    setLoader(true);
    const payload = {
      title: value?.title,
      description: value?.description,
      labName: value?.labName,
      organization_id: AdminUser?.organization_id,
      assign_to: value?.assignee,
      status: value?.status,
    };
    const payloads = {
      lab_id: singleLab?._id,
      title: singleLab?.title,
      labName: singleLab?.labName,
      description: singleLab?.description,
      owner: AdminUser?._id,
      status: singleLab?.status,
      assign_to: singleLab?.assignUsersData ? singleLab?.assignUsersData : [],
      organization_id: AdminUser?.organization_id,
    };

    (labCaseId
      ? dispatch(updateLabCase(payloads))
      : dispatch(createLabCase(payload))
    )
      .unwrap()
      .then((res) => {
        console.log("response of the data is herere ============", res);
        if (res?.success === true) {
          setRefetch(refetch + 1);
          nav("/labCase");
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error?.msg);
      });
  };

  const handleDelete = () => {
    setDeleteTask(true);
  };

  const handleClose = () => {
    setCancelPopup(true);
    // setRefetch(refetch + 1);
    // nav("/dashboard");
  };
  function handleClosePopup() {
    setCancelPopup(false);
    setRefetch(refetch + 1);
    nav("/labCase");
  }
  function handelCancel() {
    setCancelPopup(false);
  }

  let commentsDate: any = [""];

  commentsDate =
    singleLab?.commentsData.length &&
    singleLab?.commentsData?.map((item: any, index: number) => {
      {
        return moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a");
      }
    });

  return (
    <>
      <div
        className="relative w-full h-[90%]  bg-white rounded-[15px] "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={AddLabCaseSchema}
          onSubmit={(values, { resetForm }) => {
            handleTaskFunc(values);
            resetForm();
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
          }: any) => {
            return (
              <Form
                onSubmit={handleSubmit}
                className="h-full flex flex-col justify-between"
              >
                <div className="flex   px-10 flex-col md:px-6  scrollable overflow-auto">
                  <div className="w-full h-full">
                    <div className="w-full flex justify-between py-4">
                      <span className="font-semibold">Patient Name</span>
                      <div className="flex  gap-10">
                        {AdminUser?.userType === "admin" && (
                          <span className="font-medium " onClick={handleDelete}>
                            <MdDelete
                              size={25}
                              color={"#D65252"}
                              className="cursor-pointer"
                            />
                          </span>
                        )}
                        <RxCross2
                          size={20}
                          onClick={() => nav("/labCase")}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                    <div className="  border-[#A0A0A0] rounded-[10px] ">
                      <InputField
                        className="w-full  rounded-lg border border-[#DADADA] italic"
                        fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                        placeholderStyle={{ top: "8px", left: "18px" }}
                        type="text"
                        name="title"
                        placeholder="Write patient name here"
                        value={values?.title}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors.title ? errors.title : undefined}
                      />
                    </div>
                    <div className="w-full flex py-4 md:flex-col">
                      <div className="w-3/5 md:w-full">
                        <div className="w-[85%] py-4 md:w-full ">
                          <div className="font-medium pb-2">Description</div>
                          <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                            <textarea
                              placeholder="Add a description..."
                              className="w-full min-h-[120px] outline-none placeholder:text-[20px]"
                              name="description"
                              id="description"
                              value={values?.description}
                              onChange={handleChange}
                            />
                          </div>

                          {/* <div className="w-full py-1">
                            <FieldArray
                              name="idDocuments"
                              render={(arrayHelpers) => {
                                return (
                                  <>
                                    {values?.idDocuments?.map(
                                      (item: any, index: number) => (
                                        <div
                                          key={index}
                                          className=" flex flex-col"
                                        >
                                          <td className="relative ">
                                            <UploadFile
                                              className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                              fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                              placeholderStyle={{
                                                top: "10px",
                                                left: "16px",
                                              }}
                                              name={`idDocuments.${index}.document_name`}
                                              location={`idDocuments.${index}`}
                                              arrayName="idDocuments"
                                              placeholder={item?.document_type}
                                              value={item}
                                              fileFrom={"case"}
                                              setFieldValue={setFieldValue}
                                              onBlur={handleBlur}
                                              touched={touched}
                                              errors={
                                                errors?.idDocuments &&
                                                errors?.idDocuments[index]
                                                  ?.document_name
                                              }
                                            />
                                            {index >
                                              initialValues?.idDocuments
                                                ?.length -
                                                1 &&
                                              !item?.document_name && (
                                                <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                  <button
                                                    type="button"
                                                    onClick={() => {
                                                      arrayHelpers.remove(
                                                        index
                                                      );
                                                    }}
                                                  >
                                                    X
                                                  </button>
                                                </div>
                                              )}
                                          </td>
                                        </div>
                                      )
                                    )}
                                    <div
                                      className="flex flex-col"
                                      onClick={() =>
                                        arrayHelpers.insert(
                                          values?.idDocuments?.length,
                                          {
                                            document_name: "",
                                            document_link: "",
                                            document_type: "Attach Documents",
                                            document_ext: "",
                                            document_field: "idDocuments",
                                          }
                                        )
                                      }
                                    >
                                      <td>
                                        <UploadFile
                                          className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                          fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                          placeholderStyle={{
                                            top: "10px",
                                            left: "16px",
                                          }}
                                          name="add_document"
                                          arrayName="idDocuments"
                                          data={values?.idDocuments}
                                          fileFrom={"case"}
                                          placeholder="+ Attach More Documents"
                                          setFieldValue={setFieldValue}
                                          onBlur={handleBlur}
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </td>
                                    </div>
                                  </>
                                );
                              }}
                            />
                          </div> */}

                          {singleLab?._id && (
                            <React.Fragment>
                              {commentsDate && commentsDate?.length !== 0 ? (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add {singleLab?.status} -{" "}
                                        {commentsDate[commentsDate?.length - 1]}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              ) : (
                                <>
                                  <div className="pt-3 ">
                                    <p className="flex items-center gap-2">
                                      <FaCircle color="#0496FF" size={10} />
                                      <span className="text-[#8591A2] text-[14px]">
                                        Last Activity: Add {singleLab?.status} -{" "}
                                        {moment(singleLab?.createdAt).format(
                                          "MMMM Do YYYY, h:mm:ss a"
                                        )}{" "}
                                        by {AdminUser?.name?.toUpperCase()}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="border border-[#EBEBEB] my-3"></div>
                                </>
                              )}
                              <div className="flex ">
                                <div className="gap-6 bg-[#E5E5E5] px-2 my-1 rounded-3xl">
                                  {tabs.map((tab: any, index: any) => (
                                    <button
                                      type="button"
                                      key={tab}
                                      onClick={() => setActiveTab(index)}
                                      className={`
                               text-[14px] font-medium 
                               py-1 my-2
                               transition-all duration-200  mx-2
                               focus:outline-none rounded-3xl px-2
                               ${
                                 activeTab === index
                                   ? " bg-[#3D5765] text-[#FFFFFF]  transform scale-105"
                                   : "text-gray-200"
                               }
                             
                             `}
                                    >
                                      {tab}
                                    </button>
                                  ))}
                                </div>
                              </div>
                              <CommentSection
                                length={0}
                                values={values.comment}
                                errors={errors}
                                handleChange={handleChange}
                                touched={touched}
                                setFieldValue={setFieldValue}
                                data={singleLab}
                                comment={comment}
                                tabs={tabs}
                                activeTab={activeTab}
                                setComment={setComment}
                                refetch={refetch}
                                setRefetch={setRefetch}
                              />

                              
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                      <div className="w-[40%] md:w-full h-full px-4  md:px-0 flex flex-col py-4 gap-3">
                        <div className="font-medium ">Lab Name</div>
                        <div className="border-[#A0A0A0] rounded-[10px] ">
                          <InputField
                            className="w-full  rounded-lg border border-[#DADADA] italic"
                            fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                            placeholderStyle={{ top: "8px", left: "18px" }}
                            type="text"
                            name="labName"
                            placeholder="Add a description..."
                            value={values?.labName}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.labName ? errors.labName : undefined}
                          />
                        </div>

                        {/* <div className="font-medium ">Send Date</div>
                        <div className=" border-[#A0A0A0]  rounded-[10px] ">
                          <InputField
                            className="w-full  rounded-lg border border-[#DADADA] italic"
                            fieldClass="w-full h-[45px] pl-6 pr-6 outline-none italic text-[18px]"
                            placeholderStyle={{ top: "8px", left: "18px" }}
                            type="date"
                            name="sendDate"
                            placeholder="Date"
                            value={values?.sendDate}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={
                              errors.sendDate ? errors.sendDate : undefined
                            }
                          />
                        </div> */}

                        <div className="font-medium ">Status</div>
                        <SelectField
                          className="w-full  rounded-lg border border-[#DADADA] italic"
                          fieldClass="text-[14px] text-black font-medium w-full justify-between h-[40px] pl-4 pr-4"
                          name="status"
                          options={labCaseStatus}
                          value={values.status}
                          // fieldDisable={edit}
                          dataType="string"
                          errors={errors?.status}
                          touched={touched}
                          setFieldValue={setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 relative"
                  style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                >
                  <div className="w-full flex justify-end gap-4 py-2 px-4">
                    <button
                      onClick={handleClose}
                      type="button"
                      className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                      style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                    >
                      {labCaseId ? "Update" : "Add Lab Case"}
                    </button>
                  </div>
                  {cancelPopup === true && (
                    <>
                      <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
                      <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
                        <div
                          className="py-4 bg-white  w-[300px] max-h-[80vh] rounded-br-[20px] rounded-[20px] px-4  "
                          style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                        >
                          <div className="flex flex-col justify-center items-centers gap-3">
                            <p className="text-[16px] text-[#000000] font-semibold px-4">
                              Cancel Changes?
                            </p>
                            <p className="text-[14px] text-[#000000] font-medium px-4 py-4">
                              You are about to cancel all changes made to this
                              item. If you proceed, your unsaved edits will be
                              lost, and the item will revert to its previous
                              state.
                            </p>

                            <div className="w-full flex justify-end gap-4 ">
                              <button
                                onClick={handelCancel}
                                className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                cancel
                              </button>
                              <button
                                onClick={handleClosePopup}
                                className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                                style={{
                                  boxShadow: "0px 4px 10px 0px #BAC9DB",
                                }}
                              >
                                Okay
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <div className="backdrop-blur-[2px] w-full h-full fixed cursor-pointer"></div>
          <Loader />
        </div>
      )}

      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={singleLab}
          setRefetch={setRefetch}
          refetch={refetch}
          labCaseId={labCaseId}
        />
      )}
    </>
  );
};

export default AddLabCase;
