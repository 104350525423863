import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../Redux/Hooks";

interface PropsType {
  data: Data[];
}

type Data = {
  icon: ReactNode;
  url: string;
};

const SideBar: React.FC<PropsType> = ({ data }) => {

  const navigate = useNavigate();
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const organizationName = AdminUser?.organizationData?.[0]?.name
  return (
    <div
      className="md:hidden w-[7%] h-full  py-8 flex justify-center bg-white"
      style={{ boxShadow: "0px 4px 5px 0px #729CB2" }}
    >
      <ul className="flex flex-col gap-8  ">
        <span className="text-center text-[#3D5765] font-bold text-[24px]">
         {organizationName}
        </span>
        {data.map((item, index) => {
          if (AdminUser?.userType === "user" && (item?.url === "/user" || item?.url === "/document")) {
            return
          } else {
            return (
              <li
                key={index}
                onClick={() => navigate(item.url)}
                className=" flex flex-row gap-4 items-center justify-center cursor-pointer 
            px-[6px] py-[12px] rounded-[15px] "
                style={
                  window.location.pathname.includes(item.url)
                    ? { backgroundColor: "#3E5F71" }
                    : { backgroundColor: "" }
                }
              >
                {item.icon}

              </li>
            )
          }
        })}
      </ul>
    </div>
  );
};

export default SideBar;
