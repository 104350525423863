import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { updateTask, uploadDocument } from "../../Slices/TaskSlice";
import { RxCross2 } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { FieldArray, Form, Formik } from "formik";
import { AddTaskSchema } from "../../Validations/FormikValidation";
import InputField from "../Field Components/InputField";
import React, { useState } from "react";
import UploadFile from "../Field Components/UploadFile";
import TaskTable from "../Tables/TaskTable";
import TaskFieldTable from "../Tables/TaskFieldTable";
import CommentSection from "../../Components/maintananceComponent/CommentSection";
import Loader from "../../assets/image/svg/Loader";
import DeleteTaskPopups from "../../Popups/DeleteTaskPopups";
import { MdDelete, MdOutlineCloudUpload } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import moment from "moment";

const EditUserTask = ({ refetch, setRefetch }: any) => {
  const nav = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [comments, setComments] = useState([]);
  const [deletetask, setDeleteTask] = useState(false);
  const [loader, setLoader] = useState(false);
  const data = location.state || [];

  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs: any = ["Comments", "Status", "Attachments"];
  const [cancelPopup, setCancelPopup] = useState(false);
  const [comment, setComment] = useState([]);
  const AdminUser: any = useAppSelector((state) => state?.user?.user);
  const adminUserArray: any[] = AdminUser ? [AdminUser] : [];
  const { task, destinationId, destinationIndex } = location.state;

  // const commentData: any[] = data.task.commentsData;
  let commentsDate: any = [""];

  commentsDate =
    data[0]?.commentsData?.length &&
    data[0]?.commentsData?.map((item: any, index: number) => {
      {
        return moment(item?.updatedAt).format("MMMM Do YYYY, h:mm:ss a");
      }
    });

  const initialValues = {
    title: data[0]?.title ? data[0]?.title : "",
    description: data[0]?.description ? data[0]?.description : "",
    comment: data[0]?.commentsData ? data[0]?.commentsData : [],
    assignee: data[0]?.assignUsersData ? data[0]?.assignUsersData : [],
    idDocuments: data[0]?.DocumentsData
      ? data[0]?.DocumentsData
      : [
          {
            createdAt: "",
            document_ext: "",
            document_field: "idDocuments",
            document_link: "",
            document_name: "",
            document_type: "Attach Documents",
            task_id: "",
            updatedAt: "",
            __v: 0,
            _id: "",
          },
        ],
    owner: data[0]?.ownerDetails ? data[0]?.ownerDetails : [],
    priority: data[0]?.priority ? data[0]?.priority : "",
    deadline: data[0]?.deadline ? data[0]?.deadline : "",
    taskType: data[0]?.task_type ? data[0]?.task_type : "",
    status: data[0]?.status ? data[0]?.status : "",
    creationDate: data[0]?.createdAt ? data[0]?.createdAt : "",

    // dateOfContact: data[0]?.createdAt
    //   ? data[0]?.createdAt
    //   : "",
    // leadEmail: data[0]?.leadData[0]?.email ? data[0]?.leadData[0]?.email : "",

    text: data[0]?.text_for_Lead ? data[0]?.text_for_Lead : "",
  };

  const handleTaskFunc = (value: any) => {
    const assignUser: any[] = value?.assignee?.map(
      (item: any, index: number) => {
        return item?._id;
      }
    );

    setLoader(true);
    const payload = {
      task_id: data[0]?._id,
      task_details: {
        title: value?.title,
        description: value?.description,
        owner: value?._id,
        priority: value?.priority.toLowerCase(),
        status: value?.status,
        deadline: value?.deadline,
        assign_to: assignUser ? assignUser : [""],
        task_type: value?.taskType.toLowerCase(),
      },
      attached_files: value?.idDocuments,
    };

    dispatch(updateTask(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setRefetch(refetch + 1);
          nav("/dashboard");
          toast.success(res?.msg, {
            position: "top-center",
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error?.msg);
        setLoader(false);
      });
  };

  const handleClose = () => {
    setCancelPopup(true);
    // setRefetch(refetch + 1);
    // nav("/dashboard");
  };
  function handleClosePopup() {
    setCancelPopup(false);
    setRefetch(refetch + 1);
    nav("/dashboard");
  }
  function handelCancel() {
    setCancelPopup(false);
  }
  const handleDelete = () => {
    setDeleteTask(true);
  };

  return (
    <div
      className="relative w-full bg-white h-[90%] rounded-[15px] scrollable overflow-auto  "
      style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={AddTaskSchema}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          // handleComment(values);
          handleTaskFunc(values);

          // resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
        }: any) => {
          const handleChanged: any = (event: any) => {
            console.log(event, "upload files");
            const image = event.currentTarget.files[0];
            const formData = new FormData();
            formData.append("image", image);
            setLoader(true);
            if (image) {
              dispatch(uploadDocument(formData))
                .unwrap()
                .then((res) => {
                  if (res?.status) {
                    setFieldValue(
                      `idDocuments.${values?.idDocuments?.length}`,
                      {
                        document_name: image?.name?.trim(),
                        document_type: "Attach Documents",
                        document_link: res?.imageUrl,
                        document_ext: image?.type,
                        document_field: "idDocuments",
                      }
                    );

                    setLoader(false);
                  } else {
                    toast(res?.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      theme: "dark",
                      type: "error",
                    });
                  }
                })
                .catch((error) => {
                  toast(error?.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    theme: "dark",
                    type: "error",
                  });
                });
            }
          };
          return (
            <Form
              onSubmit={handleSubmit}
              className="h-full flex flex-col justify-between"
            >
              <div className="flex overflow-auto px-10 flex-col  md:px-6 ">
                <div className="w-full h-full">
                  <div className="w-full flex justify-end mt-2">
                    <div className="flex gap-10">
                      {AdminUser?.userType === "admin" && (
                        <span className="font-medium " onClick={handleDelete}>
                          <MdDelete
                            size={25}
                            color={"#D65252"}
                            className="cursor-pointer"
                          />
                        </span>
                      )}

                      <RxCross2
                        size={20}
                        onClick={() => nav("/leadTask")}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>

                  <div className="w-full flex md:flex-col">
                    <div className="w-3/5 md:w-full">
                      <div className="w-[85%] py-2 md:w-full">
                        <span className="font-semibold py-2">Task Title</span>

                        <div className="  border-[#A0A0A0] pt-2 rounded-[10px] ">
                          <InputField
                            className="w-full  rounded-lg border border-[#DADADA] italic"
                            fieldClass="w-full h-[40px] pl-6 pr-6 outline-none italic text-[18px]"
                            placeholderStyle={{ top: "8px", left: "18px" }}
                            type="text"
                            name="title"
                            placeholder="Enter Title"
                            value={values?.title}
                            touched={touched}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errors={errors.title ? errors.title : ""}
                          />
                        </div>
                        <div className="font-medium pb-2">Description</div>
                        <div className=" border rounded-[10px] border-[#A0A0A0] px-4 py-2 outline-none">
                          <textarea
                            placeholder="Add a description..."
                            className="w-full min-h-[120px] outline-none"
                            name="description"
                            id="description"
                            value={values?.description}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="w-full py-2  flex flex-col gap-3">
                          <div className="w-full flex gap-2 items-center relative">
                            <span className=" font-semibold ">
                              {" "}
                              Attached Files
                            </span>

                            <div className="relative ">
                              <p className="relative  flex font-medium text-[#0094FF] text-[14px] items-center justify-center gap-1">
                                Upload from here{" "}
                                <MdOutlineCloudUpload
                                  color="#0094FF"
                                  size={20}
                                  className="mt-1 mx-1 cursor-pointer"
                                />
                              </p>
                              <input
                                type="file"
                                className="absolute  h-full w-full z-20 top-0 left-0 opacity-0"
                                onChange={(event) => handleChanged(event)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex ">
                          <div className="gap-6 bg-[#E5E5E5] px-2 my-1 rounded-3xl">
                            {tabs.map((tab: any, index: any) => (
                              <button
                                type="button"
                                key={tab}
                                onClick={() => setActiveTab(index)}
                                className={`
                               text-[14px] font-medium 
                               py-1 my-2
                               transition-all duration-200  mx-2
                               focus:outline-none rounded-3xl px-2
                               ${
                                 activeTab === index
                                   ? " bg-[#3D5765] text-[#FFFFFF]  transform scale-105"
                                   : "text-gray-200"
                               }
                             
                             `}
                              >
                                {tab}
                              </button>
                            ))}
                          </div>
                        </div>
                        <React.Fragment>
                          {commentsDate && commentsDate?.length !== 0 ? (
                            <React.Fragment>
                              <div className="pt-3 ">
                                <p className="flex items-center gap-2">
                                  <FaCircle color="#0496FF" size={10} />
                                  <span className="text-[#8591A2] text-[14px]">
                                    Last Activity: -{" "}
                                    {commentsDate[commentsDate?.length - 1]} -
                                    Add {data[0]?.status} by{" "}
                                    {AdminUser?.name?.toUpperCase()}
                                  </span>
                                </p>
                              </div>
                              <div className="border border-[#EBEBEB] my-3"></div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="pt-3 ">
                                <p className="flex items-center gap-2">
                                  <FaCircle color="#0496FF" size={10} />
                                  <span className="text-[#8591A2] text-[14px]">
                                    Last Activity: -{" "}
                                    {moment(data[0]?.createdAt).format(
                                      "MMMM Do YYYY, h:mm:ss a"
                                    )}{" "}
                                    - Add {data[0]?.status} by{" "}
                                    {AdminUser?.name?.toUpperCase()}
                                  </span>
                                </p>
                              </div>
                              <div className="border border-[#EBEBEB] my-3"></div>
                            </React.Fragment>
                          )}
                          <CommentSection
                            length={0}
                            values={values.comment}
                            errors={errors}
                            handleChange={handleChange}
                            touched={touched}
                            setFieldValue={setFieldValue}
                            data={data[0]}
                            comment={comment}
                            activeTab={activeTab}
                            tabs={tabs}
                            setComment={setComment}
                            refetch={refetch}
                            setRefetch={setRefetch}
                          />
                          {tabs[activeTab] === "Attachments" && (
                            <div className="w-full py-1">
                              <FieldArray
                                name="idDocuments"
                                render={(arrayHelpers) => {
                                  return (
                                    <>
                                      {values?.idDocuments?.map(
                                        (item: any, index: number) => {
                                          return (
                                            <div
                                              key={index}
                                              className=" flex flex-col"
                                            >
                                              <td className="relative ">
                                                <UploadFile
                                                  className="w-full bg-white rounded-lg border-[#9A9A9A] border"
                                                  fieldClass="w-full h-[40px] pl-4 pr-32 outline-none"
                                                  placeholderStyle={{
                                                    top: "10px",
                                                    left: "16px",
                                                  }}
                                                  name={`idDocuments.${index}.document_name`}
                                                  location={`idDocuments.${index}`}
                                                  arrayName="idDocuments"
                                                  placeholder={
                                                    item?.document_type
                                                  }
                                                  value={item}
                                                  fileFrom={"case"}
                                                  setFieldValue={setFieldValue}
                                                  onBlur={handleBlur}
                                                  touched={touched}
                                                  errors={
                                                    errors?.idDocuments &&
                                                    errors?.idDocuments[index]
                                                      ?.document_name
                                                  }
                                                />
                                                {index >
                                                  initialValues?.idDocuments
                                                    ?.length -
                                                    1 &&
                                                  !item?.document_name && (
                                                    <div className="absolute right-[-24px] top-3 text-[#FF5050] font-semibold">
                                                      <button
                                                        type="button"
                                                        onClick={() => {
                                                          arrayHelpers.remove(
                                                            index
                                                          );
                                                        }}
                                                      >
                                                        X
                                                      </button>
                                                    </div>
                                                  )}
                                              </td>
                                            </div>
                                          );
                                        }
                                      )}
                                    </>
                                  );
                                }}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      </div>
                    </div>
                    <div className="w-[40%] md:w-full md:px-0 h-full px-4 flex flex-col">
                      <TaskTable
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        touched={touched}
                        setFieldValue={setFieldValue}
                        AdminUser={adminUserArray}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-full py-4 bg-white rounded-br-[20px] rounded-bl-[20px] px-4 relative "
                style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
              >
                <div className="w-full flex justify-end gap-4 py-2 px-6">
                  <button
                    onClick={handleClose}
                    type="button"
                    className=" w-[200px] border border-[#A0A0A0] py-3 rounded-[10px] font-semibold"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                    style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                  >
                    Save Task
                  </button>
                </div>

                {cancelPopup === true && (
                  <>
                    <div className="w-full h-full bg-[#00000038] fixed top-0 left-0 z-20 cursor-pointer"></div>
                    <div className="flex-col gap-8 fixed w-screen h-screen left-0 top-0 flex items-center justify-center z-[999]">
                      <div
                        className="py-4 bg-white w-[300px] max-h-[80vh] rounded-br-[20px] rounded-[20px] px-4  "
                        style={{ boxShadow: "0px -1px 10px 0px #00000033" }}
                      >
                        <div className="flex flex-col justify-center items-centers gap-3">
                          <p className="text-[16px] text-[#000000] font-semibold px-4">
                            Cancel Changes?
                          </p>
                          <p className="text-[14px] text-[#000000] font-medium px-4 py-4">
                            You are about to cancel all changes made to this
                            item. If you proceed, your unsaved edits will be
                            lost, and the item will revert to its previous
                            state.
                          </p>

                          <div className="w-full flex justify-end gap-4 ">
                            <button
                              onClick={handelCancel}
                              className="bg-[white] w-[200px]  py-3 rounded-[10px] text-[#3D5765] font-semibold"
                              style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                            >
                              cancel
                            </button>
                            <button
                              onClick={handleClosePopup}
                              className="bg-[#3D5765] w-[200px]  py-3 rounded-[10px] text-white font-semibold"
                              style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
                            >
                              Okay
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
      {loader && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-[#00000047] z-[99]">
          <Loader />
        </div>
      )}

      {deletetask && (
        <DeleteTaskPopups
          setDeleteTask={setDeleteTask}
          taskDetail={data}
          setRefetch={setRefetch}
          refetch={refetch}
          taskId={data[0]?._id}
        />
      )}
    </div>
  );
};

export default EditUserTask;
