import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

interface propsType {
  heading: string;
  button: string;
  setHandleOpen: (a: string) => void;
}

const SubHeader: React.FC<propsType> = ({ heading, button, setHandleOpen }) => {
  const nav = useNavigate();
  const handleClick = (name: string) => {
    if (name === "Register User") {
      setHandleOpen("Register");
      nav("/registerUser");
    } else if (name === "Add Lead Task") {
      setHandleOpen("LeadTask");
      nav("/AddLeadTask");
    } else if (name === "Add Task") {
      setHandleOpen("Task");
      nav("/AddTask");
    } else if (name === "Add Lab Case") {
      setHandleOpen("LabCase");
      nav("/AddLabCase");
    } else if (name === "Add Maint") {
      setHandleOpen("AddSureStayMaint");
      nav("/AddSureStayMaint");
    } else if (name === "Add New Disputes") {
      setHandleOpen("Add New Disputes");
    }
  };

  return (
    <div className="w-full pl-8 pr-4 flex justify-between items-center md:px-6">
      <span className="text-[24px] font-medium text-center">{heading}</span>
      <div
        onClick={() => handleClick(button)}
        className="border-none rounded-[10px] py-2 px-8 m-1 bg-[#3D5765] md:mx-0 md:my-1"
        style={{ boxShadow: "0px 4px 10px 0px #BAC9DB" }}
      >
        <button className="font-semibold text-white">{button}</button>
      </div>
    </div>
  );
};

export default SubHeader;
