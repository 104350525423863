import { RxCross2 } from "react-icons/rx";
import { BiSolidPencil } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

interface propsType {
  setUser: (a: any) => void;
  data: type;
}
interface type {
  createdAt: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  password: string;
  updatedAt: string;
  userStatus: string;
  userType: string;
  __v: number;
  _id: string;
}
const UserDetails: React.FC<propsType> = ({ setUser, data }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/editUser", { state: { data } });
  };

  return (
    <div className="flex justify-center items-center h-full fixed top-0 left-0 w-full z-[99]  bg-[#00000040]">
      <div className="backdrop-blur-[5px] w-full h-full fixed cursor-pointer"></div>
      <div
        className=" bg-[white] flex w-[33%] md:w-[90%] h-auto rounded-[20px] z-[100] p-[30px] flex-col "
        style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
      >
        <div className=" w-full flex justify-between items-center ">
          <span className="font-semibold">User Details</span>
          <RxCross2 size={20} onClick={() => setUser(false)} className="cursor-pointer"/>
        </div>
        <div className="w-full flex items-center gap-4  py-4">
          <div className="w-[50px] h-[50px] rounded-full bg-[#3D5765] flex justify-center items-center font-normal text-[18px] text-white">
            {data?.name[0]
              ? data?.name[0]?.toUpperCase()
              : data?.first_name[0] + "" + data?.last_name[0]}
          </div>
          <span>{data?.name}</span>
          <BiSolidPencil size={20} color={"#3D5765"} onClick={handleClick} className="cursor-pointer" />
        </div>
        <div className="w-full flex flex-col gap-2 py-3">
          <span className="font-medium ">
            Email<span className="text-[#FF0000]">*</span>
          </span>
          <p className="break-all text-[14px]">{data?.email}</p>
        </div>
        <div className="w-full flex flex-col gap-2 py-3">
          <span className="font-medium ">
            Password<span className="text-[#FF0000]">*</span>
          </span>
          <p className="break-all text-[14px]">{data?.password}</p>
        </div>
        <div className="w-full flex flex-col gap-2 pt-3">
          <span className="font-medium ">
            User Status
            <span className="text-[#C0C0C0] font-normal">
              {" "}
              (Active/Inactive)<span className="text-[#FF0000]">*</span>{" "}
            </span>
          </span>
          <p className="break-all text-[14px]">
            <div
              className="inline-block mr-2 w-[10px] h-[10px] rounded-full"
              style={
                data?.userStatus === "active"
                  ? { backgroundColor: "#6EC350" }
                  : { backgroundColor: "#CCCCCC" }
              }
            ></div>
            {data?.userStatus === "active" ? "Active" : "InActive"}
          </p>

          <hr className="text-[#D8DDE0]" />

          <p className="text-[#0496FF] text-[14px] font-normal cursor-pointer">
            Last Login Activity
          </p>
          <div className="flex flex-row items-center ">
            <span className="w-[10px] h-[10px] bg-[#8591A2] mr-2  rounded-full"></span>
            <span className="text-[#8591A2] text-[14px] font-normal">
              Last Login: 28 Aug 2024 at 1:00 PM
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
