import moment from "moment";
import toast from "react-hot-toast";
import { FaCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MdAddComment } from "react-icons/md";
import React, { useEffect, useState } from "react";

import {
  addComment,
  deleteComment,
  getAllComment,
} from "../../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";

interface propsType {
  length: number;
  values: any;
  handleChange: any;
  errors: any;
  touched: any;
  setFieldValue: any;
  data: any;
  comment: any;
  setComment: any;
  refetch: any;
  setRefetch: any;
  activeTab?: any;
  tabs?: any;
}

const CommentSection: React.FC<propsType> = ({
  values,
  handleChange,
  data,
  setComment,
  comment,
  refetch,
  setRefetch,
  activeTab,
  tabs,
}) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState("");
  const [allComments, setAllComment] = useState([]);
  const user = useAppSelector((state) => state?.user?.user);

  useEffect(() => {
    setComment(values);
  }, []);

  const handleComment = (e: any) => {
    e.preventDefault()
    const payload = {
      comment: inputValue ? inputValue.trim() : "",
      task_id: data?._id,
      user_id: user?._id,
    };
    dispatch(addComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setComment((prev: any) =>
            prev ? [...prev, res?.commentData] : [res?.commentData]
          );
          getAllComments();
          setInputValue("");
          setRefetch(refetch + 1);
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getAllComments() {
    const payload = {
      task_id: data?._id,
    };
    console.log(payload, "payload for lead data");
    dispatch(getAllComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          setAllComment(res?.commentData);
        }
      });
  }

  useEffect(() => {
    if (data) {
      getAllComments();
    }
  }, [data]);

  const handleRemove = (value: any) => {
    const payload = {
      comment_id: value?._id,
    };
    dispatch(deleteComment(payload))
      .unwrap()
      .then((res) => {
        if (res?.success === true) {
          const tempComment = comment.filter((item: any, index: number) => {
            return item !== value;
          });
          setComment(tempComment);
          setRefetch(refetch + 1);
          toast.success(res?.msg, {
            position: "top-center",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        // toast.error(error?.msg);
      });
  };

  const commentsDate = data?.commentsData?.length
    ? data?.commentsData?.map((item: any, index: number) => {
        {
          return moment(item?.createdAt).format("MMMM Do YYYY, h:mm:ss a");
        }
      })
    : [];

  return (
    <>
      <div className="w-full ">
        <div className="">
          {tabs[activeTab] === "Comments" && (
            <React.Fragment>
              <span className="font-semibold">Comment Section</span>
              <div className="flex w-full gap-4 py-2 items-center ">
                <div className="border w-full p-2 border-[#A0A0A0] rounded-[10px]">
                  <div className="flex mt-2 w-full ">
                    <div className="flex relative mb-2 w-full ">
                      <input
                        className="w-full h-[20px]   pl-5 outline-none  pr-3 bg-none relative z-[1]"
                        type="text"
                        name="comment"
                        value={inputValue}
                        placeholder="Add a Comment..."
                        id="comment"
                        onKeyDown={e => { e.key === 'Enter' && handleComment(e) }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                    </div>
                    <span className="font-medium " onClick={handleComment}>
                      <MdAddComment size={25} color={"#4278f5"} />
                    </span>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
          {tabs[activeTab] === "Comments" &&
            allComments?.length !== 0 &&
            allComments?.map((item: any, index: number) => (
              <div key={index} className="flex flex-col gap-4 py-4">
                <div className="flex gap-4">
                  <div className="flex flex-col w-full">
                    <div className="flex  gap-2 mt-2">
                      <FaCircle
                        style={{
                          color:
                            data?.status === "booked"
                              ? "#3D5765"
                              : data?.status === "new"
                              ? "#0496FF"
                              : data?.status === "following_up"
                              ? "#EE7E2E"
                              : data?.status === "completed"
                              ? "#3BB311 "
                              : data?.status === "not_interested"
                              ? "#D65252"
                              : data?.status === "todo"
                              ? "#0496FF"
                              : data?.status === "won_partial"
                              ? "#3BB311"
                              : data?.status === "contesting"
                              ? "#EE7E2E"
                              : data?.status === "won_closed"
                              ? "#3D5765"
                              : data?.status === "lost_closed"
                              ? "#D65252"
                              : data?.status === "inprogress"
                              ? "#EE7E2E"
                              : "#3D5765",
                        }}
                        className="mt-1"
                        size={10}
                      />
                      <div>
                        <p
                          className=" text-[14px]"
                          style={{
                            color:
                              data?.status === "booked"
                                ? "#3D5765"
                                : data?.status === "new"
                                ? "#0496FF"
                                : data?.status === "following_up"
                                ? "#EE7E2E"
                                : data?.status === "completed"
                                ? "#3BB311 "
                                : data?.status === "not_interested"
                                ? "#D65252"
                                : data?.status === "todo"
                                ? "#0496FF"
                                : data?.status === "won_partial"
                                ? "#3BB311"
                                : data?.status === "contesting"
                                ? "#EE7E2E"
                                : data?.status === "won_closed"
                                ? "#3D5765"
                                : data?.status === "lost_closed"
                                ? "#D65252"
                                : data?.status === "inprogress"
                                ? "#EE7E2E"
                                : "#3D5765",
                          }}
                        >
                          {data?.status
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char: any) =>
                              char.toUpperCase()
                            )}
                          <span className="text-[#8591A2] text-[14px]">
                            {" "}
                            by{" "}
                            {moment(item?.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}{" "}
                            {user?.name?.toUpperCase()}
                          </span>
                        </p>
                        <div className="flex items-center justify-between">
                          <span className="font-normal">
                            <span className="text-[#000000] font-normal text-[13px]">
                              Comment:
                            </span>{" "}
                            <span className="text-[#8591A2] text-[13px]">
                              {item?.comment}
                            </span>
                          </span>
                          {/* <span onClick={() => handleRemove(item)}>
                              <MdDelete color={"#DF0000"} size={20} />
                            </span> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          {tabs[activeTab] === "Status" &&
            data?.statusChangeData?.map((item: any, index: any) => (
              <div key={index} className="flex flex-col gap-4 py-4">
                <div className="flex gap-4">
                  <div className="flex flex-col w-full">
                    <div className="flex  gap-2 mt-2">
                      <FaCircle color="#EE7E2E" className="mt-1" size={10} />
                      <div>
                        <p className="text-[#D65252] text-[14px]">
                          {item?.prevStatus
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char: any) =>
                              char.toUpperCase()
                            )}{" "}
                          <span className="text-[#3BB311]">
                            {" "}
                            to {item?.currentStatus}
                          </span>
                          <span className="text-[#8591A2] text-[14px]">
                            {" "}
                            by {commentsDate[commentsDate?.length - 1]}{" "}
                            {user?.name?.toUpperCase()}
                          </span>
                        </p>
                        <div className="flex items-center justify-between">
                          <span className="font-normal">
                            <span className="text-[#000000] font-normal text-[13px]">
                              Comment:
                            </span>{" "}
                            <span className="text-[#8591A2] text-[13px]">
                              {item?.text}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default CommentSection;
