import React, { useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";

import CartTask from "./CartTask";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";
import AddComment from "./AddComment";
import { useNavigate } from "react-router-dom";

interface PropsType {
  completed: any;
  setTaskDetail?: any;
  // setViewTask?: any;
  // setView?: any;
  // type?: any;
  // setShowTask?: any;
  // size: any;
  // setSize: any;
  taskDetail: any;
  addLead: any;
  setRefetch: any;
  myTask: any;
  setViewTask?: any;
  setOpenComment: any;
  openComment: any;
}

const CompletedLead: React.FC<PropsType> = ({
  completed,
  setViewTask,
  setOpenComment,
  addLead,
  openComment,
  setTaskDetail,
  // setViewTask,
  // setShowTask,
  // size,
  // type,
  taskDetail,
  myTask,
  setRefetch,
}) => {
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.user);
  const calcLength = () => {
    if (myTask === "true") {
      const tempArray = completed?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return completed?.length;
    }
  };

  return (
    <>
      <div className="mt-4 lg:hidden">
         <IoMdArrowBack size={25} onClick={() => navigate(-1)} />
      </div>
      <Droppable droppableId="completedList">
        {(provided) => (
          <div
            className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="  h-full flex  justify-center flex-wrap overflow-auto  ">
              <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px] font-semibold text-center px-5 py-1 bg-[#D8F0CF] rounded-[7px] text-[#3BB311]"
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    Completed ( {completed?.length ||0})
                  </span>
                </div>
                <div className="flex flex-col  py-1 px-2">
                  <span className="font-medium text-[14px]">
                    Lead Completed
                  </span>
                  <span className="text-[#505050] text-[13px]">
                    The tasks are Completed by team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto ">
                  {completed?.map((item: any, index: number) => {
              
                      return (
                        <CartTask
                          key={index}
                          index={index}
                          item={item}
                          setTaskDetail={setTaskDetail}
                          setViewTask={setViewTask}
                          taskDetail={taskDetail}
                          openComment={openComment}
                          setOpenComment={setOpenComment}
                          icon={<HighStatusSvg />}
                        />
                      );
                  
                  })}
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {openComment && (
        <div className="w-full h-[50%] relative">
          <AddComment
            openComment={openComment}
            setOpenComment={setOpenComment}
            addLead={addLead}
            setRefetch={setRefetch}
          />
        </div>
      )}
    </>
  );
};

export default CompletedLead;
