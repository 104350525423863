import moment from "moment";
import React, { useState } from "react";
import { MdConnectWithoutContact, MdLocalPhone } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Draggable } from "react-beautiful-dnd";

import DragSVG from "../../assets/image/svg/DragSVG";
import AddLeadTask from "../TaskComponents/AddLeadTask";
import messageIcon from "../../assets/image/message.png";
import CalenderSVG from "../../assets/image/svg/CalenderSVG";
import { BiSolidFileJpg, BiSolidFilePdf } from "react-icons/bi";

const CartTask = ({
  setViewTask,
  setTaskDetail,
  setOpenComment,
  item,
  icon,
  index,
}: any) => {
  const [updateLead, setUpdateLead] = useState(false);
  const navigate = useNavigate();

  function handleClick() {
    setTaskDetail(item);
    navigate(`/leadTask/${item?._id}`);
  }

  return (
    <React.Fragment>
      <Draggable draggableId={item?._id} index={index}>
        {(provided) => (
          <div
            className={`h-auto w-auto mx-2 p-4 m-1 border-none bg-white rounded-[14px] cursor-pointer `}
            style={{ boxShadow: "0px 1.8px 8.98px 0px #0000000D" }}
            onDoubleClick={handleClick}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <div className="w-full flex justify-between  items-center ">
              <div className="flex items-center gap-3 ">
                <span className="w-[18px] h-[18px] text-[12px] bg-[#0496FF33] text-[#0094FF] flex items-center justify-center p-3 rounded-full">
                  ab
                </span>
                <p className="font-semibold">{item?.lead_name}</p>
              </div>

              <div className="flex items-center gap-2">
                <span className="">
                  <DragSVG />
                </span>
              </div>
            </div>
            <div className="w-full my-2 flex justify-between">
              <div className="flex items-center gap-2 ">
                <span className="w-[14px] h-[14px]"> {icon}</span>
                <span className="text-[10px] ml-4 font-normal text-black">
                  {item?.title}
                </span>
              </div>
              <BiSolidFileJpg color="#007cb8" size={20} />
            </div>
            <div className="flex gap-2 justify-between">
              {item?.leadlogsData[0]?.contact_method === "Phone" ? (
                <div className="flex gap-1 items-center mt-3">
                  <MdLocalPhone color="#8591A2" size={20} />
                  <span className="text-[#8591A2] text-[12px] font-normal">
                    {item?.leadlogsData[0]?.contactData}
                  </span>
                </div>
              ) : item?.leadlogsData[0]?.contact_method === "Email" ? (
                <div className="flex gap-1 items-center mt-3">
                  <MdConnectWithoutContact color="#8591A2" size={20} />
                  <span className="text-[#8591A2] text-[12px] font-normal">
                    {item?.leadlogsData[0]?.contactData}
                  </span>
                </div>
              ) : (
                <span className="text-[#8591A2] text-[12px] font-normal">
                  {item?.leadlogsData[0]?.contactData}
                </span>
              )}
            </div>

            <div className="border border-[#EBEBEB] my-2"></div>
            <div className="flex justify-between items-center gap-2 mt-2">
              <p className="text-[12px] text-[#8591A2]">
                Created By : {item?.ownerDetails[0]?.name}
              </p>
              <p className="flex items-center gap-1">
                <CalenderSVG />
                <span className="text-[11px] text-[#8591A2]">
                  {moment(item?.createdAt).fromNow()} 
                </span>
              </p>
            </div>
          </div>
        )}
      </Draggable>
      {updateLead && <AddLeadTask setUpdateLead={setUpdateLead} item={item} />}
    </React.Fragment>
  );
};

export default CartTask;
