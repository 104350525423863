import { useEffect, useState } from "react";

const CheckBox = ({
  dataType,
  name,
  data,
  selectValue,
  setSelectValue,
  setFieldValue,
}: any) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    selectValue?.map((item: any) => {
      if (item?._id === data?._id) {
        setChecked(true);
      }
    });
  }, [data]);

  const handleSelect = (value: any) => {
    let updatedSelectValue: string[] = [];

    if (checked) {
      updatedSelectValue = selectValue.filter(
        (item: any) => item?._id !== value?._id
      );
      setChecked(false);
    } else {
      updatedSelectValue = [...selectValue, value];
      setChecked(true);
    }

    setSelectValue(updatedSelectValue);
    setFieldValue(name, updatedSelectValue);
  };
  return (
    <div className={`flex flex-row gap-2 pl-5 pt-1 pb-1 pr-5 text-[14px] `}>
      <input
        type="checkbox"
        id={dataType === "string" ? data : data?._id}
        name={dataType === "string" ? data : data?._id}
        value={data}
        checked={checked}
        onChange={() => handleSelect(data)}
      />
      <label
        htmlFor={dataType === "string" ? data : data?._id}
        style={{ width: "100%" }}
      >
        {dataType === "string" ? data : data?.name}
      </label>
    </div>
  );
};

export default CheckBox;
