import React from "react";
import { Droppable } from "react-beautiful-dnd";

import CartTask from "./CartTask";
import AddComment from "./AddComment";
import { IoMdArrowBack } from "react-icons/io";
import { useAppSelector } from "../../Redux/Hooks";
import HighStatusSvg from "../../assets/image/svg/HighStatusSvg";
import { useNavigate } from "react-router-dom";

interface PropsType {
  disputeData: any;
  setTaskDetail: any;
  setViewTask?: any;
  setView?: any;
  view?: any;
  setShowTask?: any;
  type?: any;
  setSize?: any;
  size?: string;
  myTask: any;
  openComment: any;
  setOpenComment: any;
  addDispute: any;
  refetch: any;
  setRefetch: any;
}

const WonPartial: React.FC<PropsType> = ({
  disputeData,
  setShowTask,
  setTaskDetail,
  setViewTask,
  type,
  setSize,
  size,
  myTask,
  openComment,
  setOpenComment,
  addDispute,
  refetch,
  setRefetch,
}) => {
  const { user } = useAppSelector((state) => state.user);

  const navigate = useNavigate()
  const calcLenght = () => {
    if (myTask === "true") {
      const tempArray = disputeData?.filter((item: any) =>
        item?.assign_to?.includes(user?._id)
      );
      return tempArray?.length;
    } else {
      return disputeData?.length;
    }
  };
  const sum = disputeData.reduce((acc: number, item: any) => {
    console.log(acc, item, "disputed data"); // Log the accumulator and current item
    return acc + item.disputed_amount;
  }, 0);
  return (
    <>
      <div className="mt-4 lg:hidden">
         <IoMdArrowBack size={25} onClick={() => navigate(-1)} />
      </div>
      <Droppable droppableId="wonPartialList">
        {(provided) => (
          <div
            className="w-1/3 h-full bg-[#E2E8F0] p-2 rounded-[15px] md:w-full  md:mt-[20px] "
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <div className="  h-full flex  justify-center flex-wrap overflow-auto  ">
              <div className="w-full h-full flex flex-col gap-4  px-2 py-3   ">
                <div className="w-full flex px-2 ">
                  <span
                    className="text-[16px] font-semibold text-center px-5 py-1 bg-[#D8F0CF] rounded-[7px] text-[#3BB311] "
                    style={{ boxShadow: "0px 4px 4px 0px #00000026" }}
                  >
                    Won Partial (${sum})
                  </span>
                </div>
                <div className="flex flex-col  py-1 px-2">
                  <span className="font-medium text-[14px]">Won Partial</span>
                  <span className="text-[#505050] text-[13px]">
                    The Won Partial are create by team members.
                  </span>
                </div>
                <div className="h-full flex flex-col gap-2 w-full overflow-auto ">
                  {disputeData?.map((item: any, index: any) => (
                    <CartTask
                      key={index}
                      item={item}
                      index={index}
                      setViewTask={setViewTask}
                      setTaskDetail={setTaskDetail}
                      icon={<HighStatusSvg />}
                      type={type}
                      size={size}
                      refetch={refetch}
                      setRefetch={setRefetch}
                    />
                  ))}
                </div>
              </div>
            </div>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {openComment && (
        <div className="w-full h-[50%] relative">
          <AddComment
            openComment={openComment}
            setOpenComment={setOpenComment}
            addDispute={addDispute}
            setRefetch={setRefetch}
          />
        </div>
      )}
    </>
  );
};

export default WonPartial;
