import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, setAuthToken, uploadInstance } from "../utils/AxiosInstance";

interface userState {
  task: [];
  loading: boolean;
  error: string;
}

interface TaskData {
  title: string;
  description: string;
  attached_files: [string];
  commentsData: [string];
  assign_to: [string];
  owner: string;
  lead: string;
  priority: string;
  deadline: string;
  status: string;
  taskType: string;
}

const initialState: userState = {
  task: [],
  loading: false,
  error: "",
};

export const getAllTasks = createAsyncThunk(
  "getAllTasks",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllMaintanance = createAsyncThunk(
  "getAllMaintanance",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/maintanance/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleMaintanance = createAsyncThunk(
  "getSingleMaintanance",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/maintanance/single_task";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleLead = createAsyncThunk(
  "getSingleLead",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/single_task";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getSingleLabCase = createAsyncThunk(
  "getSingleLabCase",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/libcase/single_lab";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);
export const getAllDisputeTasks = createAsyncThunk(
  "getAllTasks",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/dispute/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);

      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const createTask = createAsyncThunk(
  "createTask",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/create";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const createMaint = createAsyncThunk(
  "createMaint",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/maintanance/create";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const createDispute = createAsyncThunk(
  "createDispute",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/dispute/create";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const createLead = createAsyncThunk(
  "createLead",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/create";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const updateLead = createAsyncThunk(
  "updateLead",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const updateMaint = createAsyncThunk(
  "updateMaint",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/maintanance/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const updateDispute = createAsyncThunk(
  "updateDispute",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/dispute/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const createLabCase = createAsyncThunk(
  "createLabCase",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/libcase/create";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteTask = createAsyncThunk(
  "deleteTask",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteMaintanance = createAsyncThunk(
  "deleteMaintanance",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/maintanance/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);
export const deleteLead = createAsyncThunk(
  "deleteLead",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteLabCase = createAsyncThunk(
  "deleteLabCase",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/libcase/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteDispute = createAsyncThunk(
  "deleteDispute",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/dispute/delete";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const addComment = createAsyncThunk(
  "addComment",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/addcomment";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      console.log({ response });
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const deleteComment = createAsyncThunk(
  "deleteComment",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/deletecomment";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      return response?.data;
    } catch (error: any) {
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const uploadDocument = createAsyncThunk(
  "uploadDocument",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/utils/upload";
      const requestBody = data;
      const response = await uploadInstance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------response uploadDocument");
      }
      return response?.data;
    } catch (error: any) {
      console.log("---------->uploadDocument error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllLabCases = createAsyncThunk(
  "getAllLabCases",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/libcase/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "--------------------response is here");
      }
      return response?.data;
    } catch (error: any) {
      console.log("---------------------->error is here", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const updateTask = createAsyncThunk(
  "updateTask",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------------response updateTask");
      }
      return response?.data;
    } catch (error: any) {
      console.log("----------------->updateTask error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const updateLabCase = createAsyncThunk(
  "updateLabCase",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/libcase/update";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------------response updateTask");
      }
      return response?.data;
    } catch (error: any) {
      console.log("----------------->updateTask error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const attemptsAddLead = createAsyncThunk(
  "attemptsAddLead",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/create_attempt";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------------response ");
      }
      return response?.data;
    } catch (error: any) {
      console.log("-----------------> error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllAttempts = createAsyncThunk(
  "getAllAttempts",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/get_all_attempt";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------------get all attempts");
      }
      return response?.data;
    } catch (error: any) {
      console.log("----------------->attempts error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllComment = createAsyncThunk(
  "getAllComment",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/task/get_all_comment";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "------------------response updateTask");
      }
      return response?.data;
    } catch (error: any) {
      console.log("----------------->updateTask error", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

export const getAllLeadData = createAsyncThunk(
  "getAllLeadData",
  async (data: object, { rejectWithValue }) => {
    try {
      const url = "/lead_task/getall";
      const requestBody = data;
      const response = await instance.post(url, requestBody);
      if (response?.data?.status) {
        console.log(response, "--------------------response is here");
      }
      return response?.data;
    } catch (error: any) {
      console.log("---------------------->error is here", error);
      throw rejectWithValue(error?.response?.data);
    }
  }
);

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllTasks.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(getAllTasks.fulfilled, (state, action) => {
        // console.log(action?.payload, "==============");
        return {
          ...state,
          loading: false,
          task: action.payload?.taskData,
        };
      })
      .addCase(getAllTasks.rejected, (state) => {
        return { ...state, loading: false };
      })
      .addCase(deleteTask.pending, (state) => {
        return { ...state, loading: true };
      })
      .addCase(deleteTask.fulfilled, (state) => {
        return { ...state, loading: false };
      })
      .addCase(deleteTask.rejected, (state) => {
        return { ...state, loading: false };
      });
  },
});

export default taskSlice.reducer;
