import SubHeader from "../Components/Header Component/SubHeader";
import { useEffect, useState } from "react";

import { Droppable } from "react-beautiful-dnd";

import NewLead from "../Components/Lead Components/NewLead";
import FollowingUpLead from "../Components/Lead Components/FollowingUpLead";
import Booked from "../Components/Lead Components/Booked";
import CompletedLead from "../Components/Lead Components/CompletedLead";
import ViewSingleLead from "../Components/Lead Components/ViewSingleLead";
import NotInterested from "../Components/Lead Components/NotInterested";
import RespoAllLead from "../Components/ResponsiveComponents/RespoAllLead";
import toast from "react-hot-toast";
import { getAllLeadData } from "../Slices/TaskSlice";
import { useAppDispatch, useAppSelector } from "../Redux/Hooks";

interface PropsType {
  refetch: any;
  windowWidth: any;
  showScreen: any;
  setShowScreen: any;
  completed: any;
  inprogress: any;
  todo: any;
  taskData: any;
  setRefetch: any;
  leadData: any;
  setLeadData: any;
  openComment: any;
  setOpenComment: any;
  addLead: any;
  userDetails?: any;
  myTask?: any;
  setMyTask?: any;
}

const AllLeadTask: React.FC<PropsType> = ({
  windowWidth,
  showScreen,
  setShowScreen,
  completed,
  inprogress,
  todo,
  taskData,
  leadData,
  setLeadData,
  setRefetch,
  refetch,
  addLead,
  openComment,
  setOpenComment,
  userDetails,
  myTask,
  setMyTask,
}) => {
  const dispatch = useAppDispatch()
  const [viewTask, setViewTask] = useState(false);
  const [handleOpen, setHandleOpen] = useState("");
  const [taskDetail, setTaskDetail] = useState<any>({});
  const [size, setSize] = useState<any>("medium");
  const AdminUser: any = useAppSelector((state) => state?.user?.user);

  const handleGetAllLeadData = () => {
    const payload = {
      status: "all",
      search: "",
      user_id: myTask !== "true" && myTask !== "false" ? myTask : "",
      task_filter:
        myTask === "true" ? "mytask" : myTask === "false" ? "alltask" : " ",
      organization_id: AdminUser?.organization_id,
    };
    dispatch(getAllLeadData(payload))
      .unwrap()
      .then((response) => {
        if (response?.success === true) {
          const tempNew: any = [];
          const tempFollowingUp: any = [];
          const tempBooked: any = [];
          const tempCompleted: any = [];
          const tempNotInterested: any = [];
          response?.taskData?.forEach((item: any) => {
            if (item.status === "new") {
              tempNew.push(item);
            } else if (item.status === "following_up") {
              tempFollowingUp.push(item);
            
            } else if (item.status === "scheduled") {
              tempBooked.push(item);
            } else if (item?.status === "completed") {
              tempCompleted.push(item);
            } else if (item.status === "not_interested") {
              tempNotInterested.push(item);
            }
            setLeadData({
              new: tempNew,
              followingUp: tempFollowingUp,
              booked: tempBooked,
              completed: tempCompleted,
              notInterested: tempNotInterested,
            });
          });
        }
      })
      .catch((error) => {
        setLeadData([])
        console.log("error", error);
        toast.error(error?.msg);
      });
  };
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMyTask(e.target.value);
  };

  useEffect(() => {
    handleGetAllLeadData();
  }, [myTask]);

  return (
    <>
      {windowWidth < 700 ? (
        <RespoAllLead
          button={"Add Lead Task"}
          todo={todo}
          inprogress={inprogress}
          completed={completed}
          setShowScreen={setShowScreen}
          showScreen={showScreen}
          setTaskDetail={setTaskDetail}
          taskDetail={taskDetail}
          setRefetch={setRefetch}
          refetch={refetch}
          name={"All Lead Tasks"}
          taskData={taskData}
          leadData={leadData}
          setLeadData={setLeadData}
          openComment={openComment}
          setOpenComment={setOpenComment}
          addLead={addLead}
          myTask={myTask}
        />
      ) : (
        <div className="w-[100%] h-[100%] flex  px-5">
          <div className="w-full h-full  ">
            <SubHeader
              heading="All Lead Tasks"
              button="Add Lead Task"
              setHandleOpen={setHandleOpen}
            />

            <div className="w-full items-center gap-3 flex justify-end mt-3 pr-5 ">
              <div className="pr-5">
                <label className="mr-2 text-[15px]">Filter Task:</label>
                <select
                  id="size"
                  value={myTask}
                  onChange={(e) => handleChange(e)}
                  className="px-2  text-[12px] py-1 border border-none rounded"
                >
                  <option value={"true"}>My Task</option>
                  <option value={"false"}>All Task</option>
                  {userDetails?.map((item: any, index: number) => (
                    <option key={index} value={item?._id}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className=" w-full  flex gap-2  py-4  justify-between overflow-auto "
              style={{ height: "calc(100% - 48px)" }}
            >
              <NewLead
                todo={todo}
                setViewTask={setViewTask}
                openComment={openComment}
                taskDetail={taskDetail}
                setTaskDetail={setTaskDetail}
                addLead={addLead}
                myTask={myTask}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
              />
              <div
                className="h-full border-r-[1.5px] border-dashed border-[#3E5F71]"
                style={{ height: "calc(100% - 48px)" }}
              ></div>
              <FollowingUpLead
                inprogress={inprogress}
                setViewTask={setViewTask}
                taskDetail={taskDetail}
                openComment={openComment}
                setTaskDetail={setTaskDetail}
                addLead={addLead}
                myTask={myTask}
                setOpenComment={setOpenComment}
                setRefetch={setRefetch}
              />
              <div
                className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"
                style={{ height: "calc(100% - 48px)" }}
              ></div>

              <Booked
                taskData={taskData}
                setViewTask={setViewTask}
                setTaskDetail={setTaskDetail}
                taskDetail={taskDetail}
                openComment={openComment}
                addLead={addLead}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />

              <div
                className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"
                style={{ height: "calc(100% - 48px)" }}
              ></div>
              <CompletedLead
                completed={completed}
                setViewTask={setViewTask}
                setTaskDetail={setTaskDetail}
                openComment={openComment}
                addLead={addLead}
                taskDetail={taskDetail}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />

              <div
                className="h-full  border-r-[1.5px] border-dashed border-[#3E5F71]"
                style={{ height: "calc(100% - 48px)" }}
              ></div>
              <NotInterested
                leadData={leadData?.notInterested}
                setViewTask={setViewTask}
                setTaskDetail={setTaskDetail}
                openComment={openComment}
                addLead={addLead}
                taskDetail={taskDetail}
                myTask={myTask}
                setRefetch={setRefetch}
                setOpenComment={setOpenComment}
              />
            </div>
          </div>

          {/* {viewTask && (
            <ViewSingleLead
              setViewTask={setViewTask}
              taskDetail={taskDetail}
              setRefetch={setRefetch}
              refetch={refetch}
            />
          )} */}
        </div>
      )}
    </>
  );
};

export default AllLeadTask;
