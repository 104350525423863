import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/Hooks";
import { userLogout } from "../../Slices/UserSlice";
import { useRef } from "react";
import { useOutSideCheck } from "../customize state/useOutSideCheck";

interface PropsType {
  setPass?: (a: any) => void;
  setProfile?: any;
  setProf?: any;

  profile: any;
}

const MyProfile: React.FC<PropsType> = ({
  setPass,
  setProfile,
  setProf,
  profile,
}) => {
  const dispatch = useAppDispatch();
  const nav = useNavigate();
  const wrapperRef = useRef(null);
  useOutSideCheck(wrapperRef, setProfile, profile); // this is used to close the component clicking anywhere.

  const logOut = () => {
    dispatch(userLogout());
    nav("/login");
  };
  const user = useAppSelector((state) => state.user.user);


  return (
    <div
      className="h-auto overflow-auto absolute top-[45px] right-2 md:right-[-5%] z-[999] bg-white w-[400px] border-none rounded-[10px]  md:w-[300px]"
      style={{ boxShadow: "0px 6px 10px 0px #00000033" }}
      ref={wrapperRef}
    >
      <div className="flex justify-between items-center py-4 border-b border-[#D0D0D0] px-8">
        <span className="text-[20px] font-medium">Account Details</span>
    
        <div className="flex flex-col gap-2 items-center">
          <div className="w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#3D5765] text-white text-[18px]">
            {user?.name[0]?.toUpperCase()}
          </div>
          <span className="text-[14px] font-semibold">
            {user?.name?.toUpperCase()}
          </span>
        </div>
      </div>

      <div className="flex flex-col py-4 border-b border-[#D0D0D0] px-8">
        <div
          onClick={() => {
            setProf && setProf(true);
            setProfile(false);
          }}
          className="py-4 cursor-pointer"
        >
          <div className="flex justify-between ">
            <span className="font-semibold">Account Details</span>
            <div className="mt-2">
              <MdOutlineKeyboardArrowRight size={20} />
            </div>
          </div>
          <span className=" pl-1 text-[13px] text-[#B0B0B0] font-normal">
            View and update your personal information here.
          </span>
        </div>

        <div
          onClick={() => {
            setPass && setPass(true);
            setProfile(false);
          }}
          className="py-4 cursor-pointer "
        >
          <div className="flex justify-between ">
            <span className="font-semibold">Update Password</span>
            <div className="mt-2">
              <MdOutlineKeyboardArrowRight size={20} />
            </div>
          </div>
          <span className=" pl-1 text-[13px] text-[#B0B0B0] font-normal">
            Securely update your password here.
          </span>
        </div>
      </div>
      <div onClick={logOut} className="flex flex-col py-4 px-8 cursor-pointer ">
        <span className="font-semibold text-[#E04A4A]">Log Out</span>
        <span className=" pl-1 pt-2 text-[13px] text-[#B0B0B0] font-normal">
          Securely update your password here.
        </span>
      </div>
    </div>
  );
};

export default MyProfile;
